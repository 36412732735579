import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";
import { ImageCroppedEvent } from "ngx-image-cropper";
import * as moment from "moment";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-add-discount",
  templateUrl: "./add-discount.component.html",
  styleUrls: ["./add-discount.component.css"]
})
export class AddDiscountComponent implements OnInit {
  //VAriable declaration
  pageHeading = "Add Discount";
  barName = "";
  adddiscountForm: FormGroup;
  templates = [];
  tempPath = this.cs.tempPath();
  offerImgPath = this.cs.offersPath();
  imgPreviewPath = null;
  imgCropPath = null;
  croppedImage: any = "";
  imageChangedEvent: any = "";
  FileErrorMessageLog = false;
  canSubmit = true;
  showCancelBtn = false;
  editing = false;
  temp = "";
  TimeErrorMessage = false;
  MinuteErrorMessage = false;
  DiscountErrorMessage = false;
  DiscountMaxErrorMessage = false;
  disTitleMsg = true;
  discountSave = "Publish";
  offerId: any;
  imageSave = false;
  imageHide = false;
  //offer item
  itemData = [];
  itemIdsExist = [];
  itemImgPath = this.cs.itemsPath();
  isShowOfferItem = false;
  dynamicDiscountRate = 0;
  blob = null;
  base64Img: any = "";

  //Injecting SErvices
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Calling templates function
    this.loadTemplates();

    //Call the menu items
    this.loadMenuItems("");

    //Form Model Declaration with form validation for the fields
    this.adddiscountForm = this.fb.group({
      id: [""],
      barId: [localStorage.getItem("barId")],
      offerImage: [""],
      templateId: [0], //[this.templates[0]],
      title: [
        "",
        [
          Validators.required,
          //Validators.pattern("^[a-zA-Z ()]+$"),
          Validators.minLength(3),
          Validators.maxLength(50)
        ]
      ],
      description: ["", [Validators.minLength(20), Validators.maxLength(200)]],
      startTime: ["", [Validators.required]],
      endTime: ["", [Validators.required]],
      discountRate: ["", [Validators.pattern("^[0-9()]+$")]],
      appliedOn: ["", [Validators.required]],
      itemIds: new FormArray([]),
      agree: ["", [Validators.required]],
      gracePeriod: [""]
    });

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadMenuItems("");
        this.loadOfferData(id);
        //this.loadOfferItems("discount", id);
        this.canSubmit = false;
        this.showCancelBtn = true;
        this.editing = true;
        this.offerId = id;
        this.pageHeading = "Edit Discount";
      }
    });
  }

  //Function to load templates
  loadTemplates() {
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getTemplates").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barName = response.bar.barName; //To show on Image preview
          this.templates = response.data;
          //Add the first Image for preview
          this.imgPreviewPath = this.tempPath + this.templates[0].templateFrame;
          //Patch the template ID in Form Modal
          this.adddiscountForm.patchValue({
            templateId: this.templates[0].id,
            offerImage: ""
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to set the template with Discount Form Model
  selectTemplate(index) {
    let tempData = this.templates[index];
    this.imgPreviewPath = this.tempPath + tempData.templateFrame;
    //Patch the template ID in Form Modal
    this.adddiscountForm.patchValue({
      templateId: tempData.id,
      offerImage: ""
    });
    this.base64Img = "";
    console.log("Changed Template ID:" + this.templateId.value);
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.imgPreviewPath = event.base64;
    this.adddiscountForm.get("offerImage").setValue(event.base64);
    this.base64Img = "ok";
  }

  hideCropImg() {
    this.imageSave = false;
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // const files = this.imgCropPath;
      // console.log("files" + files);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        this.imageHide = true;
        this.imageSave = true;
        this.FileErrorMessageLog = false;
        this.adddiscountForm.get("offerImage").setValue(file);
        this.imageChangedEvent = event;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.imgPreviewPath = reader.result;
        };
        //unPatch the template ID in Form Modal
        this.adddiscountForm.patchValue({
          templateId: 0
        });
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  //Load Offer Data in case of edit
  loadOfferData(id) {
    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));
    formData.append("id", id);
    formData.append("offerType", "discount");
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getOffersData").subscribe(
      (response: any) => {
        if (response.status == 1) {
          if (response.canEdit == true) this.canSubmit = true;
          this.loadOfferItems("discount", response.data.offerId);

          //Patch the Item data in menuForm
          this.adddiscountForm.patchValue({
            barId: localStorage.getItem("barId"),
            id: response.data.id,
            offerImage: response.data.offerImage,
            title: response.data.title,
            description: response.data.description,
            startTime: this.cs.timeWithoutSeconds(response.data.startTime), //response.data.startTime,
            endTime: this.cs.timeWithoutSeconds(response.data.endTime), //response.data.endTime,
            discountRate: response.data.discountRate,
            appliedOn: response.data.appliedOn.toString(),
            templateId: response.data.templateId,
            gracePeriod: response.data.gracePeriod,
            agree: 1
          });
          //show offer items
          if (response.data.appliedOn == 2) {
            this.isShowOfferItem = true;
          }
          //Preview of Image
          if (response.data.templateId != 0)
            this.imgPreviewPath = this.tempPath + response.data.templateFrame;
          else
            this.imgPreviewPath = this.offerImgPath + response.data.offerImage;

          this.TitleShow(response.data.title);
          this.DescriptionShow(response.data.description);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //form submit function
  onDiscountSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;

    /*if (this.templateId.value == 0) {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please select a template";
      this.myapp.isLoadingResults = false;
    }*/

    //Function to validate End time is grater then start time
    let startValue = this.startTime.value;
    let endValue = this.endTime.value;
    let starttime = this.cs.timevalidation(startValue);
    let endtime = this.cs.timevalidation(endValue);
    /*if (starttime >= endtime) {
      this.TimeErrorMessage = true;
      canSubmit = false;
      return;
    } else {
      this.TimeErrorMessage = false;
    }*/

    // VAlidating minimum 1 hr interval
    /*let starttotal = moment(startValue, "hh:mm:ss")
      .add(59, "minutes")
      .format("LTS");
    let endtotal = moment(endValue, "hh:mm:ss")
      .subtract(0, "minutes")
      .format("LTS");
    if (starttotal > endtotal || starttotal == endtotal) {
      this.MinuteErrorMessage = true;
      canSubmit = false;
      return;
    } else {
      this.MinuteErrorMessage = false;
      canSubmit = true;
    }*/

    // Function to validate discount
    let discountRateValue = this.discountRate.value;
    if (discountRateValue == 0) {
      this.DiscountErrorMessage = true;
      canSubmit = false;
    } else if (discountRateValue > 100) {
      this.DiscountMaxErrorMessage = true;
      canSubmit = false;
    } else {
      this.DiscountErrorMessage = false;
      this.DiscountMaxErrorMessage = false;
    }
    const ids: FormArray = this.adddiscountForm.get("itemIds") as FormArray;
    //Validation checking of offer item
    if (this.isShowOfferItem) {
      if (ids.length > 0) {
      } else {
        canSubmit = false;
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = "Please select an item";
        this.myapp.isLoadingResults = false;
      }
    }

    //Validation agree is checked or not
    if (!this.agree.value) {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please agree before publishing this offer";
      this.myapp.isLoadingResults = false;
    }

    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("id", this.adddiscountForm.get("id").value);
      formData.append("barId", this.adddiscountForm.get("barId").value);
      formData.append(
        "offerImage",
        this.adddiscountForm.get("offerImage").value
      );
      formData.append("title", this.adddiscountForm.get("title").value);
      formData.append(
        "description",
        this.adddiscountForm.get("description").value
      );
      formData.append("startTime", this.adddiscountForm.get("startTime").value);
      formData.append("endTime", this.adddiscountForm.get("endTime").value);
      formData.append(
        "discountRate",
        this.adddiscountForm.get("discountRate").value
      );
      formData.append("appliedOn", this.adddiscountForm.get("appliedOn").value);
      formData.append(
        "templateId",
        this.adddiscountForm.get("templateId").value
      );
      formData.append("offerType", "discount");
      formData.append("itemIds", this.adddiscountForm.get("itemIds").value);
      formData.append("base64Img", this.base64Img);
      formData.append("gracePeriod", this.gracePeriod.value);
      formData.append("token", localStorage.getItem("token"));

      this.bs.callAPI(formData, "addEditOffer").subscribe(response => {
        console.log(response);
        
        if (response.status === 1) {
          this.myapp.isLoadingResults = false;
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.router.navigate(["/view-discount"]);
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });

      /*let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message:
            "Changes and Deletion of offer won't be allowed after publishing. Do you want to publish the offer?"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        //Result is coming from the buttons on the popup
      });*/
    }
  }

  // Change save button text on click specific item
  SpecificItem(): void {
    //this.discountSave = "Save & Select Item";
  }
  AllItem(): void {
    //this.discountSave = "Save";
  }
  // View Title on banner
  TitleShow(titleValue: string): void {
    document.getElementById("distitle").innerHTML = titleValue;
    //console.log(titleValue);
  }
  // View Description on banner
  DescriptionShow(descriptionValue: string): void {
    document.getElementById("disdiscription").innerHTML = descriptionValue;
    //console.log(descriptionValue);
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.disTitleMsg = false;
    this.adddiscountForm.reset();
    //Patch the required data in Form Modal
    this.adddiscountForm.patchValue({
      id: "",
      barId: [localStorage.getItem("barId")],
      templateId: this.templates[0].id,
      offerImage: "",
      title: "",
      description: "",
      startTime: "",
      endTime: "",
      discountRate: "",
      appliedOn: ""
    });
    //Add the first Image for preview again
    this.imgPreviewPath = this.tempPath + this.templates[0].templateFrame;
    this.isShowOfferItem = false;
    this.imageSave = false;
  }

  //Getor - To access the fields directly in the form in HTML
  /*get offerImage() {
    return this.adddiscountForm.get("offerImage");
  }*/
  get title() {
    return this.adddiscountForm.get("title");
  }
  get description() {
    return this.adddiscountForm.get("description");
  }
  get startTime() {
    return this.adddiscountForm.get("startTime");
  }
  get endTime() {
    return this.adddiscountForm.get("endTime");
  }
  get discountRate() {
    return this.adddiscountForm.get("discountRate");
  }
  get appliedOn() {
    return this.adddiscountForm.get("appliedOn");
  }
  get itemIds() {
    return this.adddiscountForm.get("itemIds");
  }
  get templateId() {
    return this.adddiscountForm.get("templateId");
  }
  get agree() {
    return this.adddiscountForm.get("agree");
  }
  get gracePeriod() {
    return this.adddiscountForm.get("gracePeriod");
  }

  //New code write by ravindra 02-01-2019
  //Load Menu Items defination
  loadMenuItems(keyword) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getItemsList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.itemData = response.data;
        } else {
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load Item Data in case of edit
  loadOfferItems(offerType, offerId) {
    const formData = new FormData();
    formData.append("offerType", offerType);
    formData.append("offerId", offerId);
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getOffersItems").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //Set the discount rate
          this.dynamicDiscountRate = response.discountRate;

          //Add the items
          for (let i = 0; i < response.data.length; i++) {
            const formArray: FormArray = this.adddiscountForm.get(
              "itemIds"
            ) as FormArray;

            // Add a new control in the arrayForm
            formArray.push(new FormControl(response.data[i].itemId));
            this.itemIdsExist.push(response.data[i].itemId);
          }
        } else {
          //Set the discount rate
          this.dynamicDiscountRate = response.discountRate;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  // Function to calculate disounted price on HTML
  calculateDisountedPrice(price, discount) {
    return (price - (price / 100) * discount).toFixed(2);
  }
  //Function to find itemIds in existing Ids to show the checkbox checked while EDIT
  checkItemExist(itemId) {
    let data = this.itemIdsExist.indexOf(itemId);
    if (data >= 0) return true;
    else return false;
  }

  //Function to find itemIds in existing Ids to show the price in green while EDIT
  onEditItemExist(itemId) {
    let mainPrice = document.getElementById("mainPrice" + itemId);
    let disPrice = document.getElementById("discountPrice" + itemId);
    let data = this.itemIdsExist.indexOf(itemId);
    if (data >= 0) {
      //console.log("ItemID exist");
      return true;
    } else return false;
  }

  //Funciton to select/deselect menu items on checkbox event
  onCheckChange(event, itemId: any) {
    let mainPrice = document.getElementById("mainPrice" + itemId);
    let disPrice = document.getElementById("discountPrice" + itemId);

    const formArray: FormArray = this.adddiscountForm.get(
      "itemIds"
    ) as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
      console.log("checked");
      mainPrice.className =
        "font-weight-bold mr-2 font-weight-bold line-through";
      disPrice.className = "font-weight-bold mr-2 text-success";
    } else {
      mainPrice.className = "font-weight-bold mr-2 font-weight-bold";
      disPrice.className = "font-weight-bold mr-2 text-success d-none";

      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  //Function to display offer item list and hide
  showHideOfferItem(num): void {
    if (num == 2) {
      this.isShowOfferItem = true;
      if (this.editing == false) {
        //this.loadMenuItems("");
      }
    } else {
      this.isShowOfferItem = false;
    }
  }
  //Function to change discount rate
  // changeDiscountRate() {
  //   this.dynamicDiscountRate = this.adddiscountForm.get("discountRate").value;
  //   if (this.isShowOfferItem) {
  //     //this.loadMenuItems("");
  //   }
  // }

  // View offer on banner
  showDiscountValue(offerValue: any): void {
    let label = "";
    if (offerValue > 0) label = offerValue + "% off";
    document.getElementById("disoffer").innerHTML = label;

    //console.log(titleValue);

    this.dynamicDiscountRate = this.adddiscountForm.get("discountRate").value;
    if (this.isShowOfferItem) {
      //this.loadMenuItems("");
    }
  }

  //Function to select all items of category
  checkUncheckCategory(event, ci) {
    if (this.itemData[ci]) {
      let cat = this.itemData[ci];
      for (let i = 0; i < cat.subCat.length; i++) {
        for (let j = 0; j < cat.subCat[i].catItems.length; j++) {
          let itemId = cat.subCat[i].catItems[j];

          //id="customCheck_{{ ci }}{{subCatIndex}}{{ ii }}"
          let btn: HTMLElement = document.getElementById(
            "customCheck_" + ci + i + j
          ) as HTMLElement;
          btn.click();

          /*if (event.target.checked)
            this.itemIdsExist.push(itemId);
          else {
            let index = this.itemIdsExist.indexOf(itemId);
            this.itemIdsExist.splice(index, 1);
          }*/
        }
      }
    }
  }
}
