import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, tap, map, retry } from "rxjs/operators";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private cs: CommonService
  ) {}

  //Register Function
  register(loginData) {
    let url: string = this.cs.baseUrl + "barRegister";
    return this.http
      .post<any>(url, loginData)
      .pipe(catchError(this.cs.errorHandler));
  }

  //Login Function
  login(loginData) {
    let url: string = this.cs.baseUrl + "barLogin";
    return this.http
      .post<any>(url, loginData)
      .pipe(catchError(this.cs.errorHandler));
  }

  //Forgot Function
  forgotPassword(data) {
    let url: string = this.cs.baseUrl + "barForgotPassword";
    return this.http
      .post<any>(url, data)
      .pipe(catchError(this.cs.errorHandler));
  }
}
