import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-gift-invoice",
  templateUrl: "./gift-invoice.component.html",
  styleUrls: ["./gift-invoice.component.css"]
})
export class GiftInvoiceComponent implements OnInit {
  //Varbiable Declation
  currencySymbol = '';
  bar: any = [];
  orderData: any = [];
  paymentData: any = [];
  giftItems: any = [];
  orderId: any = 0;
  barId: any = 0;

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    this.barId = localStorage.getItem("barId");
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.orderId = params.get("id");
      if (this.orderId.length > 0) {
        this.loadOrderDetails();
      }
    });
  }

  //Function to call Order Details
  loadOrderDetails() {
    const formData = new FormData();
    formData.append("barId", this.barId);
    formData.append("orderId", this.orderId);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "getGiftInvoice").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.bar = response.data.bar;
          this.currencySymbol =response.currenct;
          this.giftItems = response.data.giftItems;
          this.orderData = response.data.order;
        } else {
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to go back to previous route
  goBack() {
    history.back();
    return false;
  }

  calculateNetAmt(grandTotal, advance) {
    let amount: any = (grandTotal - advance).toFixed(2);
    return amount > 0 ? amount : 0;
  }

  printInvoice() {
    document.getElementById("printable").style.display = "block";
    window.print();
    document.getElementById("printable").style.display = "none";
  }
}
