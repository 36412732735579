import { Component, OnInit } from "@angular/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl
} from "@angular/platform-browser";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    //this.cs.isAdminRoute();
    this.cs.isAdminOrStaffRoute();

    // custom validator to check that two fields match
    function MustMatch(controlName: string, matchingControlName: string) {
      return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
        } else {
          matchingControl.setErrors(null);
        }
      };
    }

    //passwordForm Form Model Declration with form validation for the fields
    this.passwordForm = this.fb.group(
      {
        //barId: [localStorage.getItem("barId")],
        oldPass: ["", [Validators.required]],
        newPass: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
            )
          ]
        ],
        confPass: ["", [Validators.required]]
      },
      {
        validator: MustMatch("newPass", "confPass")
      }
    );
  }
  onPasswordSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;
    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("userId", localStorage.getItem("userId"));
      formData.append("userType", localStorage.getItem("userType"));
      formData.append("token", localStorage.getItem("token"));
      formData.append("oldPass", this.passwordForm.get("oldPass").value);
      formData.append("newPass", this.passwordForm.get("newPass").value);
      formData.append("confPass", this.passwordForm.get("confPass").value);
      formData.append("token", localStorage.getItem("token"));

      //Form submit by appi call
      this.bs.callAPI(formData, "adminChangePassword").subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.passwordForm.reset();
          //this.resetForm();

          //Update password in remember me
          if (localStorage.getItem("loginPassword")) {
            window.localStorage.setItem("loginPassword", this.newPass.value);
          }

          this.router.navigate(["/change-password"]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    }
  }
  //Reset the Form
  resetForm() {
    this.passwordForm.patchValue({
      oldPass: "",
      newPass: "",
      confPass: ""
    });
  }
  //Getor - To access the fields directly in the form in HTML
  get oldPass() {
    return this.passwordForm.get("oldPass");
  }
  get newPass() {
    return this.passwordForm.get("newPass");
  }
  get confPass() {
    return this.passwordForm.get("confPass");
  }
}
