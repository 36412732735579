import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MapsAPILoader, MouseEvent } from "@agm/core";
@Component({
  selector: "app-add-bar",
  templateUrl: "./add-bar.component.html",
  styleUrls: ["./add-bar.component.css"]
})
export class AddBarComponent implements OnInit {
  barForm: FormGroup;

  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _activatedRoute: ActivatedRoute
  ) {
    // router.routerState.queryParams.subscribe(data => console.log('queryParams', data['st']));
    //   this._activatedRoute.params.subscribe(params => {
    //     console.log('params', params);
    //   });
  }

  ngOnInit() {
    // Admin routes validation
    if (window.localStorage.getItem("roleId") != "5") this.cs.isAdminRoute();

    //Profile Form Model Declration with form validation for the fields
    this.barForm = this.fb.group({
      id: [""],
      barName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
          //Validators.pattern("^[a-zA-Z () &]+$")
        ]
      ],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]]
    });
  }
  //Function to submit data
  onBarSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;
    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      //formData.append("id", this.barForm.get("id").value);
      formData.append("barName", this.barForm.get("barName").value);
      formData.append("email", this.barForm.get("email").value);
      formData.append("phone", this.barForm.get("phone").value);
      formData.append("loginLink", this.cs.siteUrl + "login");
      formData.append("userId", window.localStorage.getItem("userId"));
      formData.append("token", localStorage.getItem("token"));


      //Form submit by appi call
      this.bs.callAPI(formData, "addBarOrPub").subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.router.navigate(["/view-bar"]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    }
  }

  //Getor - To access the fields directly in the form in HTML
  get barName() {
    return this.barForm.get("barName");
  }
  get email() {
    return this.barForm.get("email");
  }
  get phone() {
    return this.barForm.get("phone");
  }
}
