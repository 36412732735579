import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { exit, title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
  selector: 'app-event-manager-create',
  templateUrl: './event-manager-create.component.html',
  styleUrls: ['./event-manager-create.component.css']
})
export class EventManagerCreateComponent implements OnInit {

  

  staffForm: FormGroup;
  actionType = "add";
  pageTitle: string;
  dynamicRoute: any = "";
  imageSave = false;
  imageSaveLogo = false;
  FileErrorMessageLogo = false;
  FileErrorMessageCover = false;
  croppedImage: any = "";
  imageChangedEvent: any = "";
  imageChangedEventLogo: any = "";
  logoImageSave: any = null;
  logoPath = null;
  maintables = [];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
   //Profile Form Model Declration with form validation for the fields
   this.staffForm = this.fb.group({
    id: [""],
    eventManagerName: [
      "",
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(25),
      ]
    ],
    email: [""],
    phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
    password:["",[Validators.required]],
    status: [""],
    profilePic:[""],
    // password:[""],
  });
  //Access the Paramter from URL
  this.route.paramMap.subscribe((params: ParamMap) => {
    let id = parseInt(params.get("id"));
    if (id) {
      this.loadStaffData(id);
      this.actionType = "edit";
      this.pageTitle = "Edit Event Manager";
      this.dynamicRoute = "createEventManager";
      this.staffForm = this.fb.group({
        id: [""],
        eventManagerName: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(25)
          ]
        ],
        email: [""],
        phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
        password:[""],
        status: [""],
        profilePic:[""],
      });
     
    } else {
      this.actionType = "add";
      this.pageTitle = "Add Event Manager";
      this.dynamicRoute = "createEventManager";
    }
  });
 
  this.dropdownSettings= {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
}

//Function to submit data
onStaffSubmit() {
  this.myapp.hideAlerts();
  let canSubmit = true;
  if (canSubmit) {
    this.myapp.isLoadingResults = true;
    // this.getAllSelectedItems();
   
    const formData = new FormData();
    formData.append("id", this.staffForm.get("id").value);
    formData.append("name", this.staffForm.get("eventManagerName").value);
    formData.append("email", this.staffForm.get("email").value);
    formData.append("roleId", '12');
    formData.append("status", this.staffForm.get("status").value);
    formData.append("phone", this.staffForm.get("phone").value);
    formData.append("password", this.staffForm.get("password").value);
    formData.append("userId", window.localStorage.getItem("userId"));
    formData.append("profilePic", this.staffForm.get("profilePic").value);

    formData.append("token", localStorage.getItem("token"));

    //Form submit by appi call
    this.bs.callAPI(formData, this.dynamicRoute).subscribe(response => {
      console.log(response);
      
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.router.navigate(["/event-manger"]);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }
}
loadStaffData(id) {
  const staffData = new FormData();
  staffData.append("id", id);
  staffData.append("token", localStorage.getItem("token"));
  this.bs.callAPI(staffData, "editEventManager").subscribe(
    (response: any) => {
      console.log(response);
      
      if (response.status == 1) {
        this.staffForm.patchValue({
          id: response.data.id,
          eventManagerName: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          status: response.data.status
        });
        // const selectedTableIds = response.data.waiterTables.split(',').map(id => +id);
        
       
      
      }
    },
    error => {
      console.log(error);
    }
  );
}


 

   //on File select, put the selected file in Profile Form model variable
   onFileSelect(event, form) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let docName = file.name;
      //console.log("File Size: " + file.size);
      if (form == "profileForm") {
        // console.log('pkkkk');
        
        var fileMimes = file.type.split("/");
        var fileType = fileMimes[0];
  
        if (fileType === "image") {
          this.imageSaveLogo = true;
          this.imageChangedEventLogo = event;
          this.FileErrorMessageLogo = false;
          this.logoImageSave = this.staffForm.get("profilePic").setValue(file);
          this.staffForm.get("profilePic").setValue(file);
          //console.log('ookjkjj');
          
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.logoPath = reader.result;
          };
          return;
        } else {
          this.FileErrorMessageLogo = true;
        }
      } 
      
      
     
      
    }
  }
resetForm() {
  this.staffForm.reset();
  //Patch the required data in Form Modal
  this.staffForm.patchValue({
    id: "",
    eventManagerName: "",
    phone: "",
    email: "",
    password:""
  });
}
get eventManagerName() {
  return this.staffForm.get("eventManagerName");
}
get email() {
  return this.staffForm.get("email");
}
get phone() {
  return this.staffForm.get("phone");
}
get status() {
  return this.staffForm.get("status");
}
get password() {
  return this.staffForm.get("password");
}


}
