import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
@Component({
  selector: "app-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.css"]
})
export class TopBarComponent implements OnInit {
  topBarList = [];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    this.loadTopBarList();
  }
  //Define loadTicketBooking function
  loadTopBarList() {
    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getTopBarList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.topBarList = response.data;
        } else {
          this.topBarList = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }
}
