import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-kot-list",
  templateUrl: "./kot-list.component.html",
  styleUrls: ["./kot-list.component.css"]
})
export class KotListComponent implements OnInit {
  //Varbiable Declation
  kotData = [];
  bar: any = [];
  orderId = "";
  orderPKey: any = "";
  selectedOrder = "";
  notifyOrderList = [];
  printContent = '';
  barGST='';
  adminGST='';

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get("id");
      if (id.length > 0) {
        this.selectedOrder = id;
        this.loadOrderKotDetails(id);
      }
    });
  }

  //loadOrderKotDetails defination
  loadOrderKotDetails(orderId) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("orderId", orderId); //Unique ID of the order
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getOrderKots").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          this.kotData = response.data;
          this.bar = response.bar;
          this.orderId = response.data[0].orderId;
          this.orderPKey = response.data[0].orderPKey;
          this.adminGST =response.adminGSTNumber;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // Function to serve KOT Items, final status
  serveKot(kotId, orderPKey, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: "Are you sure you want to dispatch this order?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result == true) {
        const itemData = new FormData();
        // itemData.append("barId", localStorage.getItem("barId"));
        itemData.append("orderId", orderPKey);
        itemData.append("kotId", kotId);
        itemData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs
          .callAPI(itemData, "updateKotServeStatus")
          .subscribe(response => {
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              this.kotData[index].status = 3;
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
            }
          });
      }
    });
  }

  //Function to delete KOT Items
  deleteKot(kotId, orderPKey, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const itemData = new FormData();
        itemData.append("barId", localStorage.getItem("barId"));
        itemData.append("orderId", orderPKey);
        itemData.append("kotId", kotId);
        itemData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(itemData, "editKotItems").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            //Delete the item from array
            this.kotData.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }

  //Function to accept KOT Items
  acceptKot(kotId, orderId, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want confirm this order?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        this.updateNotifyorderList(orderId);
        const itemData = new FormData();
        itemData.append("status", "2");
        itemData.append("kotId", kotId);
        itemData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(itemData, "changeKotStatus").subscribe(response => {
          if (response.status === 1) {
            this.kotData[index].status = 2;
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }

  //Function to accept KOT Items
  rejectKot(kotId, orderId, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to reject this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        this.updateNotifyorderList(orderId);
        const itemData = new FormData();
        itemData.append("status", "4");
        itemData.append("kotId", kotId);
        itemData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(itemData, "changeKotStatus").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            //Delete the item from array
            this.kotData.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }

  //Function to go back to previous route
  goBack() {
    if (history.length > 1) {
      //alert('notification is click in if part');
      history.back();
    } else {
      //alert('notification is click in else part')
      this.router.navigate(["/order-history"]);
    }
    return false;
  }

  updateNotifyorderList(orderId) {
    //console.log("update Notification function is call");
    if (localStorage.hasOwnProperty("barNotifyList")) {
      //console.log("update Notification function pass condition 1");

      let barNotifyList = JSON.parse(localStorage.getItem("barNotifyList"));
      //console.log("Bar NOtify list: " + barNotifyList.notifyOrderList);
      this.notifyOrderList = JSON.parse(barNotifyList.notifyOrderList);

      // console.log(
      //   "update Notification function pass condition 2: " + this.notifyOrderList
      // );
      let updatedOrderList = [];
      //console.log("update Notification function pass condition 3");
      const index: number = this.notifyOrderList.indexOf(orderId);
      //console.log("Index: " + index);
      if (index !== -1) {
        this.notifyOrderList.splice(index, 1);
      }
      //console.log("Array after removing order ID:" + this.notifyOrderList);

      let newObj = {
        notifyOrderList: JSON.stringify(this.notifyOrderList),
        notifyDate: this.cs.todayDate()
      };
      localStorage.setItem("barNotifyList", JSON.stringify(newObj));
      //console.log("updated notifyOrderList is: " + this.notifyOrderList);
    }
  }

  // printKot(index) {
  //   /*var printContents = document.getElementById("printable_" + index).innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;*/
      
  //   document.getElementById("printable_" + index).style.display = "block";
  //   window.print();
  //   document.getElementById("printable_" + index).style.display = "none";
  // }


  printKot(index) {
    alert('ppp');
    const printableElement = document.getElementById("printable_" + index);
    printableElement.classList.add('no-print'); // Hide non-printable content
  
    window.print();
  
    printableElement.classList.remove('no-print'); // Show the content again
  }
  
  pagesContent = [
    this.kotData,
    // Add more pages as needed
  ];
  printMultiplePages() {
    this.printContent = ''; // Clear the previous print content
  
    for (const kot of this.kotData) {
      const kotTypeText = kot.kotType === 'food' ? 'KOT' : 'BOT'; // Use a ternary operator
      const KotItems = kot.items;
      const createdAtDate: Date = new Date(kot.createdAt);
      const year = createdAtDate.getFullYear();
      const month = (createdAtDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
      const day = createdAtDate.getDate().toString().padStart(2, '0');
      
      const dateOnly: string = `${year}-${month}-${day}`;
      // Initialize a variable to store the HTML for items
      let itemContent = '';
  
      // Loop through KotItems to generate the HTML for items
      for (const item of KotItems) {
        itemContent += `
          <tr>
            <td style="color:#000; text-align: left">${item.itemName}</td>
            <td style="color:#000; text-align: right">${item.quantity}</td>
          </tr>
        `;
      }
  
      this.printContent += `
        <div *ngFor="let kot of kotData; index as i" [attr.id]="'printable_' + i" class="printable" style="padding:5mm">
          <p style="text-align: center">Powered by ${this.myapp.appName}</p>
          <h4 style="color: #000; margin-bottom: 0;text-align: center;width: 100%; margin-bottom: 0">${this.bar.barName}</h4>
          <p style="color: #000; margin-bottom: 0;text-align: center;width: 100%; margin-top: 0">${this.bar.address}</p>
          <p style="color: #000; text-align: center;width: 100%;">
            <span>${kotTypeText}</span> RECEIPT <!-- Use the conditional text here -->
          </p>
          <table style="width: 100%">
            <tr>
              <td style="color:#000; text-align: left">Date: ${dateOnly}</td>
              <td style="color:#000; text-align: right"><span>${kotTypeText}</span>: #${kot.kotNumber}</td>
            </tr>
            <tr>
              <td style="color:#000; text-align: left">Order No: #${this.orderId}</td>
              <td style="color:#000; text-align: right">Visit code: #${kot.visitCode}</td>
            </tr>
          </table>
          <table style="width: 100%">
            <thead>
              <tr>
                <th style="color:#000; text-align: left; border-top:1px dashed #ddd; border-bottom:1px dashed #ddd;">ITEM</th>
                <th style="color:#000; text-align: right; border-top:1px dashed #ddd; border-bottom:1px dashed #ddd;">QTY</th>
              </tr>
            </thead>
            <tbody>
              ${itemContent} <!-- Insert the generated item content here -->
            </tbody>
          </table>
        </div>
      `;
    }
  
    const printWindow = window.open('', '', 'width=600,height=600');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Multiple Pages</title>
          <style>
            /* Add your custom styles here */
            div { page-break-before: always; }
          </style>
        </head>
        <body>${this.printContent}</body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }
  
    

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to close the order
  /*closeOrder() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to close this tab?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        const postData = new FormData();
        postData.append("orderId", this.orderPKey);
        this.bs.callAPI(postData, "changeOrderStatus").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            this.router.navigate(["/invoice/" + this.orderId]);
          } else if (response.status === 2) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            this.ngOnInit();
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });

        //this.myapp.changeStatus("bnOrders", this.visitData["orderPKey"], 3);
        //this.router.navigate(["/invoice/" + this.visitData["orderId"]]);
      }
    });
  }*/
}
