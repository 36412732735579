import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: "app-order-history",
  templateUrl: "./order-history.component.html",
  styleUrls: ["./order-history.component.css"]
})
export class OrderHistoryComponent implements OnInit {
  //Variable Declration
  public timeType: string = "today";
  toDisabled = false;

  orderData = [];
  // currencySymbol = localStorage.getItem("currency");
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  offerData: any = {};

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    
    this.filterForm = this.fb.group({
      from: [""],
      to: [""],
      keyword: [""],
      orderType: [""],
      status: [""],
      
    });
    // let currencySymbol = localStorage.getItem("currency");
    // alert(currencySymbol);
  //  this.currencySymbol= localStorage.getItem("currency");
    //Call the Orders Function
    //this.loadOrders("", "", ""); //three empty arguments, bcz this function is using in filters aslo

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let orderId = params.get("orderId");
      if (orderId) {
        this.filterForm.patchValue({
          keyword: orderId
        });

        this.loadOrders(orderId, "", "",this.timeType);
      } else{
        this.loadOrders("", "", "",this.timeType);
      } 
    });
  }

  //Load Orders defination
  loadOrders(keyword, orderType, status,timeType) {
    if (this.timeType == "today") {
      this.filterForm.get("from").setValue("");
      this.filterForm.get("to").setValue("");
    } else {
      
    }
   

    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));

    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("orderType", this.filterForm.get("orderType").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("token", localStorage.getItem("token"));
    barData.append("time", timeType);
    if (this.timeType == "today") {
      
    } else {
      let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
      let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

      barData.append("from", from);
      barData.append("to", to);
    }

    /*barData.append("keyword", keyword);
    barData.append("orderType", orderType);
    barData.append("status", status);*/

    this.bs.callAPI(barData, "getOrdersList").subscribe(
      (response: any) => {
        console.log(response.currency);
        
        if (response.status == 1) {
          // this.currencySymbol =response.currency;
          this.orderData = response.data;
          //console.log(this.orderData);
        } else {
          this.orderData = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

   //Function to change report time
   changeReportType(time) {
    
    if (time == "today") {
      this.timeType = time;
      this.loadOrders("","","",time);
    } else {
      this.timeType = time;
      this.orderData = [];
      
      this.calculateEndDate();
    }

    console.log(time);
    
  }

  //Function to calculate end date
  calculateEndDate() {
    this.toDisabled = true;
    let from = new Date(this.filterForm.get("from").value);
    var year = from.getFullYear();
    var month = from.getMonth();
    var date = from.getDate();
    var hour = from.getHours();
    var newDate;

    if (this.timeType == "yearly")
      newDate = new Date(year + 1, month, date - 1, hour);
    else if (this.timeType == "monthly") {
      newDate = new Date(year, month + 1, date - 1, hour);
    } else if (this.timeType == "weekly") {
      newDate = new Date(year, month, date + 6, hour);
    } else {
      this.toDisabled = false;
    }
    this.filterForm.get("to").setValue(new Date(newDate));
  }

  // Function to filter reports
  filterReport(email ,status, keyword) {
    let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
    let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("status", status);
    barData.append("token", localStorage.getItem("token"));
    barData.append("from", from);
    barData.append("to", to);

    this.bs.callAPI(barData, "getOrdersList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.orderData = response.data;
          //console.log(this.visitData);
        } else {
          this.orderData = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
    let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("orderType", this.filterForm.get("orderType").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));
    barData.append("from", from);
    barData.append("to", to);

    this.bs.callAPI(barData, "getOrdersList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.orderData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let orderType = this.filterForm.get("orderType").value;
    let status = this.filterForm.get("status").value;
    this.loadOrders(keyword, orderType, status,this.timeType);
  }

  //On Applied/Status filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let orderType = this.filterForm.get("orderType").value;
    let status = this.filterForm.get("status").value;
    this.loadOrders(keyword, orderType, status,this.timeType);
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  loadOfferData(index) {
    this.offerData = this.orderData[index].offer;
  }
}
