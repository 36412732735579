import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-all-orders",
  templateUrl: "./all-orders.component.html",
  styleUrls: ["./all-orders.component.css"]
})
export class AllOrdersComponent implements OnInit {
  public timeType: string = "today";
  toDisabled = false;
  //Variable Declration
  orderData = [];
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;
  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;
  barId: any;
  restaurantName: any;


  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the Orders Function
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.barId = id;
        // this.loadTicketBooking(id, "");
        // this.loadVisits(id, "");
        // this.loadDiscounts();
      } else {
      }
    });
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      from: [""],
      to: [""],
      keyword: [""],
      orderType: [""],
      status: [""],
      city: [localStorage.getItem("activeCity")],
      country: [localStorage.getItem("activeCountryId")]
    });
    this.loadOrders("", "", "",this.timeType);
    this.cities = this.myapp.allCities;
    this.countries = this.myapp.allCountries;
  }

  //Load Orders defination
  loadOrders(keyword, orderType, status,timeType) {
    if (this.timeType == "today") {
      this.filterForm.get("from").setValue("");
    this.filterForm.get("to").setValue("");

    } else {

    }

    const barData = new FormData();
    barData.append("barId", this.barId);
    barData.append("keyword", keyword);
    barData.append("orderType", orderType);
    barData.append("status", status);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("countryId", this.filterForm.get("country").value);
    barData.append("userId", window.localStorage.getItem("userId"));
    barData.append("token", localStorage.getItem("token"));
    if (this.timeType == "today") {
     
    } else {
      let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
      let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

      barData.append("from", from);
      barData.append("to", to);
    }

    this.bs.callAPI(barData, "getOrdersList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.orderData = response.data;
          this.restaurantName = response.restaurantName;
          //console.log(this.orderData);
        } else {
          this.orderData = [];
          this.restaurantName = response.restaurantName;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
   //Function to change report time
   changeReportType(time) {
    if (time == "today") {
      this.timeType = time;
      this.loadOrders("","","",time);
    } else {
      this.timeType = time;
      this.orderData = [];
      
      this.calculateEndDate();
    }
  }

  //Function to calculate end date
  calculateEndDate() {
    this.toDisabled = true;
    let from = new Date(this.filterForm.get("from").value);
    var year = from.getFullYear();
    var month = from.getMonth();
    var date = from.getDate();
    var hour = from.getHours();
    var newDate;

    if (this.timeType == "yearly")
      newDate = new Date(year + 1, month, date - 1, hour);
    else if (this.timeType == "monthly") {
      newDate = new Date(year, month + 1, date - 1, hour);
    } else if (this.timeType == "weekly") {
      newDate = new Date(year, month, date + 6, hour);
    } else {
      this.toDisabled = false;
    }
    this.filterForm.get("to").setValue(new Date(newDate));
  }

  // Function to filter reports
  filterReport(email ,status, keyword) {
   
    let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
    let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

    const barData = new FormData();
    barData.append("barId",this.barId);
    barData.append("keyword", keyword);
    barData.append("orderType", '');
    barData.append("status", status);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("countryId", this.filterForm.get("country").value);
    barData.append("userId", window.localStorage.getItem("userId"));
    barData.append("token", localStorage.getItem("token"));
    barData.append("from", from);
    barData.append("to", to);

    this.bs.callAPI(barData, "getOrdersList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.orderData = response.data;
          //console.log(this.visitData);
        } else {
          this.orderData = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", this.barId);
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("orderType", this.filterForm.get("orderType").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("counrtyId", this.filterForm.get("country").value);
    barData.append("page", pageNo);
    barData.append("userId", window.localStorage.getItem("userId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getOrdersList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.orderData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let orderType = this.filterForm.get("orderType").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    this.loadOrders(keyword, orderType, status,this.timeType);
  }

  //On Applied/Status filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let orderType = this.filterForm.get("orderType").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    this.loadOrders(keyword, orderType, status,this.timeType);
  }

   // Function to filer Cities - for Bar
   filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange();
  }

  // Function to filer Cities - for Bar
  filterByCountry(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.countriesSorted = false;
    } else {
      let keyword = this.filterForm.get("country").value;
      if (keyword.length > 0) {
        let output = [];
        this.countries.forEach(function(countries) {
          if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(countries);
          }
        });
        if (output.length > 0) {
          this.sortedCountries = output;
          this.countriesSorted = true;
        } else {
          this.sortedCountries = [];
          this.countriesSorted = false;
        }
      } else {
        this.sortedCountries = [];
        this.countriesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBoxcountry(str) {
    this.filterForm.patchValue({
      country: str
    });
    this.sortedCountries = [];
    this.countriesSorted = false;
    this.onKeyWordChange();
  }



  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }
}
