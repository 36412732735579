import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
@Component({
  selector: 'app-event-manager-events',
  templateUrl: './event-manager-events.component.html',
  styleUrls: ['./event-manager-events.component.css']
})
export class EventManagerEventsComponent implements OnInit {

  public timeType: string = "Ongoing";
  toDisabled = false;
  //Varbiable Declation
  eventList = [];
  page = 1; //used for load more
  hideLoadMore = true;
  filterForm: FormGroup;
  todayDay: string;
  eventId: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the loadSpecialEvent function
    
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      from: [""],
      to: [""],
      keyword: [""],
      date: [""],
      status: [""]
    });
    // this.loadSpecailEvent("", "", "",this.timeType);
    // let today = new Date();
    // let dd = String(today.getDate()).padStart(2, "0");
    // let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    // let yyyy = today.getFullYear();
    // this.todayDay = yyyy + "-" + mm + "-" + dd;
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.eventId = id;
        console.log(id);
        
        this.loadSpecailEvent("","",id);
      } else {
      }
    });
  }
  //Define Special event function
  loadSpecailEvent(keyword, date,barId) {
    const barData = new FormData();
    barData.append("barId",barId );
    barData.append("keyword", keyword);
    // barData.append("date", date);
    // barData.append("status", status);
    // barData.append("token", localStorage.getItem("token"));
    // barData.append("time", timeType);
    this.bs.callAPI(barData, "getEventManagerSpecialEvents").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          this.eventList = response.data;
        } else {
          this.eventList = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to change report time
  changeReportType(time) {
    if (time == "Ongoing") {
      this.timeType = time;
      this.eventId;
      this.loadSpecailEvent("","",this.eventId);
    } else {
      this.timeType = time;
      this.eventList = [];
      this.loadSpecailEvent("","",this.eventId);
    }
  }

 
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", this.eventId);
    barData.append("keyword", this.filterForm.get("keyword").value);
    // barData.append("date", this.filterForm.get("date").value);
    // barData.append("status", this.filterForm.get("status").value);
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(barData, "getEventManagerSpecialEvents").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.eventList.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let date = this.filterForm.get("date").value;
    //alert("date:" + date);
    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }
    //let status = this.filterForm.get("status").value;
    //alert("keyword:" + keyword + "date:" + date + "status:" + status);
    this.loadSpecailEvent(keyword, '', this.eventId);
  }
  //On Applied/Status filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let date = this.filterForm.get("date").value;
    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }
    let status = this.filterForm.get("status").value;
    //alert('keyword:'+keyword +'date:' +date+ 'status:' +status);
    this.loadSpecailEvent(keyword, '', this.eventId);
  }
  //Function to Active/Inactive records
  changeStatus(id, value, index) {
    this.myapp.changeStatus("bnSpecialEvents", id, value);
    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.eventList[index].status = true;
    else if (value == 0) this.eventList[index].status = false;
  }
  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  // Function to return time in am/pm format on HTML
  convertTime(startTime, endTime) {
    return (
      this.cs.convertTime(startTime) + " - " + this.cs.convertTime(endTime)
    );
  }

}
