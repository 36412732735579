import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";


@Component({
  selector: 'app-event-manager',
  templateUrl: './event-manager.component.html',
  styleUrls: ['./event-manager.component.css']
})
export class EventManagerComponent implements OnInit {

  staffData = [];
  hideLoadMore = true;
  page = 1; //used for load more
  filterForm: FormGroup;
  showModal = true;
  cancelForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}
  
  ngOnInit() {
    this.loadStaffData("", "");

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      status: [""],
      // roleId: [""]
    });
  }

  //Load staff data defination
  loadStaffData(keyword, status) {
    const userData = new FormData();
    userData.append("keyword", keyword);
    userData.append("status", status);
    // userData.append("roleId", roleId);
    // userData.append("barId", window.localStorage.getItem("barId"));
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "getEventManager").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          this.staffData = response.data;
        } else {
          this.staffData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

   //Function to Active/Inactive records
   changeStatus(id, value, index) {
    if (value == 1) {
      var newd = 'activate';
    }else if(value == 3){
      var newd = 'reject';
    }else if(value == 0){
      var newd = 'Active';
    }else{
      var newd = '';
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      
      data: { message: "Are you sure you want to "+newd +" this Event Manager profile?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        const param = new FormData();
        param.append("id", id);
        param.append("value", value);
        param.append("token", localStorage.getItem("token"));
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(param, "eventManagerActiveRequest").subscribe(response => {
          console.log(response);
          
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            //Delete the item from array
            this.loadStaffData('','');
            // this.staffData.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }

  

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("page", pageNo);
    userData.append("status", this.filterForm.get("status").value);
    // userData.append("roleId", this.filterForm.get("roleId").value);
    userData.append("barId", window.localStorage.getItem("barId"));
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "getBarZones").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.staffData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    // let roleId = this.filterForm.get("roleId").value;
    this.loadStaffData(keyword, status);
  }
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    // let roleId = this.filterForm.get("roleId").value;
    this.loadStaffData(keyword, status);
  }

 
  deleteStaff(id, index) {
    console.log("Deleted Staff id:" + id);
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const param = new FormData();
        param.append("id", id);
        param.append("token", localStorage.getItem("token"));
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(param, "deleteWaiter").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            //Delete the item from array
            this.staffData.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }


}
