// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../../app/service/bar.service";
import { CommonService } from "../../../app/service/common.service";
import { AppComponent } from "../../../app/app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";

@Component({
  selector: 'app-admin-app-version',
  templateUrl: './admin-app-version.component.html',
  styleUrls: ['./admin-app-version.component.css']
})
export class AdminAppVersionComponent implements OnInit {

  templateForm: FormGroup;
  actionType = "add";
  pageTitle: string;
  dynamicRoute: any = "";
  FileErrorMessageTemplate = false;
  templatePrePath = null;
  templatePath = this.cs.tempPath();
  templateList = [];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();
    this.templateForm = this.fb.group({
      // id: [""],
      // templateName: [""], //[Validators.required]
      // mediaName: ["", [Validators.required]]
      android_version:[""],
      ios_version:[""]
    });
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.actionType = "edit";
        this.pageTitle = "Admin App Versions";
        this.dynamicRoute = "editTemplate";
        this.loadTemplateData(id);
      } else {
        this.actionType = "add";
        this.pageTitle = "Admin App Versions";
        this.dynamicRoute = "addTemplate";
      }
    });
    this.loadTemplateList();
  }
  

  onFormSubmit() {
    // alert('pp');
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    const bannerData = new FormData();
    // bannerData.append("id", this.templateForm.get("id").value);
    bannerData.append(
      "android_version",
      this.templateForm.get("android_version").value
    );
    bannerData.append(
      "ios_version",
      this.templateForm.get("ios_version").value
    );
    // bannerData.append("mediaName", this.templateForm.get("mediaName").value);
    // console.log(bannerData);
    bannerData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(bannerData, "addAppVersion").subscribe(response => {
      console.log(response);
      
      if (response.status === 1) {
        if (this.actionType == "edit") {
          this.templatePrePath = null;
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.router.navigate(["/add-banner-template"]);
        } else {
          this.templatePrePath = null;
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.templateForm.reset();
          //this.templatePath = null;
          //Add the latest added image in Array
          this.templateList.unshift(response.data);
        }
        
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }
  //Load template data defination
  loadTemplateList() {
    this.bs.callAPI("", "getAppVersions").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          this.templateList = response.data;
          console.log(this.templateList.length);
          
          
        } else {
          this.templateList = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //load template data for edit
  loadTemplateData(id) {
    const param = new FormData();
    param.append("id", id);
    param.append("token", localStorage.getItem("token"));
    this.bs.callAPI(param, "getAppVersions").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.templateForm.patchValue({
            // id: response.data.id,
            // templateName: response.data.templateName,
            // mediaName: ""
          });
          this.templatePrePath = this.templatePath + response.data.mediaName;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  get android_version() {
    return this.templateForm.get("android_version");
  }
  get ios_version() {
    return this.templateForm.get("ios_version");
  }
  get email() {
    return this.templateForm.get("mediaName");
  }


}
