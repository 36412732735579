import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-areas",
  templateUrl: "./areas.component.html",
  styleUrls: ["./areas.component.css"]
})
export class AreasComponent implements OnInit {
  // Variable declration
  areaForm: FormGroup;
  formHeading = "Create Areas";
  btnText = "Save";
  showCancelBtn = false;
  allCities: any = [];
  allStates: any = [];
  allAreas: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the loadStates function
    this.loadStates();
    this.loadCityAreas();

    //Profile Form Model Declration with form validation for the fields
    this.areaForm = this.fb.group({
      id: [""],
      areas: ["", [Validators.required]],
      stateId: ["", [Validators.required]],
      cityId: ["", [Validators.required]]
    });
  }

  //loadCityAreas function defination
  loadCityAreas() {
    this.myapp.hideAlerts();
    this.bs.callAPI(null, "getCityAreas").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.allAreas = response.data;
        } else {
          //this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load States
  loadStates() {
    this.myapp.hideAlerts();
    const formData = new FormData();
    //formData.append("stateId", "0");
    this.bs.callAPI(formData, "getAllStates").subscribe(
      (response: any) => {
        // console.log(response);
        if (response.status == 1) {
          this.allStates = response.data;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load cities, on States change event
  loadCities(stateId) {
    this.myapp.hideAlerts();
    //const stateId = this.areaForm.get("stateId").value;

    if (stateId != "0") {
      //this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("stateId", stateId);
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getAllCities").subscribe(
        //this.myapp.isLoadingResults = false;
        (response: any) => {
          //console.log(response);
          if (response.status == 1) {
            this.allCities = response.data;
            //console.log(this.allCities);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.allCities = [];
  }

  // Form Submit funciton
  onAreaSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    this.bs
      .callAPI(this.areaForm.value, "updateCityAreas")
      .subscribe(response => {
        if (response.status === 1) {
          console.log(response);
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.areaForm.reset();
          this.loadCityAreas();

          this.showCancelBtn = false;
          this.btnText = "Save";
          this.formHeading = "Create Areas";
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  //Function to edit record, it will show the data in form
  editCityArea(id, area, cityId, stateId) {
    this.myapp.hideAlerts();
    this.showCancelBtn = true;
    this.formHeading = "Edit City Area";
    this.btnText = "Update";

    this.loadCities(stateId);

    this.areaForm.patchValue({
      id: id,
      areas: area,
      cityId: cityId,
      stateId: stateId
    });
  }

  // Function to cancel editing
  cancelEdit() {
    this.myapp.hideAlerts();
    this.showCancelBtn = false;
    this.formHeading = "Create Areas";
    this.btnText = "Save";

    this.areaForm.patchValue({
      id: "",
      areas: "",
      cityId: "",
      stateId: ""
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get stateId() {
    return this.areaForm.get("stateId");
  }
  get cityId() {
    return this.areaForm.get("cityId");
  }
  get areas() {
    return this.areaForm.get("areas");
  }
}
