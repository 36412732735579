import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-create-kot",
  templateUrl: "./create-kot.component.html",
  styleUrls: ["./create-kot.component.css"]
})
export class CreateKotComponent implements OnInit {
  //Varialbe Declration
  currencySymbol = localStorage.getItem("currency");
  visitCancelForm: FormGroup;
  tableForm: FormGroup;
  itemData: any = [];
  visitData: any = [];
  visitId = "";
  cartItems = new Array();
  discountRate = 0;
  itemImgPath = this.cs.itemsPath();
  showModal = true;
  showItems = true;
  showPreviousOrders = false;
  noOrderAllowed = false;
  comment="";
  //filterForm: FormGroup;
  //itemForm: FormGroup;

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    //Call the Bar Items
    this.loadMenuItems("");

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get("id");
      if (id.length > 0) {
        this.visitId = id;
        this.loadVisitInfo(id);
      }
    });

    //visitCancelForm Form Model Declration with form validation for the fields
    this.visitCancelForm = this.fb.group({
      visitId: [this.visitId],
      status: [3],
      reason: [
        "",
        [
          //Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ]
    });

    this.tableForm = this.fb.group({
      tableNo: ["", [Validators.required]]
    });
  }

  //loadVisitInfo defination
  loadVisitInfo(visitId) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("visitId", visitId);
    barData.append("token", localStorage.getItem("token"));
//console.log(visitId);

    this.bs.callAPI(barData, "getVisitOrderInfo").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          this.visitData = response.data;

          if (this.visitData.reedemPending) this.showItems = false;
          else this.showItems = true;

          /*if (
            this.visitData.pendingKots == 0 &&
            this.visitData.readyKots == 0
          ) {
            this.showItems = true;
          } else this.showItems = false;*/
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load Menu Items defination
  loadMenuItems(keyword) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("module", "order"); //To call the discounted price
    barData.append("keyword", keyword);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getItemsList").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          this.itemData = response.data;
          //console.log(this.itemData);
        } else {
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Funciton to search items by keyword
  searchItems(keyword) {
    this.loadMenuItems(keyword);
  }

  //Function to Increase qty of Item in Cart
  increaseQty(ci: any, subCatIndex: any, ii: any) {
    let stock = this.itemData[ci].subCat[subCatIndex].catItems[ii]
      .availableStock;
    let itemId = this.itemData[ci].subCat[subCatIndex].catItems[ii].id;
    let itemName = this.itemData[ci].subCat[subCatIndex].catItems[ii].itemName;
    let price = this.itemData[ci].subCat[subCatIndex].catItems[ii].price;

    //Getting the Discounted/HappyHour Price of items
    if (
      this.itemData[ci].subCat[subCatIndex].catItems[ii].discountType != "offer"
    ) {
      if (this.itemData[ci].subCat[subCatIndex].catItems[ii].discountPrice)
        price = this.itemData[ci].subCat[subCatIndex].catItems[ii]
          .discountPrice;
      if (this.itemData[ci].subCat[subCatIndex].catItems[ii].happyHourPrice)
        price = this.itemData[ci].subCat[subCatIndex].catItems[ii]
          .happyHourPrice;
    }

    let cartData = { itemId: itemId, itemName: itemName, price: price, qty: 1 };

    let index = -1;
    //First search itemId is exit or not in the CART
    for (var i = 0; i < this.cartItems.length; i++) {
      if (this.cartItems[i].itemId === itemId) {
        index = i;
        break;
      }
    }

    if (index < 0) {
      //Insert the Items in Cart
      this.cartItems.push(cartData); //Push data in cart
    } else {
      if (stock > 0) {
        if (this.cartItems[index].qty == stock) {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = "This item doesn't have more stock available";
          return;
        }
      }

      //Update the Items in Cart
      this.cartItems[index].qty++;
      this.cartItems[index].price = price * this.cartItems[index].qty;
    }
  }

  //Function to Decrease qty of Item in Cart
  decreaseQty(ci: any, subCatIndex: any, ii: any) {
    let itemId = this.itemData[ci].subCat[subCatIndex].catItems[ii].id;
    let price = this.itemData[ci].subCat[subCatIndex].catItems[ii].price;

    //Getting the Discounted/HappyHour Price of items
    if (
      this.itemData[ci].subCat[subCatIndex].catItems[ii].discountType != "offer"
    ) {
      if (this.itemData[ci].subCat[subCatIndex].catItems[ii].discountPrice)
        price = this.itemData[ci].subCat[subCatIndex].catItems[ii]
          .discountPrice;
      if (this.itemData[ci].subCat[subCatIndex].catItems[ii].happyHourPrice)
        price = this.itemData[ci].subCat[subCatIndex].catItems[ii]
          .happyHourPrice;
    }

    let index = -1;
    //First search itemId is exit or not in the CART
    for (var i = 0; i < this.cartItems.length; i++) {
      if (this.cartItems[i].itemId === itemId) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      //Update the Items in Cart
      //console.log("update");
      this.cartItems[index].qty--;
      this.cartItems[index].price = price * this.cartItems[index].qty;

      //Get the price if the buy/free offer is available
      if (
        this.itemData[ci].subCat[subCatIndex].catItems[ii].discountType ==
        "offer"
      ) {
        let buy = this.itemData[ci].subCat[subCatIndex].catItems[ii].buy;
        let free = this.itemData[ci].subCat[subCatIndex].catItems[ii].free;
        let offerPrice = this.cs.getOfferPrice(
          buy,
          free,
          this.cartItems[index].qty,
          price
        );

        //Re-update the Price of item in Cart
        this.cartItems[index].price = offerPrice;
      }

      //If qty reaches to 0, then remove the item from cart
      if (this.cartItems[index].qty == 0) this.cartItems.splice(index, 1);
    }
    //console.log(this.cartItems);
    //console.log("total items: " + this.cartItems.length);
  }

  //Submit Funciton
  onOrderSubmit() {
    this.noOrderAllowed = false;
    if (this.visitData.reedemPending) {
      this.noOrderAllowed = true;
      return false;
    }

    //Validation checking
    if (this.cartItems.length > 0) {
      const itemData = new FormData();
      itemData.append("barId", localStorage.getItem("barId"));
      itemData.append("visitId", this.visitId);
      itemData.append("cartItems", JSON.stringify(this.cartItems));
      itemData.append("token", localStorage.getItem("token"));
      //console.log(this.cartItems);
      this.myapp.hideAlerts();
      this.myapp.isLoadingResults = true;
      this.bs.callAPI(itemData, "createOrder").subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.cartItems = new Array();
          this.router.navigate([history.go(-1)]);
          //this.router.navigate(["/kot-list/" + response.orderId]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    } else {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please select an item";
      this.myapp.isLoadingResults = false;
    }
  }

  //Function to reset Cart
  resetCart() {
    this.cartItems = new Array();
  }

  //Function to close the order
  closeOrder() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to close this tab?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      //console.log(result);
      if (result == true) {
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        const postData = new FormData();
        if (this.visitData["orderPKey"]) {
          postData.append("orderId", this.visitData["orderPKey"]);
        } else {
          //If NO ORDER is placed, closing with Gift orders only
          postData.append("visitId", this.visitId);
        }
        postData.append("token", localStorage.getItem("token"));
        this.bs.callAPI(postData, "changeOrderStatus").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;

            if (this.visitData["orderPKey"])
              this.router.navigate(["/invoice/" + this.visitData["orderPKey"]]);
            else this.router.navigate(["/invoice/0/" + this.visitId]);
          } else if (response.status === 2) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            this.ngOnInit();
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });

        //this.myapp.changeStatus("bnOrders", this.visitData["orderPKey"], 3);
        //this.router.navigate(["/invoice/" + this.visitData["invoice"]]);
      }
    });
  }

  //Function to show/hide the visit cancel modal
  cancelVisit() {
    this.showModal = true;
    /*let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to cancel this visit?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        this.myapp.changeStatus("bnVisits", this.visitId, 3);
        this.router.navigate(["/dashboard/"]);
      }
    });*/
  }

  //Function to cancel the visit, if no order is placced
  onVisitCancelSubmit() {
    /*const itemData = new FormData();
    itemData.append("visitId", this.visitId);
    itemData.append("cartItems", JSON.stringify(this.cartItems));*/
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("visitId", this.visitId);
    formData.append("status", "3");
    formData.append("reason", this.reason.value);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "changeVisitStatus").subscribe(response => {
      if (response.status === 1) {
        // Hide the Modal
        this.showModal = false;
        let closeModal: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModal.classList.remove("modal-open");
        let closeModalpadding: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModalpadding.style.padding = "0px";
        let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
          "modal-backdrop"
        )[0] as HTMLElement;
        ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);

        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = "Visit has been cancelled successfully";
        this.myapp.isLoadingResults = false;
        this.router.navigate(["/dashboard/"]);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  confirmKots(orderId, visitId) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to confirm the orders?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const formData = new FormData();
        formData.append("orderId", orderId);
        formData.append("visitId", visitId);
        formData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(formData, "confirmAllKots").subscribe(response => {
         // console.log(response);
          
          if (response.status === 1) {
            this.visitData.readyKots = 1;
            this.visitData.pendingKots = 0;
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            this.router.navigate([history.go(-1)]);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }

  // To dispatch all KOTs
  dispatchKots(orderId) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to dispatch the orders?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const formData = new FormData();
        formData.append("orderId", orderId);
        formData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(formData, "dispatchAllKots").subscribe(response => {
          if (response.status === 1) {
            this.visitData.readyKots = 0;
            //this.showItems = true;
            this.visitData.orderItems = [];

            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            this.router.navigate([history.go(-1)]);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }

  // To dispatch single KOT
  dispatchKot(kotId, orderPKey, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: "Are you sure you want to dispatch this order?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result == true) {
        const itemData = new FormData();
        // itemData.append("barId", localStorage.getItem("barId"));
        itemData.append("orderId", orderPKey);
        itemData.append("kotId", kotId);
        itemData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs
          .callAPI(itemData, "updateKotServeStatus")
          .subscribe(response => {
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              //this.visitData.orderKots[index].status = 3;
              this.visitData.orderKots.splice(index, 1);

              if (this.visitData.orderKots.length == 0)
                this.router.navigate([history.go(-1)]);
              //this.showItems = true;
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
            }
          });
      }
    });
  }

  onTableFormSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("visitId", this.visitId);
    formData.append("sittingType", "table");
    formData.append("tableStandingNo", this.tableNo.value);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "availCode").subscribe(response => {
      if (response.status === 1) {
        this.visitData.tableStandingNo = this.tableNo.value;
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = "Table has been assigned successfully";
        this.myapp.isLoadingResults = false;
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  // Getor - To access the fields directly in the form in HTML
  get reason() {
    return this.visitCancelForm.get("reason");
  }
  get tableNo() {
    return this.tableForm.get("tableNo");
  }

  priceWithDecimal(price) {
    return price.toFixed(2);
  }

  showHidePreviousOrders() {
    if (this.showPreviousOrders) this.showPreviousOrders = false;
    else this.showPreviousOrders = true;
  }

  // Function to return item quantity from cart
  getItemCartQuantity(itemId) {
    if (this.cartItems.length) {
      for (var i = 0; i < this.cartItems.length; i++) {
        if (this.cartItems[i].itemId === itemId) {
          return this.cartItems[i].qty;
        }
      }
    } else return 0;
  }
}
