import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-patron-history",
  templateUrl: "./patron-history.component.html",
  styleUrls: ["./patron-history.component.css"]
})
export class PatronHistoryComponent implements OnInit {
  // Variable Declration
  cancelForm: FormGroup;
  showModal = true;
  cancelReason: any = null;
  public patronData: any;
  public profileInfo = [];
  public visitHistory = [];
  public orderHistory = [];
  public eventHistory = [];
  public messages = [];
  public emailMessages = [];
  public bmType = "push";

  userPicPath = this.cs.userProfilePic();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminCustomerCareRoute();

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let userId = parseInt(params.get("id"));
      if (userId) this.loadPatronItems(userId);
      //console.log("user id" + userId);
    });

    //Cancel Form Model Declration with form validation for the fields
    this.cancelForm = this.fb.group({
      userId: ["", [Validators.required]],
      status: ["", [Validators.required]],
      reason: [
        "",
        [
          //Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ]
    });
  }

  //Load item defination
  loadPatronItems(userId) {
    const userData = new FormData();
    userData.append("userId", userId);
    userData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(userData, "patronProfileInfo").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //console.log("user id 3 " + userId);
          this.patronData = response;
          this.profileInfo = response.data.profileInfo;
          this.visitHistory = response.data.visitHistory;
          this.orderHistory = response.data.orderHistory;
          this.eventHistory = response.data.eventHistory;
          this.messages = response.data.messages;
          this.emailMessages = response.data.emailMessages;
          //console.log(this.profileInfo);
        } else {
          this.patronData = [];
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to Active/Inactive records
  changeStatus(id, status) {
    this.showModal = true;
    this.cancelForm.patchValue({
      userId: id,
      status: status,
      token:localStorage.getItem("token")
    });
    if (status == 1) this.onCancelSubmit();
  }
  // barData.append("token", localStorage.getItem("token"));
  // Function to inactive/active the user
  onCancelSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    this.bs
      .callAPI(this.cancelForm.value, "changeUserStatus")
      .subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.ngOnInit();

          // Hide the Modal
          this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  //Function to show the reason in modal
  loadCancelReason(reason) {
    this.cancelReason = reason;
  }

  changeBmType(type) {
    this.bmType = type;
  }

  // Getor - To access the fields directly in the form in HTML
  get reason() {
    return this.cancelForm.get("reason");
  }
}
