import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-view-menu",
  templateUrl: "./view-menu.component.html",
  styleUrls: ["./view-menu.component.css"]
})
export class ViewMenuComponent implements OnInit {
  //Varbiable Declation
  currencySymbol = localStorage.getItem("currency");
  catData = []; //Used in filter form
  itemData = [];
  menuCount="";
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  itemImgPath = this.cs.itemsPath();
  subCategories = [];
  mainCategories = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) { }

  ngOnInit() {
    //Call the Bar Categories
    //this.loadCategories();

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      barId: [localStorage.getItem("barId")],
      catId: [""],
      subCatId: [""],
      keyword: [""],
      status: [""]
    });

    //Access the Paramter from URL
    /*this.route.paramMap.subscribe((params: ParamMap) => {
      let keyword = params.get("keyword");
      //console.log(keyword);
      //if (keyword)
      this.loadMenuItems(keyword);
    });*/

    this.loadMenuItems("", "");
  }

  //Load Menu items defination
  loadMenuItems(keyword, status) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("status", status);
    barData.append("catId", this.filterForm.get("catId").value);
    barData.append("subCatId", this.filterForm.get("subCatId").value);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getMenu").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.itemData = response.data;
          this.menuCount =response.totel_menu;
          console.log(this.itemData);
        } else {
          this.itemData = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("catId", this.filterForm.get("catId").value);
    barData.append("subCatId", this.filterForm.get("subCatId").value);
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getMenu").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.itemData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load Categories defination
  loadCategories() {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getCategories").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.catData = response.data;
          console.log(this.catData);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to Active/Inactive records
  changeStatus(id, value, index) {
    this.myapp.changeStatus("bnBarItems", id, value);
    //this.ngOnInit();

    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.itemData[index].status = true;
    else if (value == 0) this.itemData[index].status = false;
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    this.loadMenuItems(keyword, status);
    //this.router.navigate(["/view-menu", keyword]); //Absolute Path
    //this.router.navigate([keyword], { relativeTo: this.route }); //Relative Navigation
  }

  //On Status filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    this.loadMenuItems(keyword, status);
  }

  //Load Subcategory, on Category change event
  loadSubCategory(event) {
    this.myapp.hideAlerts();
    const catId = this.filterForm.get("catId").value;
    if (catId != "") {
      const formData = new FormData();
      formData.append("catId", catId);
      formData.append("barId", localStorage.getItem("barId"));
      formData.append("token", localStorage.getItem("token"));

      this.bs.callAPI(formData, "getCategoryData").subscribe(
        (response: any) => {
          if (response.status == 1) {
            this.subCategories = response.data;
          } else {
            var noSubCat = [
              { subCatId: "", subCatName: "No Sub Category found" }
            ];
            this.subCategories = noSubCat;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.subCategories = [];
  } s
}
