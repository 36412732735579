import { Component, OnInit } from "@angular/core";
import { BarService } from "./service/bar.service";
import { CommonService } from "./service/common.service";
import { Router } from "@angular/router";
import { PushNotificationsService } from "ng-push";
import { anyChanged } from "@progress/kendo-angular-common";
import { Location } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  appName = "Stepout Live";
  agreementFile = "https://stepoutlive.com/legal/Service-Agreement.pdf";
  isUserLogin = false;
  isLoadingResults = false;
  showSuccessAlert = false;
  showErrorAlert = false;
  successMsg = "";
  errorMsg = "";
  actionStatus = false;
  itemMainCategories: any = []; //["Drink", "Food"];
  licenseTypes: any = [];
  foodUnitTypes = ["Piece", "Full", "Half", "Quarter", "Plate"];
  drinkUnitTypes = ["30ml", "60ml", "120ml"];
  paymentModes = ["cash", "card", "wallet"];
  allCities: any = [];
  allCountries:any = [];
  pickBarName: any = "";
  loginUserKiId: any = 0;
  loginBarName: any = "";
  loginBarLogo: any = "";
  loginBarCoverImg: any = "";
  loginBarRank: any = 0;
  isPosActivate = false;
  restaurantActivationCount = 0;
  routeName = "";
  tableBookingIds: any = [];
  tableBookingNotifyMsg: any = "";
  broadcastNotifyTitle: any = "";
  broadcastNotifyMsg: any = "";
  userRoles = [
    {
      id: 1,
      role: "Admin"
    },
    {
      id: 4,
      role: "Account Staff"
    },
    {
      id: 5,
      role: "Restaurant Onboarding Staff"
    },
    {
      id: 6,
      role: "Customer care Executive"
    }
  ];
  //hideCitySuggestion = false;

  //notification: any = [];

  constructor(
    private router: Router,
    private bs: BarService,
    private cs: CommonService,
    location: Location,
    private _pushNotifications: PushNotificationsService
  ) {
    this._pushNotifications.requestPermission();

    router.events.subscribe(val => {
      if (location.path() != "") {
        this.routeName = location.path();
      } else {
        this.routeName = "Home";
      }
      //console.log("RouteName:", this.routeName);

      /*if (this.routeName.includes("pos")) {
        this.isPosActivate = true;
      } else this.isPosActivate = false;*/
    });
  }

  ngOnInit() {
    //console.log("Current Route:" + this.router.url);
    //Login Access Validation
    this.isUserLogin = this.cs.isUserLogin();

    //Redirect to Dashboard if user comes to login or base URL after successful login
    /*if (this.isUserLogin) {
      this.router.navigate(["/dashboard"]);
    }*/

    // If user try to reload the page,
    if (this.isUserLogin) {
      if (this.router.url == "/dashboard" || this.router.url == "/") {
        /*if (localStorage.getItem("userType") == "B")
          this.router.navigate(["/dashboard"]);
        else if (localStorage.getItem("userType") == "A")
          this.router.navigate(["/admin-dashboard"]);
        else if (window.localStorage.getItem("roleId") == "4")
          this.router.navigate(["/revenue-list"]);
        else if (window.localStorage.getItem("roleId") == "5")
          this.router.navigate(["/add-bar"]);
        else if (window.localStorage.getItem("roleId") == "6")
          this.router.navigate(["/view-bar"]);*/
        /*else if (localStorage.getItem("userType") == "S")
          this.router.navigate(["/revenue-list"]);*/
      }
    }

    //If Table Bookings Ids is set
    if (window.localStorage.getItem("tableBookingIds")) {
      this.tableBookingIds = localStorage.getItem("tableBookingIds");
    }

    //Call app settings
    this.loadAppSettings();

    //Hide the notifiation after 10 sec
    setInterval(() => {
      //notification-alert is the class
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
      this.successMsg = "";
      this.errorMsg = "";
    }, 10000);

    this.notifyToBar();
    setInterval(() => {
      this.notifyToBar();
    }, 3000);

    // Restrict other login on another tab
    /*if (window.localStorage.getItem("isUserLogin")) {
      setInterval(() => {
        if (window.localStorage.getItem("userType") == "B")
          this.loginUserKiId = window.localStorage.getItem("barId");
        else this.loginUserKiId = window.localStorage.getItem("userId");
        if (this.loginUserKiId == null) window.location.href = "./login";
      }, 1000);
    }*/
  }

  //Function to hide Alerts on before each event calling
  hideAlerts() {
    this.showSuccessAlert = false;
    this.showErrorAlert = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  //Function to load app settings
  loadAppSettings() {
    const formData = new FormData();
    formData.append("dummy", "1");
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "getAppSettings").subscribe(
      (response: any) => {
        if (response.status == 1) {
          console.log("licenseType", response.data.licenseType);
          //console.log(response.data.itemMainCats);
          this.licenseTypes = response.data.licenseType;
          this.itemMainCategories = response.data.itemMainCats;
          this.restaurantActivationCount =
            response.data.restaurantActivationCount;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to active/deactive the records in the database
  changeStatus(table, id, value) {
    this.hideAlerts();
    //this.isLoadingResults = true;
    const statusData = new FormData();
    statusData.append("table", table);
    statusData.append("id", id);
    statusData.append("value", value);
    statusData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(statusData, "changeStatus").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.actionStatus = true;
          /*this.isLoadingResults = false;
          this.showSuccessAlert = true;
          this.successMsg = response.message;*/
        } else {
          //this.isLoadingResults = false;
          this.actionStatus = false;
          this.showErrorAlert = true;
          this.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to active/deactive the records in the database
  deleteRecord(table, id) {
    this.hideAlerts();
    const formData = new FormData();
    formData.append("table", table);
    formData.append("id", id);
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "deleteData").subscribe(
      (response: any) => {
        if (response.status == 1) {
        } else {
          this.showErrorAlert = true;
          this.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // Function to logout user
  logout() {
    const postData = new FormData();
    postData.append("token", localStorage.getItem("token"));
    if (localStorage.getItem("userType") == "B") {
      postData.append("barId", localStorage.getItem("barId"));
    } else {
      postData.append("userId", localStorage.getItem("userId"));
    }


    this.bs.callAPI(postData, "barLogout").subscribe(response => {
      if (response.status === 1) {
        let un = "";
        let pwd = "";
        if (localStorage.getItem("loginUserName")) {
          un = localStorage.getItem("loginUserName");
          pwd = localStorage.getItem("loginPassword");
        }

        //Destory the local storage
        localStorage.clear();

        //Set back the remember login info
        if (un != "" && pwd != "") {
          window.localStorage.setItem("loginUserName", un);
          window.localStorage.setItem("loginPassword", pwd);
        }

        window.location.href = "./login";
      } else {
        this.showErrorAlert = true;
        this.errorMsg = response.message;
        this.isLoadingResults = false;
      }
    });
    return false;
  }

  //notification function
  notifyToBar() {
    const formData = new FormData();
    let barNotifyList: any;
    let notifyOrderList = [];
    let today = this.cs.todayDate();
    if (localStorage.hasOwnProperty("barNotifyList")) {
      barNotifyList = JSON.parse(localStorage.getItem("barNotifyList"));
      if (barNotifyList.notifyDate !== today) {
        notifyOrderList = [];
      }
    }
    if (localStorage.hasOwnProperty("barId")) {
      formData.append("barId", localStorage.getItem("barId"));
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getPendingOrderKots").subscribe(
        (response: any) => {
          if (response.status == 1) {
            let orders = response.data;
            for (let i = 0; i < orders.length; i++) {
              if (
                (orders[i].orderType == "preorder" &&
                  orders[i].isReedem == "1") ||
                orders[i].orderType == "order"
              )
                this.playAudio();
            }

            let gifts = response.giftOrders;
            for (let i = 0; i < gifts.length; i++) {
              this.playAudio();
            }

            // TABLE BOOKING Notification loop
            /*for (let i = 0; i < response.tableBookings.length; i++) {
              let id = response.tableBookings[i].id;
              if (!(this.tableBookingIds.indexOf(id) !== -1)) {
                this.playAudio();
                this.tableBookingIds.push(id);
                this.tableBookingNotifyMsg =
                  "A new table booking request has arrived";

                //Open the table booking Modal
                let btn: HTMLElement = document.getElementById(
                  "show-table-booking-btn"
                ) as HTMLElement;
                btn.click();
              }
              localStorage.setItem("tableBookingIds", this.tableBookingIds);
            }*/
            //console.log("Table booking ids", this.tableBookingIds);

            /*if (window.localStorage.getItem("tableBookingIds")) {
              this.tableBookingIds = localStorage.getItem("tableBookingIds");
            } else {
              localStorage.setItem("tableBookingIds", this.tableBookingIds);
            }*/

            /*if (this.router.url == "/dashboard")
              this.router.navigate(["/dashboard/code"]);*/

            // Notification loop of pending KOT orders
            /*for (let i = 0; i < response.data.length; i++) {
              this.playAudio(); // Call audio file function, will be removed after SSL

              let options = {
                //set options
                body:
                  "New order is received! Order ID is " +
                  response.data[i].orderId,
                icon:
                  this.cs.siteUrl + "assets/img/brand/notification-logo.jpg", //adding an icon
                  //sound: this.cs.siteUrl + "assets/siren.mp3", //This will be uncommented after SSL - Not supported on live
                data: {
                  url: this.cs.siteUrl + "kot-list/" + response.data[i].orderId
                }
              };

              var notiObj = this._pushNotifications
                .create("Stepout Live", options)
                .subscribe(
                  //creates a notification
                  res => console.log(res),
                  err => console.log(err)
                );
              if (notifyOrderList.indexOf(response.data[i].orderId) === -1) {
                notifyOrderList.push(response.data[i].orderId);
              }
            }*/

            /*let newObj = {
              notifyOrderList: JSON.stringify(notifyOrderList),
              notifyDate: today
            };
            localStorage.setItem("barNotifyList", JSON.stringify(newObj));

            // Notification loop of closed orders but not paid
            for (let i = 0; i < response.orders.length; i++) {
              this.playAudio();

              let options = {
                //set options
                body:
                  "Order from " +
                  response.orders[i].firstName +
                  " " +
                  response.orders[i].lastName +
                  " is closed. PAYMENT DUE!",
                icon:
                  this.cs.siteUrl + "assets/img/brand/notification-logo.jpg", //adding an icon
                  //sound: this.cs.siteUrl + "assets/siren.mp3", //This will be uncommented after SSL - Not supported on live
                data: {
                  url: this.cs.siteUrl + "invoice/" + response.orders[i].orderId
                }
              };
              var notiObj = this._pushNotifications
                .create("Stepout Live", options)
                .subscribe(
                  //creates a notification
                  res => console.log(res),
                  err => console.log(err)
                );
            }

            // TABLE BOOKING Notification loop
            for (let i = 0; i < response.tableBookings.length; i++) {
              this.playAudio();

              let options = {
                //set options
                body:
                  "New Table booking request from " +
                  response.tableBookings[i].firstName +
                  " " +
                  response.tableBookings[i].lastName,
                icon:
                  this.cs.siteUrl + "assets/img/brand/notification-logo.jpg", //adding an icon
                //sound: this.cs.siteUrl + "assets/siren.mp3", //This will be uncommented after SSL - Not supported on live
                data: {
                  url: this.cs.siteUrl + "booking-table"
                }
              };
              var notiObj = this._pushNotifications
                .create("Stepout Live", options)
                .subscribe(
                  //creates a notification
                  res => console.log(res),
                  err => console.log(err)
                );
            }*/
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  playAudio() {
    let audio = new Audio();
    audio.src = this.cs.siteUrl + "assets/siren.mp3";
    audio.load();
    audio.play();
    //audio.pause();
  }

  goBack() {
    history.back();
    return false;
  }

  /*hideAllCities() {
    this.hideCitySuggestion = true;
    console.log("Hide Cities");
  }*/
}
