import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"]
})
export class PaymentComponent implements OnInit {
  //Variable declration
  payToBarForm: FormGroup;
  gstRate: any;
  commissionRate: any;
  payAmountEror: boolean;
  settlementStatus: boolean;
  //Injecting SErvices
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // Admin routes validation
    //this.cs.isAdminRoute();
    this.cs.isAdminOrStaffRoute();

    this.payToBarForm = this.fb.group({
      barId: [""],
      barName: [""],
      startDate: [""],
      endDate: [""],
      amount: [""],
      dueAmount: [""],
      totalAmount: [""],
      OnlineAmount: [""],
      payAmount: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      totalCommission: [""],
      paymentMode: ["", [Validators.required]],
      remarks: [""],
      settlementData: [""]
    });
    this.payAmountEror = false;
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadSettlementData(id);
      } else {
      }
    });
  }
  //define loadSettlementData function
  loadSettlementData(id) {
    const payData = new FormData();
    payData.append("barId", id);
    payData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(payData, "getBarSettlementList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //console.log(response.data);
          let totalAmount =
            response.data[0].previousDueAmount +
            response.data[0].totalPaybaleAmount;
          this.payToBarForm.patchValue({
            barId: id,
            barName: response.data[0].barName,
            startDate: response.data[0].startWeek,
            endDate: response.data[0].endWeek,
            amount: response.data[0].totalPaybaleAmount.toFixed(2),
            dueAmount: response.data[0].previousDueAmount.toFixed(2),
            totalAmount: totalAmount.toFixed(2),
            OnlineAmount: response.data[0].OnlineAmount.toFixed(2),
            payAmount: totalAmount.toFixed(2),
            totalCommission: response.data[0].totalCommission,
            settlementData: response.data
          });
          this.gstRate = response.data[0].taxRate;
          this.commissionRate = response.data[0].commissionRate;

          //this.calculateCommission();
          if (response.data[0].settlementStatus) {
            this.settlementStatus = true;
          } else {
            this.settlementStatus = false;
          }
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  calculateCommission() {
    let amount: any = this.payToBarForm.get("payAmount").value;
    //alert('amount:'+amount)
    if (amount > 0) {
      //alert("gst:"+this.gstRate + "commissionRate:"+this.commissionRate);
      let commission = (amount * this.commissionRate) / 100;
      let gst = (commission * this.gstRate) / 100;
      let total = (commission + gst).toFixed(2);
      this.payToBarForm.controls["totalCommission"].patchValue(total);
      this.payAmountEror = false;
    }
  }
  //sbmit payment form defination
  onPaymentSubmit() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          "Please recheck the settlement, you can not change after submit?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        this.myapp.hideAlerts();
        let canSubmit = true;
        this.payAmountEror = false;
        let totalAmount: any = this.payToBarForm.get("totalAmount").value;
        let payAmount: any = this.payToBarForm.get("payAmount").value;
        if (payAmount > totalAmount) {
          canSubmit = false;
          this.payAmountEror = true;
          return true;
        }
        if (canSubmit) {
          this.myapp.isLoadingResults = true;
          const formData = new FormData();
          formData.append("barId", this.payToBarForm.get("barId").value);
          formData.append(
            "startDate",
            this.payToBarForm.get("startDate").value
          );
          formData.append("endDate", this.payToBarForm.get("endDate").value);
          formData.append("amount", this.payToBarForm.get("totalAmount").value);
          formData.append(
            "paidAmount",
            this.payToBarForm.get("payAmount").value
          );
          formData.append("remarks", this.payToBarForm.get("remarks").value);
          formData.append(
            "commission",
            this.payToBarForm.get("totalCommission").value
          );
          formData.append(
            "paymentMode",
            this.payToBarForm.get("paymentMode").value
          );
          formData.append(
            "settlementData",
            JSON.stringify(this.payToBarForm.get("settlementData").value)
          );
          formData.append("token", localStorage.getItem("token"));
          //Form submit by appi call
          this.bs
            .callAPI(formData, "paySettlementAmount")
            .subscribe(response => {
              if (response.status === 1) {
                this.myapp.showSuccessAlert = true;
                this.myapp.successMsg = response.message;
                this.myapp.isLoadingResults = false;
                this.router.navigate(["/payment-history"]);
              } else {
                this.myapp.showErrorAlert = true;
                this.myapp.errorMsg = response.message;
                this.myapp.isLoadingResults = false;
              }
            });
        }
      }
    });
  }
  //Getor - To access the fields directly in the form in HTML
  get barName() {
    return this.payToBarForm.get("barName");
  }
  get amount() {
    return this.payToBarForm.get("amount");
  }
  get dueAmount() {
    return this.payToBarForm.get("dueAmount");
  }
  get totalAmount() {
    return this.payToBarForm.get("totalAmount");
  }
  get payAmount() {
    return this.payToBarForm.get("payAmount");
  }
  get totalCommission() {
    return this.payToBarForm.get("totalCommission");
  }
  get OnlineAmount() {
    return this.payToBarForm.get("OnlineAmount");
  }
  get paymentMode() {
    return this.payToBarForm.get("paymentMode");
  }
  get startDate() {
    return this.payToBarForm.get("startDate");
  }
  get endDate() {
    return this.payToBarForm.get("endDate");
  }
  get remarks() {
    return this.payToBarForm.get("remarks");
  }
}
