import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-bar-transactions",
  templateUrl: "./bar-transactions.component.html",
  styleUrls: ["./bar-transactions.component.css"]
})
export class BarTransactionsComponent implements OnInit {
  //Varbiable Declation
  public timeType: string = "today";
  public reportData: any = [];
  toDisabled = false;
  public cards = {
    totalSales: 0,
    totalAdminCommission: 0,
    totalTipAmount: 0
  };

  transactionData = []; //Used in filter form
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  filterDisable = true;
  customTime = false;

  //Injecting the Services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //load Transaction Data
    /*this.loadTransactionData("", "", "", "", "");
    this.filterForm = this.fb.group({
      keyword: [""],
      paymentMode: [""],
      date: [""],
      date2: [""],
      paymentTime: [""]
    });*/

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      from: [""],
      to: [""],
      keyword: [""]
    });

    this.loadReport(this.timeType, "0");
  }

  //Load Menu items defination
  loadReport(reportType, email) {
    this.filterForm.get("from").setValue("");
    this.filterForm.get("to").setValue("");

    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("time", reportType);
    barData.append("token", localStorage.getItem("token"));

    if (email == "1") barData.append("email", email);

    this.bs.callAPI(barData, "getBarTransaction").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.reportData = response.data.report;
          this.cards = response.data.cards;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to change report time
  changeReportType(time) {
    if (time == "today") {
      this.timeType = time;
      this.loadReport(time, "0");
    } else {
      this.timeType = time;
      this.reportData = [];
      this.cards = {
        totalSales: 0,
        totalAdminCommission: 0,
        totalTipAmount: 0
      };
      this.calculateEndDate();
    }
  }

  //Function to calculate end date
  calculateEndDate() {
    this.toDisabled = true;
    let from = new Date(this.filterForm.get("from").value);
    var year = from.getFullYear();
    var month = from.getMonth();
    var date = from.getDate();
    var hour = from.getHours();
    var newDate;

    if (this.timeType == "yearly")
      newDate = new Date(year + 1, month, date - 1, hour);
    else if (this.timeType == "monthly") {
      newDate = new Date(year, month + 1, date - 1, hour);
    } else if (this.timeType == "weekly") {
      newDate = new Date(year, month, date + 6, hour);
    } else {
      this.toDisabled = false;
    }
    this.filterForm.get("to").setValue(new Date(newDate));
  }

  // Function to filter reports
  filterReport(email) {
    let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
    let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

    const param = new FormData();
    param.append("barId", localStorage.getItem("barId"));
    param.append("from", from);
    param.append("to", to);
    param.append("keyword", this.filterForm.get("keyword").value);
    param.append("time", this.timeType);
    param.append("token", localStorage.getItem("token"));

    if (email == "1") param.append("email", email);

    this.bs.callAPI(param, "getBarTransaction").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.reportData = response.data.report;
          this.cards = response.data.cards;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load Transaction Data defination
  /*loadTransactionData(keyword, paymentTime, from, to, paymentMode) {
    const param = new FormData();
    param.append("barId", localStorage.getItem("barId"));
    param.append("keyword", keyword);
    param.append("paymentTime", paymentTime);
    param.append("from", from);
    param.append("to", to);
    param.append("paymentMode", paymentMode);

    this.bs.callAPI(param, "getAllTransaction").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.transactionData = response.data;
        } else {
          this.transactionData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const param = new FormData();
    param.append("barId", localStorage.getItem("barId"));
    param.append("keyword", this.filterForm.get("keyword").value);
    param.append("page", pageNo);
    param.append("paymentTime", this.filterForm.get("paymentTime").value);
    param.append("paymentMode", this.filterForm.get("paymentMode").value);

    this.bs.callAPI(param, "getAllTransaction").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.transactionData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  enableDisableFilter() {
    let keyword = this.filterForm.get("keyword").value;
    let date = this.filterForm.get("date").value;
    let paymentMode = this.filterForm.get("paymentMode").value;

    let paymentTime = this.filterForm.get("paymentTime").value;
    if (paymentTime == "custom") this.customTime = true;
    else this.customTime = false;

    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }

    let date2 = this.filterForm.get("date2").value;
    if (date2 == undefined || date2 == null || date2 == "") {
      date2 = "";
    } else {
      if (typeof date2 === "object") {
        date2 = this.cs.stringToDateFun(date2);
      }
    }

    if (paymentMode || keyword || paymentTime) this.filterDisable = false;
    else this.filterDisable = true;
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let paymentMode = this.filterForm.get("paymentMode").value;
    let paymentTime = this.filterForm.get("paymentTime").value;

    let date = "";
    let date2 = "";
    if (paymentTime == "custom") {
      date = this.filterForm.get("date").value;
      if (date == undefined || date == null || date == "") {
        date = "";
      } else {
        if (typeof date === "object") {
          date = this.cs.stringToDateFun(date);
        }
      }

      date2 = this.filterForm.get("date2").value;
      if (date2 == undefined || date2 == null || date2 == "") {
        date2 = "";
      } else {
        if (typeof date2 === "object") {
          date2 = this.cs.stringToDateFun(date2);
        }
      }
    }
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //On Payment Mode filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let paymentMode = this.filterForm.get("paymentMode").value;
    let paymentTime = this.filterForm.get("paymentTime").value;

    let date = "";
    let date2 = "";
    if (paymentTime == "custom") {
      date = this.filterForm.get("date").value;
      if (date == undefined || date == null || date == "") {
        date = "";
      } else {
        if (typeof date === "object") {
          date = this.cs.stringToDateFun(date);
        }
      }

      date2 = this.filterForm.get("date2").value;
      if (date2 == undefined || date2 == null || date2 == "") {
        date2 = "";
      } else {
        if (typeof date2 === "object") {
          date2 = this.cs.stringToDateFun(date2);
        }
      }
    }

    //alert('keyword:'+keyword +'date:' +date+ 'status:' +status);
    this.loadTransactionData(keyword, paymentTime, date, date2, paymentMode);
  }*/
}
