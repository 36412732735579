import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AppComponent } from "../../app.component";
import { AvailCodeDialogComponent } from "../../avail-code-dialog/avail-code-dialog.component";
import { BarsDialogComponent } from "../../bars-dialog/bars-dialog.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  //Variable Declration
  availForm: FormGroup;
  adminLogin = false;
  popupNotification = [];
  notifications = [];
  barOpt = false;
  lastCallTime: any = "";
  showNotDot = false;
  //pickBarName:any="";
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //If bar is logged in then, show the option to bar only
    if (window.localStorage.getItem("userType") == "B") this.barOpt = true;

    //Form Model Declration with form validation for the fields
    this.availForm = this.fb.group({
      barId: [localStorage.getItem("barId")],
      code: [""]
    });
    this.myapp.pickBarName = localStorage.getItem("activeBarName");
    if (this.myapp.pickBarName == "" || this.myapp.pickBarName == undefined) {
      this.myapp.pickBarName = "Pick a Bar";
    }
    //If admin is logged in
    if (
      window.localStorage.getItem("userType") == "A" ||
      window.localStorage.getItem("userType") == "S"
    ) {
      this.adminLogin = true;
    }

    // Only for restaurants
    if (!this.adminLogin) {
      this.getBarNotification();
      setInterval(() => {
        this.getBarNotification();
      }, 3000);
    }
  }

  //Function to open the dialog - Testing
  openCodeDialog() {
    /*let dialogRef = this.dialog.open(AvailCodeDialogComponent);
    //On Cancel Button
    dialogRef.afterClosed().subscribe(result => {
      console.log(`dialog result:  ${result}`);
    });*/
  }

  //Function to Find the code in DB in avail
  checkCodeExist(key, keyCode) {
    //console.log("keyCode: " + keyCode);
    if (key.length == 4 && keyCode != 13) {
      //13 means ENTER key

      this.router.navigate(["order-history", key]);

      /*this.myapp.hideAlerts();
      const formData = new FormData();
      formData.append("barId", localStorage.getItem("barId"));
      formData.append("code", this.code.value);

      this.bs.callAPI(formData, "checkCodeExist").subscribe(
        (headerRes: any) => {
          if (headerRes.status == 1) {
            // console.log(headerRes.data);
            this.availForm.patchValue({
              code: ""
            });

            //THis method accept two param (1: component, 2: Optional configuration)
            let dialogRef = this.dialog.open(AvailCodeDialogComponent, {
              data: headerRes.data
            });
            //On Cancel Button
            dialogRef.afterClosed().subscribe(result => {
              console.log(`dialog result:  ${result}`);
            });

            //console.log(headerRes.data);
            //Patch the Item data in menuForm
            //this.visitForm.patchValue({
              //tableNo: headerRes.data.id
            //});
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = headerRes.message;
          }
        },
        error => {
          console.log(error);
        }
      );*/
    }
  }

  //Function to open the dialog to select a Bar
  /*openBarDialog() {
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("status", "1");

    this.bs.callAPI(formData, "barList").subscribe(
      (headerRes: any) => {
        if (headerRes.status == 1) {
          // console.log(headerRes.data);

          //THis method accept two param (1: component, 2: Optional configuration)
          let dialogRef = this.dialog.open(BarsDialogComponent, {
            data: headerRes.data
          });
          //On Cancel Button
          dialogRef.afterClosed().subscribe(result => {
            console.log(`dialog result:  ${result}`);
          });
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = headerRes.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }*/

  //Getor - To access the fields directly in the form in HTML
  get code() {
    return this.availForm.get("code");
  }
  //This function is used for get bar notification list.
  getBarNotification() {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));

    if (localStorage.getItem("userType") == "B") {
      formData.append("barId", localStorage.getItem("barId"));
      formData.append("limit", "5");
      formData.append("lastCallTime", this.lastCallTime);
    } else {
      // In case of Admin-Staff Login
      formData.append("userId", localStorage.getItem("userId"));
      
    }
    this.bs.callAPI(formData, "getBarNotification").subscribe(
      (headerRes: any) => {
        /*if (headerRes.status == 100) {
          console.log("Forcefully Logout");
          this.myapp.logout();
        }*/
        if (headerRes.status == 1) {
          if (this.router.url == "/booking-table")
            this.router.navigate(["/dashboard"]);

          this.popupNotification = headerRes.data;
          this.notifications = headerRes.notifications;
          this.lastCallTime = headerRes.lastCallTime;
          this.showNotDot = true;

          // TABLE BOOKING Notification loop
          for (let i = 0; i < this.popupNotification.length; i++) {
            let msg = this.popupNotification[i].description;
            this.myapp.tableBookingNotifyMsg = msg;

            //Open the table booking Modal
            let btn: HTMLElement = document.getElementById(
              "show-table-booking-btn"
            ) as HTMLElement;
            btn.click();

            if (msg == "A new table booking request has arrived") {
              this.myapp.playAudio();
            }
          }

          // BROADCAST Notification loop
          for (let i = 0; i < this.notifications.length; i++) {
            this.myapp.broadcastNotifyMsg = this.notifications[i].description;
            this.myapp.broadcastNotifyTitle = this.notifications[i].title;

            //Open the Modal
            let btn: HTMLElement = document.getElementById(
              "show-broadcast-msg-btn"
            ) as HTMLElement;
            btn.click();
          }
        } else {
          //this.showNotDot = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  openNotificationBox() {
    this.showNotDot = false;
    return false;
  }

  // Function to redirect, on click on notification
  goToPage(orderId, orderType) {
    if (orderType == "order") {
      this.router.navigate(["kot-list", orderId]);
    } else if (orderType == "preorder") {
      this.router.navigate(["invoice", orderId]);
    } else if (orderType == "visit") {
      this.router.navigate(["code-generate"]);
    } else if (orderType == "bookTable") {
      this.router.navigate(["booking-table"]);
    } else if (orderType == "orderPayment") {
      this.router.navigate(["invoice", orderId]);
    } else {
      this.router.navigate(["bar-profile"]);
    }
  }
}
