import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,Renderer2
} from "@angular/core";

import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-mester-restaurant',
  templateUrl: './mester-restaurant.component.html',
  styleUrls: ['./mester-restaurant.component.css']
})
export class MesterRestaurantComponent implements OnInit {

  barForm: FormGroup;
  cancelForm: FormGroup;
  hideLoadMore = true;
  page = 1; //used for load more
  filterForm: FormGroup;

  cities: any[];
  allCities = [];
  dropdownSettings = {};
  selectedItems = [];
  selectedIds: number[] = [];
  cityNew: number = 0;
  resturantId = null;
  restaurantPassword = null;
  restaurantDetailsActive =false;
  // page = 1;
  barData = [];
  newLoad = false;

  // @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  @ViewChild('cancelModal', { static: false }) cancelModal: ElementRef;

  showModal: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _activatedRoute: ActivatedRoute,
    private renderer: Renderer2,

    
  ) {
    // router.routerState.queryParams.subscribe(data => console.log('queryParams', data['st']));
    //   this._activatedRoute.params.subscribe(params => {
    //     console.log('params', params);
    //   });
  }
  ngAfterViewInit() {
    // Check if the modal element is referenced correctly after view initialization
    if (!this.cancelModal) {
      console.error("Modal element not found after view initialization.");
    }
  }

  ngOnInit() {
    // Admin routes validation
    if (window.localStorage.getItem("roleId") != "5") this.cs.isAdminRoute();
    
    //Profile Form Model Declration with form validation for the fields
    this.barForm = this.fb.group({
      // id: [""],
      barName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
          //Validators.pattern("^[a-zA-Z () &]+$")
        ]
      ],
      localArea: ["", [Validators.required]],
      cityId: [""]
    });
    this.loadRestaurantMasterData("");
    this.loadCities();
    this.filterForm = this.fb.group({
      keyword: [""],
    });
    this.dropdownSettings= {
      singleSelection: true,
      idField: 'city_id',
      textField: 'city_name',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  loadRestaurantMasterData(keyword){
    // this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    // userData.append("page", pageNo);
    userData.append("keyword", keyword);
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "restaurantMasterList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          this.barData = response.data;
         
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );

  }
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
   
    const userData = new FormData();
    // userData.append("page", pageNo);
    // userData.append("keyword", keyword);
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("page", pageNo);
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("token", localStorage.getItem("token"));


    this.bs.callAPI(userData, "restaurantMasterList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.barData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load cities, on States change event
  loadCities() {
    this.myapp.hideAlerts();
    
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getCities").subscribe(
        //this.myapp.isLoadingResults = false;
        (response: any) => {
          // console.log(response);
          if (response.status == 1) {           
            this.allCities = response.data;
            this.dropdownSettings = {
              singleSelection: true,
              idField: 'item_id',
              textField: 'item_text',
              
              itemsShowLimit: 1,
              allowSearchFilter: true
            };

          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
  }
  onItemSelect(event: any) {
    
    console.log(event.item_id);
    this.cityNew =event.item_id;
    console.log('lkkkk');
    console.log(this.cityNew);  
  }
  
  logSelectedItems(id: number) {
    this.selectedIds.push(id);
    console.log(this.selectedIds.join(','));
  }

  //Function to submit data
  onBarSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;
    if (canSubmit) {
      // this.myapp.isLoadingResults = true;
      const formData = new FormData();
      //formData.append("id", this.barForm.get("id").value);
      formData.append("barName", this.barForm.get("barName").value);
      formData.append("localArea", this.barForm.get("localArea").value);
      formData.append("cityId", this.cityNew.toString());
      formData.append("type", '0');
      formData.append("token", localStorage.getItem("token"));

      //Form submit by appi call   old url :-resturantMasterData
      this.bs.callAPI(formData, "cloneFinalRestaurant").subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          // Store restaurant ID and password from response
            this.resturantId = response.data.restaurantId;
            this.restaurantPassword = response.data.password;
            
            if (this.cancelModal) {
              this.showModal = true;
              this.renderer.addClass(this.cancelModal.nativeElement, 'show');
              this.renderer.setStyle(this.cancelModal.nativeElement, 'display', 'block');
              this.renderer.setStyle(this.cancelModal.nativeElement, 'background-color', 'rgba(0,0,0,0.5)');
            } else {
              console.error("Modal element not found.");
            }
            // this.dialog.open(SuccessModalComponent);
          // this.loadRestaurantMasterData("");
          // this.router.navigate(["/restaurant-data"]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    }
  }
  closeModal() {
    this.renderer.removeClass(this.cancelModal.nativeElement, 'show');
    this.renderer.setStyle(this.cancelModal.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.cancelModal.nativeElement, 'background-color', 'rgba(0,0,0,0)');
  }
  copyToClipboard() {
    const textToCopy = `Restaurant ID: ${this.resturantId}\nPassword: ${this.restaurantPassword}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = "Restaurant Details copied to successfully";
          this.barForm = this.fb.group({
            barName: [''],
            localArea: [''],
            cityControl: ['']
          });
          if (this.newLoad ) {
            this.loadRestaurantMasterData("");
          this.router.navigate(["/restaurant-data"]);
          }
          this.router.navigate(["/restaurant-data"]);
          
    }).catch(err => {
      // console.error('Failed to copy text: ', err);
    });
  }
  
  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    // console.log(keyword);
    
    this.loadRestaurantMasterData(keyword);
  }
  // Function to Active/Inactive records
  changeStatus(id, status) {
    const formData = new FormData();   
    formData.append("id", id);
    formData.append("status", status);
    formData.append("type", '1');
    formData.append("token", localStorage.getItem("token"));
    this.bs
      .callAPI(formData, "cloneFinalRestaurant")
      .subscribe(response => {
        console.log(response);
        
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          // this.ngOnInit();
          this.resturantId = response.data.restaurantId;
          this.restaurantPassword = response.data.password;
          
          if (this.cancelModal) {
            this.newLoad = true;
            this.showModal = true;
            this.renderer.addClass(this.cancelModal.nativeElement, 'show');
            this.renderer.setStyle(this.cancelModal.nativeElement, 'display', 'block');
            this.renderer.setStyle(this.cancelModal.nativeElement, 'background-color', 'rgba(0,0,0,0.5)');
          } else {
            console.error("Modal element not found.");
          }
          // this.loadRestaurantMasterData("");
          // this.router.navigate(["/restaurant-data"]);

          
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }
 

  //Getor - To access the fields directly in the form in HTML
  get barName() {
    return this.barForm.get("barName");
  }
  get email() {
    return this.barForm.get("email");
  }
  get phone() {
    return this.barForm.get("phone");
  }

}
