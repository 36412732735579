import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../service/login.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  //Varialbe Declration
  loginForm: FormGroup;
  forgotForm: FormGroup;
  invalidLogin: boolean = false;
  validForgot: boolean = false;
  invalidForgot: boolean = false;
  showForgotForm: boolean = false;
  showLoginForm: boolean = true;
  isLoadingResults = false;
  forgotMessage = "";
  loginMessage = "";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ls: LoginService
    
  ) {}

  ngOnInit() {
    //Unset the Local Storage Varialbes
    //window.localStorage.removeItem("barId");
    //window.localStorage.removeItem("userType");
    //localStorage.clear();

    //Redirect to Dashboard if user comes to login after successful login
    if (window.localStorage.getItem("isUserLogin")) {
      this.router.navigate(["/dashboard"]);
    }

    //form validation for the fields
    this.loginForm = this.fb.group({
      userName: [
        localStorage.getItem("loginUserName"),
        [Validators.required, Validators.minLength(3)]
      ],
      password: [localStorage.getItem("loginPassword"), [Validators.required]],
      remember: [""]
    });

    this.forgotForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }

  //Show-Hide forgot/login form
  loadForgotForm() {
    this.showForgotForm = true;
    this.showLoginForm = false;

    this.invalidForgot = false;
    this.validForgot = false;

    //Reset the form
    this.loginForm.reset();
    return false;
  }
 
  loadLoginForm() {
    this.showForgotForm = false;
    this.showLoginForm = true;
    //Reset the form
    this.forgotForm.reset();
    return false;
  }

  //Login Form Submit funtion
  onSubmit() {
    //Validation checking
    if (this.loginForm.invalid) {
      return;
    }

    // If Any User is already logged in, then redirect
    if (window.localStorage.getItem("userType") == "A")
      window.location.href = "./admin-dashboard";
    else if (window.localStorage.getItem("roleId") == "4")
      window.location.href = "./revenue-list";
    else if (window.localStorage.getItem("roleId") == "5")
      window.location.href = "./add-bar";
    else if (window.localStorage.getItem("roleId") == "6")
      window.location.href = "./view-bar";
    else if (window.localStorage.getItem("userType") == "B")
      window.location.href = "./dashboard";

    // Login code start
    //const loginPayLoad = this.loginForm.value;
    //console.log("login form", this.loginForm.value);
    const loginPayLoad = new FormData();
    loginPayLoad.append("userName", this.userName.value);
    loginPayLoad.append("password", this.password.value);

    this.ls.login(loginPayLoad).subscribe(response => {
      //debugger;
      if (response.status === 1) {
        //console.log(response);
        //console.log(response.data.id);
        if (this.remember.value) {
          window.localStorage.setItem("loginUserName", this.userName.value);
          window.localStorage.setItem("loginPassword", this.password.value);
        }

        window.localStorage.setItem("userType", response.data.userType);
        window.localStorage.setItem("roleId", response.data.roleId);
        window.localStorage.setItem("activeCountryId", response.data.countryId);
        window.localStorage.setItem("activeCity", "");
        window.localStorage.setItem("isUserLogin", "1");
        window.localStorage.setItem("loginUserKiId", response.data.id);
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("stafType", response.data.stafType);

        if (response.data.userType == "B") {
          //Bar Login
          window.localStorage.setItem("barId", response.data.id);
          window.localStorage.setItem("currency", response.currency);
          window.location.href = "./dashboard";
        } else {
          //Admin Login
          window.localStorage.setItem("currency", response.currency);
          window.localStorage.setItem("userId", response.data.userId);
          if (response.data.roleId == 4) {
            window.location.href = "./revenue-list";
          } else if (response.data.roleId == 5) {
            window.location.href = "./add-bar";
          } else if (response.data.roleId == 6) {
            window.location.href = "./view-bar";
          } else {
            window.location.href = "./admin-dashboard";
          }
        }
        //this.router.navigate(["/dashboard"]);
      } else {
        this.invalidLogin = true;
        this.loginMessage = response.message;
        console.log(response.message);
      }
    });
  }

  //Forgot Form Submit funtion
  onForgotSubmit() {
    //Validation checking
    if (this.forgotForm.invalid) {
      return;
    }
    //console.log(this.forgotForm.value);

    this.isLoadingResults = true;
    //const forgotPayLoad = this.forgotForm.value;
    const forgotPayLoad = new FormData();
    forgotPayLoad.append("email", this.email.value);

    this.ls.forgotPassword(forgotPayLoad).subscribe(response => {
      if (response.status === 1) {
        this.isLoadingResults = false;
        this.validForgot = true;
        this.invalidForgot = false;
        //console.log(response.message);
        this.forgotMessage = response.message;
        this.forgotForm.reset();
      } else {
        this.isLoadingResults = false;
        this.invalidForgot = true;
        this.validForgot = false;
        this.forgotMessage = response.message;
        //console.log(response.message);
      }
    });
  }

  //Getor - To access the userName directly in the form in HTML
  get userName() {
    return this.loginForm.get("userName");
  }
  get password() {
    return this.loginForm.get("password");
  }
  get remember() {
    return this.loginForm.get("remember");
  }
  get email() {
    return this.forgotForm.get("email");
  }
}
