import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { title } from "process";

@Component({
  selector: 'app-all-special-event-restaurant-list',
  templateUrl: './all-special-event-restaurant-list.component.html',
  styleUrls: ['./all-special-event-restaurant-list.component.css']
})
export class AllSpecialEventRestaurantListComponent implements OnInit {

  //Variable Declration
  // filterForm: FormGroup;
  cancelForm: FormGroup;
  broadForm: FormGroup;
  // barData = [];
  visitData = [];
  page = 1; //used for load more
  hideLoadMore = true;
  filterForm: FormGroup;
  showBackButton = false;
  cancelReason: any = null;
  cancelByName: any = null;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;
  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;


  //Injecting the Services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the function
   
// console.log(title);

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      barId: [localStorage.getItem("barId")],
      keyword: [""],
      status: [""],
      city: [localStorage.getItem("activeCity")],
      country: [localStorage.getItem("activeCountryId")]
      
    });
    this.cities = this.myapp.allCities;
    this.countries = this.myapp.allCountries;
    this.loadVisits("", "");
    

    let pickBarName = localStorage.getItem("activeBarName");
    if (pickBarName == "" || pickBarName == undefined) {
    } else {
      this.showBackButton = true;
    }
  }

  //Load Visit function definiation
  loadVisits(keyword, status) {
    const barData = new FormData();
    barData.append("status", status);

    this.bs.callAPI(barData, "ActiveRestaurantsLists").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.visitData = response.data;
          //console.log("All Cities:" + this.myapp.allCities);
        } else {
          this.visitData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }


}
