import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-payment-history",
  templateUrl: "./payment-history.component.html",
  styleUrls: ["./payment-history.component.css"]
})
export class PaymentHistoryComponent implements OnInit {
  //Varbiable Declation
  paymentData: any = [];
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  barOpt = true;
  settlementData: any = [];
  placeHolder = "Search by bar name, amount";
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;

  //Injecting the Services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //If bar is logged in, then false the barOpt
    if (window.localStorage.getItem("userType") == "B") {
      this.barOpt = false;
      this.placeHolder = "Search by amount or remarks...";
    }

    //Call the Bar Categories
    this.loadPaymentHistory("", "");

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      barId: [localStorage.getItem("barId")],
      keyword: [""],
      city: [""]
    });

    // Load all cities
    this.cities = this.myapp.allCities;
  }

  //loadPaymentHistory defination
  loadPaymentHistory(keyword, city) {
    const barData = new FormData();

    let barId: any = "";
    //if bar is accessging this route then call only his records
    if (localStorage.getItem("barId")) barId = localStorage.getItem("barId");

    barData.append("barId", barId);
    barData.append("keyword", keyword);
    barData.append("city", city);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getPaymentHistory").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.paymentData = response.data;
          //console.log(this.paymentData[0].settlementData);
        } else this.paymentData = [];
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    let barId: any = "";
    //if bar is accessging this route then call only his records
    if (localStorage.getItem("barId")) barId = localStorage.getItem("barId");

    barData.append("barId", barId);
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getPaymentHistory").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.paymentData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let city = this.filterForm.get("city").value;
    this.loadPaymentHistory(keyword, city);
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange();
  }

  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  // Function to show payment info in modal
  loadPaymentData(index) {
    this.settlementData = JSON.parse(this.paymentData[index].settlementData);
    //console.log(this.settlementData);
  }
}
