import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-code-generate",
  templateUrl: "./code-generate.component.html",
  styleUrls: ["./code-generate.component.css"]
})
export class CodeGenerateComponent implements OnInit {
  public timeType: string = "today";
  toDisabled = false;
  //Variable Declration
  visitData = [];
  page = 1; //used for load more
  hideLoadMore = true;
  filterForm: FormGroup;
  cancelReason: any = null;
  cancelByName: any = null;

  //Injecting the Services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      from: [""],
      to: [""],
      barId: [localStorage.getItem("barId")],
      keyword: [""],
      status: [""]
    });
    //Call the function
    this.loadVisits("", "",this.timeType);

    
  }

  //Load Visit function definiation
  loadVisits(keyword, status,timeType) {
   
    
    if (this.timeType == "today") {
      this.filterForm.get("from").setValue("");
    this.filterForm.get("to").setValue("");

    } else {

    }
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("status", status);
    barData.append("token", localStorage.getItem("token"));
    barData.append("time", timeType);
    if (this.timeType == "today") {
     
    } else {
      let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
      let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

      barData.append("from", from);
      barData.append("to", to);
    }

    this.bs.callAPI(barData, "getVisits").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.visitData = response.data;
          //console.log(this.visitData);
        } else {
          this.visitData = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
   //Function to change report time
   changeReportType(time) {
    if (time == "today") {
      this.timeType = time;
      this.loadVisits("","",time);
    } else {
      this.timeType = time;
      this.visitData = [];
      
      this.calculateEndDate();
    }
  }

  //Function to calculate end date
  calculateEndDate() {
    this.toDisabled = true;
    let from = new Date(this.filterForm.get("from").value);
    var year = from.getFullYear();
    var month = from.getMonth();
    var date = from.getDate();
    var hour = from.getHours();
    var newDate;

    if (this.timeType == "yearly")
      newDate = new Date(year + 1, month, date - 1, hour);
    else if (this.timeType == "monthly") {
      newDate = new Date(year, month + 1, date - 1, hour);
    } else if (this.timeType == "weekly") {
      newDate = new Date(year, month, date + 6, hour);
    } else {
      this.toDisabled = false;
    }
    this.filterForm.get("to").setValue(new Date(newDate));
  }

  // Function to filter reports
  filterReport(email ,status, keyword) {
   
    let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
    let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("status", status);
    barData.append("token", localStorage.getItem("token"));
    barData.append("from", from);
    barData.append("to", to);

    this.bs.callAPI(barData, "getVisits").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.visitData = response.data;
          //console.log(this.visitData);
        } else {
          this.visitData = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getVisits").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.visitData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange(keyword) {
    this.hideLoadMore = true;
    this.page = 1;
    let status = this.filterForm.get("status").value;
    this.loadVisits(keyword, status,this.timeType);
    //this.router.navigate(["/view-menu", keyword]); //Absolute Path
    //this.router.navigate([keyword], { relativeTo: this.route }); //Relative Navigation
  }

  //On Applied/Status filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    this.loadVisits(keyword, status,this.timeType);
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to show the reason in modal
  loadVisitReason(cancelBy, reason) {
    this.cancelByName = cancelBy;
    this.cancelReason = reason;
  }
}
