import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-view-staff",
  templateUrl: "./view-staff.component.html",
  styleUrls: ["./view-staff.component.css"]
})
export class ViewStaffComponent implements OnInit {
  staffData = [];
  hideLoadMore = true;
  page = 1; //used for load more
  filterForm: FormGroup;
  //will continue from here
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    this.loadStaffData("", "", "");

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      status: [""],
      roleId: [""]
    });
  }

  //Load staff data defination
  loadStaffData(keyword, status, roleId) {
    const userData = new FormData();
    userData.append("keyword", keyword);
    userData.append("status", status);
    userData.append("roleId", roleId);
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "staffList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.staffData = response.data;
        } else {
          this.staffData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("page", pageNo);
    userData.append("status", this.filterForm.get("status").value);
    userData.append("roleId", this.filterForm.get("roleId").value);
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "staffList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.staffData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let roleId = this.filterForm.get("roleId").value;
    this.loadStaffData(keyword, status, roleId);
  }
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let roleId = this.filterForm.get("roleId").value;
    this.loadStaffData(keyword, status, roleId);
  }

  //Function to Active/Inactive records
  changeStatus(id, value, index) {
    this.myapp.changeStatus("bnUsers", id, value);

    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.staffData[index].status = true;
    else if (value == 2) this.staffData[index].status = 2; // false;
  }
  deleteStaff(id, index) {
    console.log("Deleted Staff id:" + id);
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const param = new FormData();
        param.append("id", id);
        param.append("token", localStorage.getItem("token"));
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(param, "deleteStaff").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            //Delete the item from array
            this.staffData.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }
}
