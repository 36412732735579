import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.css"]
})
export class AdminDashboardComponent implements OnInit {
  //Variable Declration
  public dashboardData: any = [];
  public salesData = [];
  public topThreeBar: any = [];
  public topThreePatrons: any = [];
  public activeCity = "";
  public activeCountryId = "";
  public cities: any = [];
  public countries:any =[];
  sortedCities: any = [];
  sortedCountries: any = [];
  sortedCitiesBar = false;
  shortedCountriesBar =false;
  sortedCitiesUser = false;
  sortedCountriesUser = false;
  sortedCitiesGlobal = false;
  sortedCountriesGlobal = false;

  public topBarsSubHeading = "Of Current Month";
  public topPatronsSubHeading = "Of Current Month";
  public timeType: string = "Weekly";
  userPicPath = this.cs.userProfilePic();
  filterTopBarForm: FormGroup;
  filterTopSpenderForm: FormGroup;

  //Injecting the Services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the function, with Weekly time to load graph data.
    this.getDashBoardData("Weekly");
    //Define the Filter Form Model
    this.filterTopBarForm = this.fb.group({
      keyword: [""]
    });
    this.filterTopSpenderForm = this.fb.group({
      keyword: [""]
    });

    //Cities Array
    //this.cities = this.myapp.cities;
    //console.log("All cities:" + this.cities);
    /*this.cities = [
      "Afghanistan",
      "Zimbabwe"
    ];*/

    if (localStorage.getItem("activeCity"))
      this.activeCity = localStorage.getItem("activeCity");

      if (localStorage.getItem("activeCountryId"))
      this.activeCountryId = localStorage.getItem("activeCountryId");



  }

  // loadDashBoardData function definiation
  getDashBoardData(reportType) {
    const postData = new FormData();
    postData.append("time", reportType);
    postData.append("countryId", localStorage.getItem("activeCountryId"));
    //this.timeType = reportType;
    postData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(postData, "getAdminDashboardData").subscribe(
      (response: any) => {
        if (response.status == 1) {
          // console.log(response.data);
          /*let allCities = response.data.cities;
          allCities.forEach(function(cities) {
            this.cities.push(cities.cityName);
          });*/
          this.cities = this.myapp.allCities = response.data.cities;
          //console.log("All Cities:" + this.cities);
          this.countries = this.myapp.allCountries = response.data.countries;
          
          this.dashboardData = response.data;
          this.topThreeBar = this.dashboardData.topThreeBar;
          this.topThreePatrons = this.dashboardData.topThreeSpender;
          let graphData = response.data.graphData;
          //console.log(graphData);

          // Createing array of Footfall Data
          let keys = [];
          let values = [];

          for (let i = 0; i < graphData.footFall.length; i++) {
            keys.push(graphData.footFall[i].key);
            values.push(graphData.footFall[i].value);
          }

          // Createing array of Payments Data
          let values2 = [];
          for (let i = 0; i < graphData.payments.length; i++) {
            values2.push(graphData.payments[i].value);
          }

          //Update the Lables and Data in the Graph variables directly
          this.barChartLabels = keys;
          this.barChartData = [
            //{ data: values, label: "Footfall" },
            { data: values2, label: "Payments" }
          ];
          this.barChartData2 = [{ data: values, label: "Footfall" }];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // Chart.js
  public barChartOptions: ChartOptions = {
    responsive: true
  };

  public barChartLabels: Label[]; // = this.labels;

  public chartColors: Array<any> = [
    {
      backgroundColor: "rgba(42, 82, 180,0.6)",
      borderColor: "rgba(42, 82, 180,0.6)",
      fillColor: "rgba(225,10,24,0.2)",
      strokeColor: "rgba(11,255,20,1)",
      pointColor: "rgba(111,200,200,1)",
      pointStrokeColor: "#fff",
      pointHighlightFill: "#fff",
      pointHighlightStroke: "rgba(200,100,10,0.8)"
    }
  ];

  public chartColors2: Array<any> = [
    {
      backgroundColor: "rgba(215, 2, 48,0.2)",
      borderColor: "rgba(215, 2, 48,0.2)",
      fillColor: "rgba(225,10,24,0.2)",
      strokeColor: "rgba(11,255,20,1)",
      pointColor: "rgba(111,200,200,1)",
      pointStrokeColor: "#fff",
      pointHighlightFill: "#fff",
      pointHighlightStroke: "rgba(200,100,10,0.8)"
    }
  ];

  public barChartType: ChartType = "bar"; // line & bar
  public barChartLegend = true; //true show the label on top of the graph
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    //this.chartData
    //{ data: this.salesData, label: this.timeType }
    //{ data: [208, 480, 405, 1009, 860, 207, 908], label: "Monthly" }
  ];

  public barChartData2: ChartDataSets[] = [];

  //search  by topbarlist by city name
  onTopBarKeyWordChange() {
    let keyword = this.filterTopBarForm.get("keyword").value;
    if (keyword != "") {
      //console.log("search by city: " + keyword);
      const barData = new FormData();
      barData.append("keyword", keyword);
      barData.append("token", localStorage.getItem("token"));

      this.bs.callAPI(barData, "topBarListByCityName").subscribe(
        (response: any) => {
          if (response.status == 1) {
            this.topBarsSubHeading = "Overall";
            this.dashboardData.topThreeBar = response.data.topThreeBar;
          } else {
            this.dashboardData.topThreeBar = []; //this.topThreeBar;
            /*this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;*/
            this.topBarsSubHeading = "Of Current Month";
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.dashboardData.topThreeBar = this.topThreeBar;
  }

  onTopSpenderKeyWordChange() {
    let keyword = this.filterTopSpenderForm.get("keyword").value;
    if (keyword != "") {
      //console.log("search by city: " + keyword);
      const barData = new FormData();
      barData.append("keyword", keyword);
      barData.append("token", localStorage.getItem("token"));
      
      this.bs.callAPI(barData, "topSpenderListByCityName").subscribe(
        (response: any) => {
          if (response.status == 1) {
            this.topPatronsSubHeading = "Overall";
            this.dashboardData.topThreeSpender = response.data.topThreeSpender;
          } else {
            this.dashboardData.topThreeSpender = [];
            /*this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;*/
            this.topPatronsSubHeading = "Of Current Month";
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.dashboardData.topThreeSpender = this.topThreePatrons;
  }
  onTopSpenderKeyWordChangetest() {
    let keyword = this.filterTopSpenderForm.get("keyword").value;
    if (keyword != "") {
      //console.log("search by city: " + keyword);
      const barData = new FormData();
      barData.append("keyword", keyword);
      barData.append("token", localStorage.getItem("token"));
      
      this.bs.callAPI(barData, "topSpenderListByCountryName").subscribe(
        (response: any) => {
          if (response.status == 1) {
            this.topPatronsSubHeading = "Overall";
            this.dashboardData.topThreeSpender = response.data.topThreeSpender;
          } else {
            this.dashboardData.topThreeSpender = [];
            /*this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;*/
            this.topPatronsSubHeading = "Of Current Month";
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.dashboardData.topThreeSpender = this.topThreePatrons;
  }

  // Function to filer Cities - for Bar
  filterCityBar(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onTopBarKeyWordChange();
      this.sortedCitiesBar = false;
    } else {
      let keyword = this.filterTopBarForm.get("keyword").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.sortedCitiesBar = true;
        } else {
          this.sortedCities = [];
          this.sortedCitiesBar = false;
        }
      } else {
        this.sortedCities = [];
        this.sortedCitiesBar = false;
        console.log("no keyword");
        this.dashboardData.topThreeBar = this.topThreeBar;
      }
    }
  }

  // Function to pick city from suggestions
  fillTextboxBar(str) {
    this.filterTopBarForm.patchValue({
      keyword: str
    });
    this.sortedCities = [];
    this.sortedCitiesBar = false;
    this.onTopBarKeyWordChange();
  }

  

  // Function to filer Cities - for USer
  filterCityUser(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onTopSpenderKeyWordChange();
      this.sortedCitiesUser = false;
    } else {
      let keyword = this.filterTopSpenderForm.get("keyword").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.sortedCitiesUser = true;
        } else {
          this.sortedCities = [];
          this.sortedCitiesUser = false;
        }
      } else {
        this.sortedCities = [];
        this.sortedCitiesUser = false;
        this.dashboardData.topThreeSpender = this.topThreePatrons;
      }
    }
  }

  // Function to pick city from suggestions - For User
  fillTextboxUser(str) {
    this.filterTopBarForm.patchValue({
      keyword: str
    });
    this.sortedCities = [];
    this.sortedCitiesUser = false;
    this.onTopSpenderKeyWordChange();
  }

  // Function to define the global City - for complete panel
  activateGlobalCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      //this.onKeyWordChange();

      this.sortedCitiesGlobal = false;
    } else {
      let keyword = this.activeCity;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.sortedCitiesGlobal = true;
        } else {
          this.sortedCities = [];
          this.sortedCitiesGlobal = false;
          window.localStorage.setItem("activeCity", "");
        }
      } else {
        this.sortedCities = [];
        this.sortedCitiesGlobal = false;
        window.localStorage.setItem("activeCity", "");
      }
    }
  }



   // Function to define the global City - for complete panel
   activateGlobalCountry(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      //this.onKeyWordChange();

      this.sortedCountriesGlobal = false;
    } else {
      let keyword = this.activeCountryId;
      if (keyword.length > 0) {
        let output = [];
        this.countries.forEach(function(countries) {
          if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(countries);
          }
        });
        
        if (output.length > 0) {
          // alert(output1);
          // this.sortedCities = output;
          this.sortedCountries =output;

          // this.sortedCitiesGlobal = true;
          this.sortedCountriesGlobal = true;

        } else {
          // this.sortedCities = [];
          this.sortedCountries =[];
          // this.sortedCitiesGlobal = false;
          this.sortedCountriesGlobal = false;
          window.localStorage.setItem("activeCountryId", "");
          this.getDashBoardData("Weekly");
        }
      } else {
        // this.sortedCities = [];
        // this.sortedCitiesGlobal = false;
        // window.localStorage.setItem("activeCity", "");
           this.sortedCountries =[];
          // this.sortedCitiesGlobal = false;
          this.sortedCountriesGlobal = false;
          window.localStorage.setItem("activeCountryId", "");
          this.getDashBoardData("Weekly");
      }
    }
  }

  // Function to pick city from suggestions
  fillGlobalCityTextBox(str) {
    this.activeCity = str;
    this.sortedCities = [];
    this.sortedCitiesGlobal = false;
    window.localStorage.setItem("activeCity", str);
    //this.onKeyWordChange();
    this.getDashBoardData("Weekly");
  }

  // Function to pick country from suggestions
  fillGlobalCountryTextBox(str) {
    this.activeCountryId = str;
    this.sortedCountries = [];
    this.sortedCountriesGlobal = false;
    window.localStorage.setItem("activeCountryId", str);
    //this.onKeyWordChange();
    this.getDashBoardData("Weekly");
  }
}
