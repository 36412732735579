import { Component, OnInit } from "@angular/core";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "app-add-patron",
  templateUrl: "./add-patron.component.html",
  styleUrls: ["./add-patron.component.css"]
})
export class AddPatronComponent implements OnInit {
  patronForm: FormGroup;
  showCancelBtn = false;
  pageTitle: String;
  userId: any;
  dynamicRoute: any;
  isEditable:boolean;
  constructor(
    private fb: FormBuilder,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    //menu Form Model Declaration with form validation for the fields
    this.patronForm = this.fb.group({
      userId:[""],
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ()]+$"),
          Validators.minLength(3),
          Validators.maxLength(25)
        ]
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ()]+$"),
          Validators.minLength(3),
          Validators.maxLength(25)
        ]
      ],
      userName: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(25)]
      ],
      email: ["", [Validators.required, Validators.email]],
      phone: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9()]+$"),
          Validators.minLength(10),
          Validators.maxLength(12)
        ]
      ],
      status: ["", [Validators.required]] //Validators.required
    });

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = parseInt(params.get("id"));
      if (this.userId){
        this.pageTitle = 'Edit Patron';
        this.loadPatronData(this.userId);
        this.dynamicRoute = 'editPatron';
        this.isEditable = false;
      }else{
        this.isEditable = true;
        this.pageTitle = 'Add Patron';
        this.dynamicRoute = 'addPatron';
      } 
      //console.log("user id" + userId);
      this.showCancelBtn = true;
    });
  }
  //patron Form Submission
  onPatronSubmit() {
    console.log(this.patronForm.value);
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const patronData = new FormData();
    patronData.append("userId", this.userId);
    patronData.append("firstName", this.patronForm.get("firstName").value);
    patronData.append("lastName", this.patronForm.get("lastName").value);
    patronData.append("userName", this.patronForm.get("userName").value);
    patronData.append("email", this.patronForm.get("email").value);
    patronData.append("phone", this.patronForm.get("phone").value);
    patronData.append("status", this.patronForm.get("status").value);
    patronData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(patronData, this.dynamicRoute).subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.router.navigate(["/view-patron"]);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Load patron Data in case of edit
  loadPatronData(userId) {
    const patronData = new FormData();
    patronData.append("userId", userId);
    patronData.append("token", localStorage.getItem("token"));
    //console.log("user id" + userId);
    this.bs.callAPI(patronData, "getPatronDetails").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //console.log("user id2" + userId);
          //Patch the Item data in patronForm
          this.patronForm.patchValue({
            //barId: localStorage.getItem("barId"),
            userId: response.data.userId,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            userName: response.data.userName,
            email: response.data.email,
            phone: response.data.phone,
            status: response.data.status
          });
          //console.log("firstName" + this.firstName);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Getor - To access the fields directly in the form in HTML
  get firstName() {
    return this.patronForm.get("firstName");
  }
  get lastName() {
    return this.patronForm.get("lastName");
  }
  get userName() {
    return this.patronForm.get("userName");
  }
  get email() {
    return this.patronForm.get("email");
  }
  get phone() {
    return this.patronForm.get("phone");
  }
  get status() {
    return this.patronForm.get("status");
  }
}
