import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, tap, map, retry } from "rxjs/operators";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class CommonService {
  /*====Development Server URLs====*/
  //  public baseUrl = "http://127.0.0.1:8000/api/";
  //  public mediaUrl = "http://127.0.0.1:8000/uploads/";
  //  public siteUrl = "http://127.0.0.1:8000/";

  /*====Client Testing URLs====*/
   public baseUrl = "https://business.stepoutlive.com/web-services/api/";
   public mediaUrl = "https://business.stepoutlive.com/web-services/public/uploads/";
   public siteUrl = "https://business.stepoutlive.com/";

  /*====Production URLs====*/
  // public baseUrl = "https://api.stepoutlive.com/api/";
  // public mediaUrl = "https://api.stepoutlive.com/uploads/";
  // public siteUrl = "https://web.stepoutlive.com/";

  constructor(
    private router: Router,
    private http: HttpClient,
    private location: Location
  ) {}

  //function to check Bar is logged in or NOT
  isUserLogin() {
    //will undo this condition to test dashboard
    if (!window.localStorage.getItem("isUserLogin")) {
      
      if (this.location.path() == "/restaurant-onboarding")
        this.router.navigate(["/restaurant-onboarding"]);
      else if(this.location.path() == "/restaurant-onboarding-new")
      this.router.navigate(["/restaurant-onboarding-new"]);
      else this.router.navigate(["/login"]);
      return;
    } else return true;
  }

  // function to Validate Admin Routes
  isAdminRoute() {
    if (window.localStorage.getItem("userType") != "A") {
      this.router.navigate(["/dashboard"]);
      return;
    }
  }

  // function to Validate Admin & Customer Care Executive Routes
  isAdminCustomerCareRoute() {
    if (
      window.localStorage.getItem("userType") == "A" ||
      window.localStorage.getItem("roleId") == "6"
    ) {
      return true;
    } else {
      this.router.navigate(["/dashboard"]);
      return;
    }
  }

  isAdminOrStaffRoute() {
    if (
      window.localStorage.getItem("userType") == "A" ||
      window.localStorage.getItem("userType") == "S" ||window.localStorage.getItem("userType") == "AS" || window.localStorage.getItem("userType") == "CS"
    ) {
    } else {
      this.router.navigate(["/dashboard"]);
      return;
    }
  }
  //function to return base URL
  bnUrl() {
    return this.baseUrl;
  }

  //Logo Path
  barLogoPath() {
    // return this.mediaUrl + "bars/logo/";
    return '';
  }

  //Cancel Cheque Path
  cancelChequePath() {
    // return this.mediaUrl + "bars/cheque/";
    return '';
  }

  //KYC Path

  barKycPath() {
    // return this.mediaUrl + "bars/kycdoc/";
    return '';
  }

  //Banner Path
  barBannerPath() {
    // return this.mediaUrl + "bars/banners/";
    return '';
  }

  //Menu Path
  barMenuPath() {
    // return this.mediaUrl + "bars/menu/";
    return '';
  }

  //Banner Path remove thumb
  barBannerPaththumb() {
    return this.mediaUrl + "bars/banners/thumb/";
  }

  //Meida Path
  barMediaPath() {
    // return this.mediaUrl + "bars/";
    return '';
  }

  //Items Path
  offersPath() {
    // return this.mediaUrl + "bars/offers/";
    return '';
  }
  //Items Path
  eventPath() {
    // return this.mediaUrl + "events/";
    return '';
  }
  //Template Path
  tempPath() {
    // return this.mediaUrl + "templates/";
    return '';
  }

  //Items Path
  itemsPath() {
    // return this.mediaUrl + "bars/items/";
    return '';
  }

  //User Profile Pic Path
  userProfilePic() {
    // return this.mediaUrl + "patrons/profile/";
    return '';
  }

  //User NewsFeed Path
  userNewsFeedPath() {
    return this.mediaUrl + "patrons/newsfeed/";
  }

  //Error Handler Method calling from all the services
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

  //Convert time from 24 hr to AM-PM
  convertTime(timeString) {
    let H = +timeString.substr(0, 2);
    let h = H % 12 || 12;
    let ampm = H < 12 || H === 24 ? " AM" : " PM";
    return (timeString = h + timeString.substr(2, 3) + ampm);
  }

  //Function to convert API Date in DateTime Formate
  convertToDateTime(data, option) {
    if (data == "" || data == undefined || data == "") {
      return "";
    }
    let dateArray = data.split(" ");
    let dt = dateArray[0].split("-");
    let onlyDate = dt[2] + "-" + dt[1] + "-" + dt[0];
    if (option == "date") {
      return onlyDate;
    } else {
      return onlyDate + ", " + this.convertTime(dateArray[1]);
    }
  }

  //This funciton is used to calculate Offer price of an item (Buy/Free items)
  getOfferPrice(buy, free, qty, price) {
    let finalQty = 0;
    let totalQty = buy + free;

    if (qty % totalQty == 0) {
      let avoid = qty / totalQty;
      finalQty = qty - avoid * free;
    } else {
      if (qty > totalQty) {
        let devidedBy = Math.floor(qty / totalQty); //Float to Int conversion
        let extraTimes = Math.floor(qty % totalQty);

        let extra = qty - totalQty;
        finalQty = buy * devidedBy + extraTimes;
      } else {
        finalQty = qty;
      }
    }
    return price * finalQty;
  }

  stringToDateFun(str) {
    if (str == "" || str == undefined || str == "") {
      return "";
    }
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  timeWithoutSeconds(str) {
    if (str != null && str != "") {
      let timeArray = str.split(":");
      return (timeArray[0] + ":" + timeArray[1]).toString();
    } else return "";
  }

  timevalidation(str) {
    //var startValue = new Date();
    let startValue = str.split(":");
    return (startValue[0] + ":" + startValue[1]).toString();
  }

  todayDate() {
    let date = new Date(),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  addHours(hour) {
    if (hour == "00") hour = 24;
    else if (hour == "01") hour = 25;
    else if (hour == "02") hour = 26;
    else if (hour == "03") hour = 27;
    else if (hour == "04") hour = 28;
    else if (hour == "05") hour = 29;
    else if (hour == "06") hour = 30;
    return hour;
  }
}
