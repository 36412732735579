import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-revenue-list",
  templateUrl: "./revenue-list.component.html",
  styleUrls: ["./revenue-list.component.css"]
})
export class RevenueListComponent implements OnInit {
  barData = [];
  hideLoadMore = true;
  page = 1;
  filterForm: FormGroup;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //this.cs.isAdminRoute();
    this.cs.isAdminOrStaffRoute();

    this.loadBarData("", "", "");

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      date: [""],
      city: [""]
    });

    // Load all cities
    this.cities = this.myapp.allCities;
  }

  //Load bar data defination
  loadBarData(keyword, date, city) {
    const userData = new FormData();
    userData.append("keyword", keyword);
    userData.append("date", date);
    userData.append("city", city);
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "getBarDailyRevenueReport").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barData = response.data;
        } else {
          this.barData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Define loadMoreData function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("city", this.filterForm.get("city").value);
    userData.append("page", pageNo);
    userData.append("token", localStorage.getItem("token"));
    let date = this.filterForm.get("date").value;
    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }
    userData.append("date", date);

    this.bs.callAPI(userData, "getBarDailyRevenueReport").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.barData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let city = this.filterForm.get("city").value;

    let date = this.filterForm.get("date").value;
    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }
    this.loadBarData(keyword, date, city);
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange();
  }
}
