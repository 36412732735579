import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-view-discount",
  templateUrl: "./view-discount.component.html",
  styleUrls: ["./view-discount.component.css"]
})
export class ViewDiscountComponent implements OnInit {
  //Varbiable Declation
  catData = []; //Used in filter form
  offerData = [];
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  offerImgPath = this.cs.offersPath();
  tempPath = this.cs.tempPath();
  todayDate: any = null;

  //Injecting the Services
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the loadDiscounts function
    this.loadDiscounts("", "", ""); //three empty arguments, bcz this function is using in filters aslo

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      appliedOn: [""],
      status: [""]
    });
    this.todayDate = this.cs.todayDate();
  }

  //Load Discounts defination
  loadDiscounts(keyword, appliedOn, status) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("offerType", "discount");
    barData.append("keyword", keyword);
    barData.append("appliedOn", appliedOn);
    barData.append("status", status);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getOffersListing").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.offerData = response.data;
          console.log(this.offerData);
        } else {
          this.offerData = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("offerType", "discount");
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("appliedOn", this.filterForm.get("appliedOn").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getOffersListing").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.offerData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let appliedOn = this.filterForm.get("appliedOn").value;
    let status = this.filterForm.get("status").value;
    this.loadDiscounts(keyword, appliedOn, status);
  }

  //On Applied/Status filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let appliedOn = this.filterForm.get("appliedOn").value;
    let status = this.filterForm.get("status").value;
    this.loadDiscounts(keyword, appliedOn, status);
  }

  //Function to Active/Inactive records
  changeStatus(id, value, index) {
    this.myapp.changeStatus("bnOfferDetails", id, value);

    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.offerData[index].status = true;
    else if (value == 0) this.offerData[index].status = false;
  }

  //Function to Delete records
  deleteRecord(id, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.deleteRecord("bnOfferDetails", id);
        //based on the index remove the values from loaded array
        this.offerData.splice(index, 1);
      }
    });
  }

  //Function to return time in am/pm format on HTML
  convertTime(startTime, endTime) {
    return (
      this.cs.convertTime(startTime) + " - " + this.cs.convertTime(endTime)
    );
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }
}
