import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-pos",
  templateUrl: "./pos.component.html",
  styleUrls: ["./pos.component.css"]
})
export class PosComponent implements OnInit {
  //Variable Declration
  tableVisits: any = [];
  tabVisit: any = [];
  userPicPath = this.cs.userProfilePic();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    //Call the function
    this.loadVisits();

    setInterval(() => {
      this.loadVisits();
    }, 3000);
  }

  //Load Visit function definiation
  loadVisits() {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getPosData").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.tableVisits = response.data.tableVisits;

          if (this.tableVisits.length) {
            if (!this.tabVisit.id) this.tabVisit = this.tableVisits[0];
          }
        } else {
          this.tabVisit = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // Function to show data on tab
  loadTabData(i, orderStatus: any, orderType: any, isReedem: any, id: any) {
    if (
      (orderStatus == "pending" &&
        orderType == "preorder" &&
        isReedem == "1") ||
      (orderStatus == "pending" && (orderType == "order" || orderType == "gift"))
    ) {
      this.router.navigate(["/create-kot/" + id]);
    } else this.tabVisit = this.tableVisits[i];
  }

  dispatchKots(orderId) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to dispatch the orders?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const formData = new FormData();
        formData.append("orderId", orderId);
        formData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs.callAPI(formData, "dispatchAllKots").subscribe(response => {
          if (response.status === 1) {
            this.tabVisit.orderStatus = "delivered";

            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });

    return false;
  }
}
