import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: 'app-non-sol-user-list',
  templateUrl: './non-sol-user-list.component.html',
  styleUrls: ['./non-sol-user-list.component.css']
})
export class NonSolUserListComponent implements OnInit {
  cancelForm: FormGroup;
  broadForm: FormGroup;
  barData = [];
  visitData = [];
  page = 1; //used for load more
  hideLoadMore = true;
  filterForm: FormGroup;
  showBackButton = false;
  cancelReason: any = null;
  cancelByName: any = null;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;
  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;


  //Injecting the Services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
     //Define the Filter Form Model
     this.filterForm = this.fb.group({
      barId: [localStorage.getItem("barId")],
      keyword: [""],
      status: [""],
      city: [localStorage.getItem("activeCity")],
      country: [localStorage.getItem("activeCountryId")]
      
    });
    this.cities = this.myapp.allCities;
    this.countries = this.myapp.allCountries;
    this.loadNonSolUser("", "");
    

    let pickBarName = localStorage.getItem("activeBarName");
    if (pickBarName == "" || pickBarName == undefined) {
    } else {
      this.showBackButton = true;
    }
  }


    //Load Visit function definiation
    loadNonSolUser(keyword, status) {
      const barData = new FormData();
      // barData.append("barId", localStorage.getItem("barId"));
      barData.append("keyword", keyword);
      barData.append("status", status);
      // barData.append("city", this.filterForm.get("city").value);
      // barData.append("countryId", this.filterForm.get("country").value);
      barData.append("userId", window.localStorage.getItem("userId"));
  // console.log(barData);
      barData.append("token", localStorage.getItem("token"));
  
      this.bs.callAPI(barData, "getAllNonSolUser").subscribe(
        (response: any) => {
          if (response.status == 1) {
            console.log(response);
            
            this.visitData = response.data;
            //console.log("All Cities:" + this.myapp.allCities);
          } else {
            this.visitData = [];
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  
    //Load More Data Function
    loadMoreData(pageNo) {
      this.myapp.isLoadingResults = true;
      this.myapp.hideAlerts();
      const barData = new FormData();
      barData.append("userId", window.localStorage.getItem("userId"));
      barData.append("barId", localStorage.getItem("barId"));
      barData.append("keyword", this.filterForm.get("keyword").value);
      barData.append("city", this.filterForm.get("city").value);
      barData.append("countryId", this.filterForm.get("country").value);
      barData.append("status", this.filterForm.get("status").value);
      barData.append("page", pageNo);
      barData.append("token", localStorage.getItem("token"));
  
      this.bs.callAPI(barData, "getAllNonSolUser").subscribe(
        (response: any) => {
          console.log(response);
          
          if (response.status == 1) {
            this.page++; //Increment of the page
            this.myapp.isLoadingResults = false;
  
            for (let i = 0; i < response.data.length; i++) {
              this.visitData.push(response.data[i]);
            }
  
            //Hide the load more button if reaches ot limit
            if (response.limit != response.data.length) this.hideLoadMore = false;
          } else {
            this.myapp.isLoadingResults = false;
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  
    //On Keyword filter
    onKeyWordChange(keyword) {
      this.hideLoadMore = true;
      this.page = 1;
      let status = this.filterForm.get("status").value;
      let city = this.filterForm.get("city").value;
      let country = this.filterForm.get("country").value;
      this.loadNonSolUser(keyword, status);
    }
  
    //On Applied/Status filter
    onDropDownChange() {
      this.hideLoadMore = true;
      this.page = 1;
      let keyword = this.filterForm.get("keyword").value;
      let status = this.filterForm.get("status").value;
      let city = this.filterForm.get("city").value;
      let country = this.filterForm.get("country").value;
      this.loadNonSolUser(keyword, status);
    }
  
    // Function to filer Cities - for Bar
    filterByCity(keyCode) {
      // Search on Enter Key Press
      if (keyCode == 13) {
        this.onKeyWordChange('');
        this.citiesSorted = false;
      } else {
        let keyword = this.filterForm.get("city").value;
        if (keyword.length > 0) {
          let output = [];
          this.cities.forEach(function(cities) {
            if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
              output.push(cities);
            }
          });
          if (output.length > 0) {
            this.sortedCities = output;
            this.citiesSorted = true;
          } else {
            this.sortedCities = [];
            this.citiesSorted = false;
          }
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
          this.onKeyWordChange('');
        }
      }
    }
  
    // Function to pick city from suggestions
    fillTextBox(str) {
      this.filterForm.patchValue({
        city: str
      });
      this.sortedCities = [];
      this.citiesSorted = false;
      this.onKeyWordChange('');
    }
  
    // Function to filer Cities - for Bar
    filterByCountry(keyCode) {
      // Search on Enter Key Press
      if (keyCode == 13) {
        this.onKeyWordChange('');
        this.countriesSorted = false;
      } else {
        let keyword = this.filterForm.get("country").value;
        if (keyword.length > 0) {
          let output = [];
          this.countries.forEach(function(countries) {
            if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
              output.push(countries);
            }
          });
          if (output.length > 0) {
            this.sortedCountries = output;
            this.countriesSorted = true;
          } else {
            this.sortedCountries = [];
            this.countriesSorted = false;
          }
        } else {
          this.sortedCountries = [];
          this.countriesSorted = false;
          this.onKeyWordChange('');
        }
      }
    }
  
    // Function to pick city from suggestions
    fillTextBoxcountry(str) {
      this.filterForm.patchValue({
        country: str
      });
      this.sortedCountries = [];
      this.countriesSorted = false;
      this.onKeyWordChange('');
    }
  
  
    
  
    
  
    //Function to convert API Date in Date Formate
    convertToDateTime(data, option) {
      return this.cs.convertToDateTime(data, option);
    }
    backToShowAll() {
      console.log("Back to show all data is click");
      localStorage.removeItem("barId");
      localStorage.removeItem("activeBarId");
      localStorage.removeItem("activeBarName");
      this.myapp.pickBarName = "Pick a Bar";
      this.showBackButton = false;
      this.loadNonSolUser("", "");
      // this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};
      // let currentUrl = this.router.url + '?';
      // this.router.navigateByUrl(currentUrl)
      // .then(() => {
      //   this.router.navigated = false;
      //   this.router.navigate([this.router.url]);
      // });
      //this.router.navigate([this.router.url]);
    }
  
    //Function to show the reason in modal
    loadVisitReason(cancelBy, reason) {
      this.cancelByName = cancelBy;
      this.cancelReason = reason;
    }

}
