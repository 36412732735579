import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import {
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_DATE_LOCALE
} from "@angular/material";

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import { MatDatepicker } from "@angular/material/datepicker";
import * as _moment from "moment";
import { Moment } from "moment";
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY"
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
@Component({
  selector: "app-all-special-event",
  templateUrl: "./all-special-event.component.html",
  styleUrls: ["./all-special-event.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class AllSpecialEventComponent implements OnInit {
  date = new FormControl(moment());
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.filterForm.patchValue({
      date: this.date.value
    });
    this.onKeyWordChange();
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    console.log("Modified Date" + this.date.value);
    this.filterForm.patchValue({
      date: this.date.value
    });
    datepicker.close();
    this.onKeyWordChange();
  }
  //Varbiable Declation
  eventList = [];
  page = 1; //used for load more
  hideLoadMore = true;
  filterForm: FormGroup;
  todayDay: string;
  showBackButton = false;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;

  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;
  barId: any;
  restaurantName: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the loadSpecialEvent function
   
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.barId = id;
        // this.loadTicketBooking(id, "");
        // this.loadVisits(id, "");
        // this.loadDiscounts();
      } else {
      }
    });
    this.cities = this.myapp.allCities;
    this.countries = this.myapp.allCountries;
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      date: [""],
      status: [""],
      city: [localStorage.getItem("activeCity")],
      country: [localStorage.getItem("activeCountryId")]
    });
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    this.todayDay = yyyy + "-" + mm + "-" + dd;
    //Controll load all data and specific bar data
    let pickBarName = localStorage.getItem("activeBarName");
    if (pickBarName == "" || pickBarName == undefined) {
    } else {
      this.showBackButton = true;
    }
    this.loadSpecailEvent("", "", "");
  }
  //Define Special event function
  loadSpecailEvent(keyword, date, status) {
    const barData = new FormData();
    barData.append("barId", this.barId);
    barData.append("keyword", keyword);
    barData.append("date", date);
    barData.append("status", status);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("countryId", this.filterForm.get("country").value);
    barData.append("userId", window.localStorage.getItem("userId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getAllSpecialEvents").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.eventList = response.data;
          this.restaurantName =response.restaurantName;
        } else {
          this.eventList = [];
          this.restaurantName = response.restaurantName;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    // barData.append("barId", localStorage.getItem("barId"));
    barData.append("barId", this.barId);
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("date", this.filterForm.get("date").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("page", pageNo);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("countryId", this.filterForm.get("country").value);
    barData.append("userId", window.localStorage.getItem("userId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getAllSpecialEvents").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.eventList.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    let date = this.filterForm.get("date").value;
    //alert("date:" + date);
    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }
    //let status = this.filterForm.get("status").value;
    //alert("keyword:" + keyword + "date:" + date + "status:" + status);
    this.loadSpecailEvent(keyword, date, status);
  }
  //On Applied/Status filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    let date = this.filterForm.get("date").value;
    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }
    let status = this.filterForm.get("status").value;
    //alert('keyword:'+keyword +'date:' +date+ 'status:' +status);
    this.loadSpecailEvent(keyword, date, status);
  }

   // Function to filer Cities - for Bar
   filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange();
  }

  // Function to filer Cities - for Bar
  filterByCountry(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.countriesSorted = false;
    } else {
      let keyword = this.filterForm.get("country").value;
      if (keyword.length > 0) {
        let output = [];
        this.countries.forEach(function(countries) {
          if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(countries);
          }
        });
        if (output.length > 0) {
          this.sortedCountries = output;
          this.countriesSorted = true;
        } else {
          this.sortedCountries = [];
          this.countriesSorted = false;
        }
      } else {
        this.sortedCountries = [];
        this.countriesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBoxCountry(str) {
    this.filterForm.patchValue({
      country: str
    });
    this.sortedCountries = [];
    this.countriesSorted = false;
    this.onKeyWordChange();
  }

  //Function to Active/Inactive records
  changeStatus(id, value, index) {
    this.myapp.changeStatus("bnSpecialEvents", id, value);
    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.eventList[index].status = true;
    else if (value == 0) this.eventList[index].status = false;
  }
  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to load default data when bar is unselected
  backToShowAll() {
    console.log("Back to show all data is click");
    localStorage.removeItem("barId");
    localStorage.removeItem("activeBarId");
    localStorage.removeItem("activeBarName");
    this.myapp.pickBarName = "Pick a Bar";
    this.showBackButton = false;
    this.loadSpecailEvent("", "", "");
    ("");
  }

  // Function to return time in am/pm format on HTML
  convertTime(startTime, endTime) {
    return (
      this.cs.convertTime(startTime) + " - " + this.cs.convertTime(endTime)
    );
  }
}
