import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../../service/bar.service";
import { CommonService } from "../../../service/common.service";
import { AppComponent } from "../../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-view-price',
  templateUrl: './view-price.component.html',
  styleUrls: ['./view-price.component.css']
})
export class ViewPriceComponent implements OnInit {

  eventPriceData = [];
  hideLoadMore = true;
  page = 1; //used for load more

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    this.loadEventPriceData();
  }

  //Load staff data defination
  loadEventPriceData() {
    const formParam = new FormData();
    formParam.append("token", localStorage.getItem("token"));
    this.bs.callAPI('', "specialEventFeeList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.eventPriceData = response.data;
        } else {
          this.eventPriceData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formParam = new FormData();
    formParam.append("page", pageNo);
    formParam.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formParam, "specialEventFeeList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.eventPriceData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteEventPrice(id,index){
    console.log("Event Price id:"+id);
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const param = new FormData();
       param.append("id", id);
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        param.append("token", localStorage.getItem("token"));
        this.bs.callAPI(param, "deleteSpecialEventFee").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            //Delete the item from array
            this.eventPriceData.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }
}