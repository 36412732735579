import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { BarService } from "../service/bar.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "app-avail-code-dialog",
  templateUrl: "./avail-code-dialog.component.html",
  styleUrls: ["./avail-code-dialog.component.css"]
})
export class AvailCodeDialogComponent implements OnInit {
  //Variable Declration
  visitForm: FormGroup;
  showSuccess = false;
  showError = false;
  sucMsg = "";
  errMsg = "";

  //Injecting Services
  constructor(
    public dialogRef: MatDialogRef<AvailCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private bs: BarService,
    private router: Router
  ) {}

  ngOnInit() {
    //Visit Form modal, in the popup
    this.visitForm = this.fb.group({
      visitId: [""],
      tableNo: [""],
      standingNo: [""]
    });

    if (this.data.tableNumber)
      this.visitForm.get("tableNo").setValue(this.data.tableNumber);

    //Patch values in the modal
    this.visitForm.patchValue({
      visitId: this.data.id
    });
  }

  // Function to Find the code in DB in avail
  availCode() {
    this.showError = false;
    this.errMsg = "";
    let tableNo = this.visitForm.get("tableNo").value;
    let standingNo = this.visitForm.get("standingNo").value;
    let sittingType = "";
    let tableStandingNo = "";

    //Validate, to check both one of the option should be field
    if (tableNo > 0 || standingNo > 0) {
      //Validate, to check both text box should not be field
      if (tableNo.length > 0 && standingNo.length > 0) {
        this.showError = true;
        this.errMsg = "Sorry, only one option is allowed.";
      } else {
        if (tableNo.length > 0) {
          sittingType = "table";
          tableStandingNo = tableNo;
        } else {
          sittingType = "standing";
          tableStandingNo = standingNo;
        }
        const availData = new FormData();
        availData.append("visitId", this.data.id);
        availData.append("sittingType", sittingType);
        availData.append("tableStandingNo", tableStandingNo);
        availData.append("token", localStorage.getItem("token"));

        this.bs.callAPI(availData, "availCode").subscribe(
          (response: any) => {
            if (response.status == 1) {
              this.dialogRef.close();

              if (this.router.url == "/dashboard")
                this.router.navigate(["/dashboard/code"]);
              else this.router.navigate(["/dashboard/"]);

              //Navigate to Create KOT route
              //this.router.navigate(["/create-kot/" + this.data.id]);
            } else {
              this.showError = true;
              this.errMsg = response.message;
            }
          },
          error => {
            console.log(error);
          }
        );
      }
    } else {
      this.showError = true;
      this.errMsg = "Sorry, Please enter valid Table number.";
    }
  }

  // Function to avail the booking and continue the visit
  availBooking() {
    this.showError = false;
    this.errMsg = "";
    let tableNo = this.visitForm.get("tableNo").value;
    let sittingType = "table";
    let tableStandingNo = tableNo;

    //Validate, to check both one of the option should be field
    if (tableNo.length > 0) {
      const availData = new FormData();
      availData.append("visitId", this.data.id);
      availData.append("sittingType", sittingType);
      availData.append("tableStandingNo", tableStandingNo);
      availData.append("bookTable", "1");
      availData.append("token", localStorage.getItem("token"));
      
      this.bs.callAPI(availData, "availCode").subscribe(
        (response: any) => {
          if (response.status == 1) {
            this.dialogRef.close();

            if (this.router.url == "/dashboard")
              this.router.navigate(["/dashboard/code"]);
            else this.router.navigate(["/dashboard/"]);

            //Navigate to Create KOT route
            //this.router.navigate(["/create-kot/" + this.data.id]);
          } else {
            this.showError = true;
            this.errMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.showError = true;
      this.errMsg = "Sorry, Please enter Table number";
    }
  }

  // Function to avail the booking and continue the visit
  availEvent() {
    this.showError = false;
    this.errMsg = "";
    let tableNo = this.visitForm.get("tableNo").value;
    let standingNo = this.visitForm.get("standingNo").value;
    let sittingType = "";
    let tableStandingNo = "";

    //Validate, to check both one of the option should be field
    if (tableNo.length > 0 || standingNo.length > 0) {
      //Validate, to check both text box should not be field
      if (tableNo.length > 0 && standingNo.length > 0) {
        this.showError = true;
        this.errMsg = "Sorry, only one option is allowed.";
      } else {
        if (tableNo.length > 0) {
          sittingType = "table";
          tableStandingNo = tableNo;
        } else {
          sittingType = "standing";
          tableStandingNo = standingNo;
        }

        const availData = new FormData();
        availData.append("visitId", this.data.id);
        availData.append("sittingType", sittingType);
        availData.append("tableStandingNo", tableStandingNo);
        availData.append("eventBooking", "1");
        availData.append("token", localStorage.getItem("token"));

        this.bs.callAPI(availData, "availCode").subscribe(
          (response: any) => {
            if (response.status == 1) {
              this.dialogRef.close();

              if (this.router.url == "/dashboard")
                this.router.navigate(["/dashboard/code"]);
              else this.router.navigate(["/dashboard/"]);
              //Navigate to Create KOT route
              //this.router.navigate(["/create-kot/" + this.data.id]);
            } else {
              this.showError = true;
              this.errMsg = response.message;
            }
          },
          error => {
            console.log(error);
          }
        );
      }
    } else {
      this.showError = true;
      this.errMsg = "Sorry, Please enter Table or Standing number.";
    }
  }
}
