import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";
@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.css"]
})
export class SettingComponent implements OnInit {
  settingForm: FormGroup;
  cgstErrorMessage = false;
  cgstMaxErrorMessage = false;
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: "10rem",
    minHeight: "5rem",
    placeholder: "Type something. Test the Editor... ヽ(^。^)丿",
    translate: "no"
  };
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    this.settingForm = this.fb.group({
      commission: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      paymentGatewayFees: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      paymentGatewayFeesTax: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      eventCommission: [
        "10"
        //[Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      taxOnCommission: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      barOneCommission: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      barThreeCommission: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      barTwoCommission: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      topBarLimit: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      topSpenderMinLimit: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      cashBackOn: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      cashBackAmount: [
        "",
        [Validators.required, Validators.pattern("^[0-9() . ]+$")]
      ],
      abusive: [""],
      bookingTokenMoney: [""],
      aboutUs: [""],
      termsCondtions: [""],
      privacy: [""],
      contactEmail: ["", [Validators.required, Validators.email]],
      faq: [""],
      cgst: ["", [Validators.pattern("^[0-9() . ]+$")]],
      sgst: ["", [Validators.pattern("^[0-9() . ]+$")]],
      serviceCharge: ["", [Validators.pattern("^[0-9() . ]+$")]],
      serviceChargeTax: ["", [Validators.pattern("^[0-9() . ]+$")]],
      discountRate: ["", [Validators.pattern("^[0-9() . ]+$")]],
      isDiscountEnable: [""],
      android_version:[""],
      ios_version:[""],
      gst_number:[""]
    });

    this.bs.callAPI("", "getCmsSetting").subscribe(
      (response: any) => {
        if (response.status == 1) {
          console.log(response);
          this.settingForm.patchValue({
            commission: response.data.commission,
            paymentGatewayFees: response.data.paymentGatewayFees,
            paymentGatewayFeesTax: response.data.paymentGatewayFeesTax,
            eventCommission: response.data.eventCommission,
            taxOnCommission: response.data.taxOnCommission,
            barOneCommission: response.data.barOneCommission,
            barTwoCommission: response.data.barTwoCommission,
            barThreeCommission: response.data.barThreeCommission,
            topBarLimit: response.data.topBarLimit,
            topSpenderMinLimit: response.data.topSpenderMinLimit,
            aboutUs: response.data.aboutUs,
            termsCondtions: response.data.termsAndConditions,
            privacy: response.data.privacyPolicy,
            contactEmail: response.data.contactEmail,
            faq: response.data.faq,
            cashBackOn: response.data.cashBackOn,
            cashBackAmount: response.data.cashBackAmount,
            abusive: response.data.abusiveWords,
            bookingTokenMoney: response.data.bookingTokenMoney,
            cgst: response.data.cgst,
            sgst: response.data.sgst,
            serviceCharge: response.data.serviceCharge,
            serviceChargeTax: response.data.serviceChargeTax,
            discountRate: response.data.discountRate,
            isDiscountEnable: response.data.isDiscountEnable,
            android_version:response.data.android_version,
            ios_version:response.data.ios_version,
            gst_number:response.data.gst_number,
          });
        } else {
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //sbmit payment form defination
  onSettingSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;

    console.log("cgst");
    // Function to validate cgstValue
    // let cgstValue = this.cgst.value;
    // if (cgstValue == 0) {
    //   console.log("cgst2");
    //   this.cgstErrorMessage = true;
    //   canSubmit = false;
    // } else if (cgstValue > 100) {
    //   this.cgstMaxErrorMessage = true;
    //   canSubmit = false;
    // } else {
    //   this.cgstErrorMessage = false;
    //   this.cgstMaxErrorMessage = false;
    // }

    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("commission", this.settingForm.get("commission").value);
      formData.append(
        "paymentGatewayFees",
        this.settingForm.get("paymentGatewayFees").value
      );
      formData.append(
        "paymentGatewayFeesTax",
        this.settingForm.get("paymentGatewayFeesTax").value
      );
      formData.append(
        "eventCommission",
        this.settingForm.get("eventCommission").value
      );
      formData.append(
        "taxOnCommission",
        this.settingForm.get("taxOnCommission").value
      );
      formData.append(
        "barOneCommission",
        this.settingForm.get("barOneCommission").value
      );
      formData.append(
        "barTwoCommission",
        this.settingForm.get("barTwoCommission").value
      );
      formData.append(
        "barThreeCommission",
        this.settingForm.get("barThreeCommission").value
      );
      formData.append("topBarLimit", this.settingForm.get("topBarLimit").value);
      formData.append(
        "topSpenderMinLimit",
        this.settingForm.get("topSpenderMinLimit").value
      );
      formData.append("aboutUs", this.settingForm.get("aboutUs").value);
      formData.append(
        "termsCondtions",
        this.settingForm.get("termsCondtions").value
      );
      formData.append("privacy", this.settingForm.get("privacy").value);
      formData.append(
        "contactEmail",
        this.settingForm.get("contactEmail").value
      );
      formData.append("faq", this.settingForm.get("faq").value);
      formData.append("cashBackOn", this.settingForm.get("cashBackOn").value);
      formData.append(
        "cashBackAmount",
        this.settingForm.get("cashBackAmount").value
      );
      formData.append("abusive", this.settingForm.get("abusive").value);
      formData.append(
        "bookingTokenMoney",
        this.settingForm.get("bookingTokenMoney").value
      );
      formData.append("cgst", this.settingForm.get("cgst").value);
      formData.append("sgst", this.settingForm.get("sgst").value);
      formData.append(
        "serviceCharge",
        this.settingForm.get("serviceCharge").value
      );
      formData.append(
        "serviceChargeTax",
        this.settingForm.get("serviceChargeTax").value
      );

      formData.append(
        "discountRate",
        this.settingForm.get("discountRate").value
      );

      let discountAvailable: any = 0;
      if (this.isDiscountEnable.value) discountAvailable = 1;
      formData.append("isDiscountEnable", discountAvailable);
      formData.append("token", localStorage.getItem("token"));
      formData.append("android_version", this.android_version.value);
      formData.append("ios_version", this.ios_version.value);
      formData.append("gst_number", this.gst_number.value);
      

      //Form submit by appi call
      this.bs.callAPI(formData, "editCmsSetting").subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.router.navigate(["/setting"]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    }
  }
  get commission() {
    return this.settingForm.get("commission");
  }
  get paymentGatewayFees() {
    return this.settingForm.get("paymentGatewayFees");
  }
  get paymentGatewayFeesTax() {
    return this.settingForm.get("paymentGatewayFeesTax");
  }
  get eventCommission() {
    return this.settingForm.get("eventCommission");
  }
  get taxOnCommission() {
    return this.settingForm.get("taxOnCommission");
  }
  get barOneCommission() {
    return this.settingForm.get("barOneCommission");
  }
  get barTwoCommission() {
    return this.settingForm.get("barTwoCommission");
  }
  get barThreeCommission() {
    return this.settingForm.get("barThreeCommission");
  }
  get topBarLimit() {
    return this.settingForm.get("topBarLimit");
  }
  get topSpenderMinLimit() {
    return this.settingForm.get("topSpenderMinLimit");
  }
  get aboutUs() {
    return this.settingForm.get("aboutUs");
  }
  get termsCondtions() {
    return this.settingForm.get("termsCondtions");
  }
  get privacy() {
    return this.settingForm.get("privacy");
  }
  get contactEmail() {
    return this.settingForm.get("contactEmail");
  }
  get faq() {
    return this.settingForm.get("faq");
  }
  get cashBackOn() {
    return this.settingForm.get("cashBackOn");
  }
  get cashBackAmount() {
    return this.settingForm.get("cashBackAmount");
  }
  get abusive() {
    return this.settingForm.get("abusive");
  }
  get bookingTokenMoney() {
    return this.settingForm.get("bookingTokenMoney");
  }
  get cgst() {
    return this.settingForm.get("cgst");
  }
  get sgst() {
    return this.settingForm.get("sgst");
  }
  get serviceCharge() {
    return this.settingForm.get("serviceCharge");
  }
  get serviceChargeTax() {
    return this.settingForm.get("serviceChargeTax");
  }
  get discountRate() {
    return this.settingForm.get("discountRate");
  }
  get isDiscountEnable() {
    return this.settingForm.get("isDiscountEnable");
  }
  get ios_version() {
    return this.settingForm.get("ios_version");
  }
  get android_version() {
    return this.settingForm.get("android_version");
  }
  get gst_number() {
    return this.settingForm.get("gst_number");
  }
}
