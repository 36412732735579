import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: 'app-admin-payment-bar',
  templateUrl: './admin-payment-bar.component.html',
  styleUrls: ['./admin-payment-bar.component.css']
})
export class AdminPaymentBarComponent implements OnInit {

  transactionData = []; //Used in filter form
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  showBackButton = false;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;
  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;

  //Injecting the Services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    // this.cs.isAdminRoute();
    if (
      window.localStorage.getItem("userType") == "A" ||
      window.localStorage.getItem("userType") == "S" ||window.localStorage.getItem("userType") == "AS" ) {
    } else {
      this.router.navigate(["/dashboard"]);
      return;
    }
  

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      paymentMode: [""],
      date: [""],
      city: [localStorage.getItem("activeCity")],
      country: [localStorage.getItem("activeCountryId")]
    });
    //Controll load all data and specific bar data
    let pickBarName = localStorage.getItem("activeBarName");
    if (pickBarName == "" || pickBarName == undefined) {
    } else {
      this.showBackButton = true;
    }

    // Load all cities
    this.cities = this.myapp.allCities;
    this.countries = this.myapp.allCountries;

    //load Transaction Data
    this.loadTransactionData();
  }
  //Load Transaction Data defination
  loadTransactionData() {
    const param = new FormData();
    param.append("barId", localStorage.getItem("barId"));
    param.append("keyword", this.filterForm.get("keyword").value);
    param.append("date", this.filterForm.get("date").value);
    param.append("city", this.filterForm.get("city").value);
    param.append("countryId", this.filterForm.get("country").value);
    param.append("paymentMode", this.filterForm.get("paymentMode").value);
    param.append("userId", window.localStorage.getItem("userId"));
    param.append("token", localStorage.getItem("token"));

    this.bs.callAPI(param, "getAllBarTransaction").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.transactionData = response.data;
        } else {
          this.transactionData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const param = new FormData();
    param.append("barId", localStorage.getItem("barId"));
    param.append("page", pageNo);
    param.append("keyword", this.filterForm.get("keyword").value);
    param.append("date", this.filterForm.get("date").value);
    param.append("city", this.filterForm.get("city").value);
    param.append("countryId", this.filterForm.get("country").value);
    param.append("paymentMode", this.filterForm.get("paymentMode").value);
    param.append("userId", window.localStorage.getItem("userId"));
    param.append("token", localStorage.getItem("token"));

    this.bs.callAPI(param, "getAllBarTransaction").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.transactionData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    let date = this.filterForm.get("date").value;
    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }
    let paymentMode = this.filterForm.get("paymentMode").value;
    this.loadTransactionData();
  }

  //On Payment Mode filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let date = this.filterForm.get("date").value;
    let city = this.filterForm.get("city").value;
    if (date == undefined || date == null || date == "") {
      date = "";
    } else {
      if (typeof date === "object") {
        date = this.cs.stringToDateFun(date);
      }
    }
    let paymentMode = this.filterForm.get("paymentMode").value;
    //alert('keyword:'+keyword +'date:' +date+ 'status:' +status);
    this.loadTransactionData();
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange();
  }


  // Function to filer Cities - for Bar
  filterByCountry(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.countriesSorted = false;
    } else {
      let keyword = this.filterForm.get("country").value;
      if (keyword.length > 0) {
        let output = [];
        this.countries.forEach(function(countries) {
          if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(countries);
          }
        });
        if (output.length > 0) {
          this.sortedCountries = output;
          this.countriesSorted = true;
        } else {
          this.sortedCountries = [];
          this.countriesSorted = false;
        }
      } else {
        this.sortedCountries = [];
        this.countriesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBoxCountry(str) {
    this.filterForm.patchValue({
      country: str
    });
    this.sortedCountries = [];
    this.countriesSorted = false;
    this.onKeyWordChange();
  }
  backToShowAll() {
    console.log("Back to show all data is click");
    localStorage.removeItem("barId");
    localStorage.removeItem("activeBarId");
    localStorage.removeItem("activeBarName");
    this.myapp.pickBarName = "Pick a Restaurant";
    this.showBackButton = false;
    this.loadTransactionData();
  }


}
