import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-newsfeed",
  templateUrl: "./newsfeed.component.html",
  styleUrls: ["./newsfeed.component.css"]
})
export class NewsfeedComponent implements OnInit {
  newsFeedData = [];
  userPicPath = this.cs.userProfilePic();
  mediaPath = this.cs.userNewsFeedPath();
  hideMediaLoadMore = true;
  page = 1; //Used in Pagination

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    this.loadNewsFeed();
  }

  //Load News feed
  loadNewsFeed() {
    //const newsfeedData = new FormData();
    //newsfeedData.append("barId", localStorage.getItem("barId"));
    this.bs.callAPI(null, "getReportedNewsFeed").subscribe(
      (response: any) => {
        //console.log(response.data);
        if (response.status == 1) {
          this.newsFeedData = response.data;
          //console.log("jcndcn" + this.newsFeedData);
        } else {
          this.newsFeedData = [];
          //console.log("newsFeedData");
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const newsfeedData = new FormData();
    newsfeedData.append("page", pageNo);
    newsfeedData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(newsfeedData, "getReportedNewsFeed").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //console.log(response);
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;
          for (let i = 0; i < response.data.length; i++) {
            this.newsFeedData.push(response.data[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length)
            this.hideMediaLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Media Delete Function
  deleteNewsFeed(id, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          "Do you want to delete this from Patron App? No action can't be taken after this."
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        const newsfeedData = new FormData();
        newsfeedData.append("id", id);
        newsfeedData.append("token", localStorage.getItem("token"));
        this.bs
          .callAPI(newsfeedData, "deleteReportedNewsFeed")
          .subscribe(response => {
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.newsFeedData[index].isDeleted = 1;
              //Delete the key from array
              //this.newsFeedData.splice(index, 1);
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
            }
          });
        return false;
      }
    });
    return false;
  }
  //Media Delete Function
  ignoreNewsFeed(id, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to ignore this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        const newsfeedData = new FormData();
        newsfeedData.append("id", id);
        newsfeedData.append("token", localStorage.getItem("token"));
        this.bs
          .callAPI(newsfeedData, "ignoreReportedNewsFeed")
          .subscribe(response => {
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              //Delete the key from array
              this.newsFeedData.splice(index, 1);
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
            }
          });
        return false;
      }
    });
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }
}
