import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  Input
} from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { ExportService } from "../service/export.service";

import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"]
})
export class ReportComponent implements OnInit {
  //Variable Declration
  public tableHeading: string = "";
  public tableData: any = [];
  public salesData = [];
  public timeType: string = "Weekly";
  //public chartData: any = [this.obj]; //{ data: [], label: "" };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    private expSer: ExportService
  ) {}

  ngOnInit() {
    //Call Load Graph method
    this.loadGraph("Weekly");
  }

  //Load Menu items defination
  loadGraph(reportType) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("time", reportType);
    barData.append("token", localStorage.getItem("token"));
    this.timeType = reportType;

    // Change the table heading, display on HTML
    if (reportType === "Yearly") this.tableHeading = "Year";
    else if (reportType === "Monthly") this.tableHeading = "Month";
    else this.tableHeading = "Date";

    this.bs.callAPI(barData, "loadGraph").subscribe(
      (response: any) => {
        if (response.status == 1) {
          // console.log(response.data);
          let keys = [];
          let values = [];
          this.tableData = [];
          for (let i = 0; i < response.data.length; i++) {
            //Chart Data
            keys.push(response.data[i].key);
            values.push(response.data[i].value);

            let tdata = {
              key: response.data[i].key,
              value: response.data[i].value
            };
            this.tableData.push(tdata);
          }
          //Update the Lables and Data in the Graph variables directly
          this.barChartLabels = keys;
          this.barChartData = [{ data: values, label: reportType }];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // Chart.js
  public barChartOptions: ChartOptions = {
    responsive: true
  };

  public barChartLabels: Label[]; // = this.labels;

  public chartColors: Array<any> = [
    {
      backgroundColor: "rgba(42, 82, 180,0.6)",
      borderColor: "rgba(42, 82, 180,0.6)",
      fillColor: "rgba(225,10,24,0.2)",
      strokeColor: "rgba(11,255,20,1)",
      pointColor: "rgba(111,200,200,1)",
      pointStrokeColor: "#fff",
      pointHighlightFill: "#fff",
      pointHighlightStroke: "rgba(200,100,10,0.8)"
    },
    {
      backgroundColor: "rgba(215, 2, 48,0.2)",
      borderColor: "rgba(215, 2, 48,0.2)",
      fillColor: "rgba(225,10,24,0.2)",
      strokeColor: "rgba(11,255,20,1)",
      pointColor: "rgba(111,200,200,1)",
      pointStrokeColor: "#fff",
      pointHighlightFill: "#fff",
      pointHighlightStroke: "rgba(200,100,10,0.8)"
    }
  ];

  public barChartType: ChartType = "bar";
  public barChartLegend = true; //true show the label on top of the graph
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    //this.chartData
    //{ data: this.salesData, label: this.timeType }
    //{ data: [208, 480, 405, 1009, 860, 207, 908], label: "Monthly" }
  ];

  //Function to load grapch with different times from HTML
  changeGraph(reportType) {
    this.loadGraph(reportType);
  }

  /* ==== Export to Excel Functionality Start ==== */
  fileName = "Report.xlsx";

  exportExcel(): void {
    /* table id is passed over here */

    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  /*@Input("appExport") customers: any[];
  @Input() fileName: string;

  @HostListener("click", ["$event"]) onClick($event) {
    console.log("clicked: " + $event);
    this.expSer.exportExcel(this.customers, this.fileName);
  }*/
  /* ==== Export to Excel Functionality End ==== */
}
