import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-all-happy-hours",
  templateUrl: "./all-happy-hours.component.html",
  styleUrls: ["./all-happy-hours.component.css"]
})
export class AllHappyHoursComponent implements OnInit {
  //Varbiable Declation
  offerData = [];
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  offerImgPath = this.cs.offersPath();
  tempPath = this.cs.tempPath();
  weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  showBackButton = false;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;
  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;
  barId: any;
  restaurantName: any;

  //Injecting the Services
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {


    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.barId = id;
        // this.loadTicketBooking(id, "");
        // this.loadVisits(id, "");
        // this.loadDiscounts();
      } else {
      }
    });
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      appliedOnDay: [""],
      appliedOn: [""],
      status: [""],
      city: [localStorage.getItem("activeCity")],
      country: [localStorage.getItem("activeCountryId")]
    });

    //Controll load all data and specific bar data
    let pickBarName = localStorage.getItem("activeBarName");
    if (pickBarName == "" || pickBarName == undefined) {
    } else {
      this.showBackButton = true;
    }

    // Load all cities
    this.cities = this.myapp.allCities;

    //Call the loadHappyHours function
    this.loadHappyHours();
  }

  //Load Discounts defination
  loadHappyHours() {
    const barData = new FormData();
    // barData.append("barId", localStorage.getItem("barId"));
    barData.append("barId", this.barId);
    barData.append("offerType", "happyhour");
    barData.append("appliedOnDay", this.filterForm.get("appliedOnDay").value);
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("appliedOn", this.filterForm.get("appliedOn").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("countryId", this.filterForm.get("country").value);
    barData.append("userId", window.localStorage.getItem("userId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getAllOffersListing").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.offerData = response.data;
          this.restaurantName = response.restaurantName;
        } else {
          this.offerData = [];
          this.restaurantName = response.restaurantName;
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", this.barId);
    barData.append("offerType", "happyhour");
    barData.append("appliedOnDay", this.filterForm.get("appliedOnDay").value);
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("appliedOn", this.filterForm.get("appliedOn").value);
    barData.append("status", this.filterForm.get("status").value);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("countryId", this.filterForm.get("country").value);
    barData.append("page", pageNo);
    barData.append("userId", window.localStorage.getItem("userId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getAllOffersListing").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.offerData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    /*let keyword = this.filterForm.get("keyword").value;
    let appliedOnDay = this.filterForm.get("appliedOnDay").value;
    let appliedOn = this.filterForm.get("appliedOn").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;*/
    this.loadHappyHours();
  }

  //On Applied/Status filter
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    /*let keyword = this.filterForm.get("keyword").value;
    let appliedOnDay = this.filterForm.get("appliedOnDay").value;
    let appliedOn = this.filterForm.get("appliedOn").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;*/
    this.loadHappyHours();
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange();
  }

  // Function to filer Cities - for Bar
  filterByCountry(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.countriesSorted = false;
    } else {
      let keyword = this.filterForm.get("country").value;
      if (keyword.length > 0) {
        let output = [];
        this.countries.forEach(function(countries) {
          if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(countries);
          }
        });
        if (output.length > 0) {
          this.sortedCountries = output;
          this.countriesSorted = true;
        } else {
          this.sortedCountries = [];
          this.countriesSorted = false;
        }
      } else {
        this.sortedCountries = [];
        this.countriesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBoxCountry(str) {
    this.filterForm.patchValue({
      country: str
    });
    this.sortedCountries = [];
    this.countriesSorted = false;
    this.onKeyWordChange();
  }

  //Function to Active/Inactive records
  changeStatus(id, value, index) {
    this.myapp.changeStatus("bnOfferDetails", id, value);

    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.offerData[index].status = true;
    else if (value == 0) this.offerData[index].status = false;
  }

  //Function to Delete records
  deleteRecord(id, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        this.myapp.deleteRecord("bnOfferDetails", id);
        //based on the index remove the values from loaded array
        this.offerData.splice(index, 1);
      }
    });
  }

  //Function to return time in am/pm format on HTML
  convertTime(startTime, endTime) {
    return (
      this.cs.convertTime(startTime) + " - " + this.cs.convertTime(endTime)
    );
  }

  //Function to load default data when bar is unselected
  backToShowAll() {
    console.log("Back to show all data is click");
    localStorage.removeItem("barId");
    localStorage.removeItem("activeBarId");
    localStorage.removeItem("activeBarName");
    this.myapp.pickBarName = "Pick a Restaurant";
    this.showBackButton = false;
    this.loadHappyHours();
  }
}
