import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";

@Component({
  selector: 'app-add-bar-table',
  templateUrl: './add-bar-table.component.html',
  styleUrls: ['./add-bar-table.component.css']
})
export class AddBarTableComponent implements OnInit {

  tableForm: FormGroup;
  actionType = "add";
  pageTitle: string;
  zoneData = [];
  dynamicRoute: any = "";
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    // this.cs.isAdminRoute();
    this.loadZones();
    //Profile Form Model Declration with form validation for the fields
    this.tableForm = this.fb.group({
      id: [""],
      tableName: [
        "",
        [
          Validators.required
        ]
      ],
      status: ["", [Validators.required]],
      zoneId: ["", [Validators.required]],
      capacity:[""],
    });
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadStaffData(id);
        this.actionType = "edit";
        this.pageTitle = "Edit Restaurant Table";
        this.dynamicRoute = "createBarTable";
        this.tableForm = this.fb.group({
          id: [""],
          tableName: [
            "",
            [
              Validators.required
            ]
          ],
          status: ["", [Validators.required]],
          zoneId: ["", [Validators.required]],
          capacity:[""],
        });
      } else {
        this.actionType = "add";
        this.pageTitle = "Add Restaurant Table";
        this.dynamicRoute = "createBarTable";
      }
    });
  }

   //Load Categories defination
   loadZones() {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(barData, "getBarZones").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.zoneData = response.data;
          console.log(this.zoneData);
          

         

        } else {
          
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Function to submit data
  onStaffSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;
    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("id", this.tableForm.get("id").value);
      formData.append("tableName", this.tableForm.get("tableName").value);
      formData.append("barId", localStorage.getItem("barId"));
      formData.append("status", this.tableForm.get("status").value);
      formData.append("capacity", this.tableForm.get("capacity").value);
      formData.append("zoneId", this.tableForm.get("zoneId").value);
      formData.append("loginLink", this.cs.siteUrl + "login");
      formData.append("userId", window.localStorage.getItem("userId"));
      formData.append("token", localStorage.getItem("token"));

      //Form submit by appi call
      this.bs.callAPI(formData, this.dynamicRoute).subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.router.navigate(["/view-tables"]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    }
  }
  loadStaffData(id) {
    const staffData = new FormData();
    staffData.append("id", id);
    staffData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(staffData, "editBarTable").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.tableForm.patchValue({
            id: response.data.id,
            tableName: response.data.tableName,
            capacity:response.data.capacity,
            status: response.data.status,
            
            zoneId: response.data.zoneId
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  resetForm() {
    this.tableForm.reset();
    //Patch the required data in Form Modal
    this.tableForm.patchValue({
      id: "",
      tableName: "",
      status: "",
      capacity:"",
    });
  }
  get tableName() {
    return this.tableForm.get("tableName");
  }
  get status() {
    return this.tableForm.get("status");
  }
  
  get zoneId() {
    return this.tableForm.get("zoneId");
  }

  get capacity(){
    return this.tableForm.get("capacity")

  }
  


}
