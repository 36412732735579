import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adminevent-manager-events',
  templateUrl: './adminevent-manager-events.component.html',
  styleUrls: ['./adminevent-manager-events.component.css']
})
export class AdmineventManagerEventsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
