import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { AcceptBookingDialogComponent } from "../../accept-booking-dialog/accept-booking-dialog.component";

@Component({
  selector: "app-all-table-bookings",
  templateUrl: "./all-table-bookings.component.html",
  styleUrls: ["./all-table-bookings.component.css"]
})
export class AllTableBookingsComponent implements OnInit {
  //Variable Declration
  bookingData = [];
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;
  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;
  barId: any;
  restaurantName: any;

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    //Call the Orders Function
    
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.barId = id;
        // this.loadTicketBooking(id, "");
        // this.loadVisits(id, "");
        // this.loadDiscounts();
      } else {
      }
    });
    //Define filter form
    this.filterForm = this.fb.group({
      keyword: [""],
      paymentStatus: [""],
      status: [""],
      city: [localStorage.getItem("activeCity")],
      country: [localStorage.getItem("activeCountryId")]
    });
    this.loadBookingData("", "", "");
    // Load all cities
    this.cities = this.myapp.allCities;    
    this.countries = this.myapp.allCountries;

  }
  //Load bookingData defination
  loadBookingData(keyword, status, paymentStatus) {
    const barData = new FormData();
    barData.append("barId", this.barId);
    barData.append("keyword", keyword);
    barData.append("status", status);
    barData.append("paymentStatus", paymentStatus);
    barData.append("city", this.filterForm.get("city").value);
    barData.append("countryId", this.filterForm.get("country").value);
    barData.append("userId", window.localStorage.getItem("userId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getAllTableBooking").subscribe(
      (response: any) => {
        if (response.status == 1) {
          console.log("table data: " + response.data);
          this.bookingData = response.data;
          this.restaurantName = response.restaurantName;
        } else {
          this.bookingData = [];
          this.restaurantName =response.restaurantName;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("barId", this.barId);
    userData.append("page", pageNo);
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("city", this.filterForm.get("city").value);
    userData.append("countryId", this.filterForm.get("country").value);
    userData.append("status", this.filterForm.get("status").value);
    userData.append(
      "paymentStatus",
      this.filterForm.get("paymentStatus").value
    );
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "getAllTableBooking").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.bookingData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    let status = this.filterForm.get("status").value;
    let paymentStatus = this.filterForm.get("paymentStatus").value;
    this.loadBookingData(keyword, status, paymentStatus);
  }
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    let status = this.filterForm.get("status").value;
    let paymentStatus = this.filterForm.get("paymentStatus").value;
    this.loadBookingData(keyword, status, paymentStatus);
  }

   // Function to filer Cities - for Bar
   filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange();
  }

  // Function to filer Cities - for Bar
  filterByCountry(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.countriesSorted = false;
    } else {
      let keyword = this.filterForm.get("country").value;
      if (keyword.length > 0) {
        let output = [];
        this.countries.forEach(function(countries) {
          if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(countries);
          }
        });
        if (output.length > 0) {
          this.sortedCountries = output;
          this.countriesSorted = true;
        } else {
          this.sortedCountries = [];
          this.countriesSorted = false;
        }
      } else {
        this.sortedCountries = [];
        this.countriesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBoxCountry(str) {
    this.filterForm.patchValue({
      country: str
    });
    this.sortedCountries = [];
    this.countriesSorted = false;
    this.onKeyWordChange();
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to return time in am/pm format on HTML
  convertTime(bookingTime) {
    return this.cs.convertTime(bookingTime);
  }
}
