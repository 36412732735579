import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
@Component({
  selector: "app-view-geo-tagged",
  templateUrl: "./view-geo-tagged.component.html",
  styleUrls: ["./view-geo-tagged.component.css"]
})
export class ViewGeoTaggedComponent implements OnInit {
  geoData = [];
  hideLoadMore = true;
  page = 1; //used for load more
  filterForm: FormGroup;
  //will continue from here

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    this.loadGeoItems("");

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""]
    });
  }
  //Load geo item defination
  loadGeoItems(keyword) {
    const userData = new FormData();
    userData.append("keyword", keyword);
    userData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(userData, "getAllGeoTagging").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.geoData = response.data;
        } else {
          this.geoData = [];
          // this.myapp.showErrorAlert = true;
          // this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("page", pageNo);
    userData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(userData, "getAllGeoTagging").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.geoData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    this.loadGeoItems(keyword);
  }
}
