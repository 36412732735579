import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../service/login.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";


@Component({
  selector: "app-restaurant-onboarding",
  templateUrl: "./restaurant-onboarding.component.html",
  styleUrls: ["./restaurant-onboarding.component.css"]
})
export class RestaurantOnboardingComponent implements OnInit {
  //Varialbe Declration
  barForm: FormGroup;
  error = false;
  success = false;
  response: string = "";

  forgotForm: FormGroup;
  invalidLogin: boolean = false;
  validForgot: boolean = false;
  invalidForgot: boolean = false;
  showForgotForm: boolean = false;
  showbarForm: boolean = true;
  isLoadingResults = false;
  forgotMessage = "";
  loginMessage = "";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ls: LoginService,
    private cs: CommonService,
    public myapp: AppComponent
  ) { }

  ngOnInit() {
    //form validation for the fields
    this.barForm = this.fb.group({
      barName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
          //Validators.pattern("^[a-zA-Z () &]+$")
        ]
      ],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
          )
        ]
      ],
      confPass: ["", [Validators.required]],
      terms: ["", [Validators.required]]
    });
  }

  //Login Form Submit funtion
  onSubmit() {
    this.error = false;
    this.success = false;
    //Validation checking
    if (this.barForm.invalid) {
      return;
    }
    else if (this.terms.value == false) {
      this.error = true;
      this.response = "Please accept the service agreement";
      return;
    }

    const formData = new FormData();
    formData.append("barName", this.barForm.get("barName").value);
    formData.append("email", this.barForm.get("email").value);
    formData.append("phone", this.barForm.get("phone").value);
    formData.append("password", this.barForm.get("password").value);
    formData.append("confPass", this.barForm.get("confPass").value);
    formData.append("loginLink", this.cs.siteUrl + "login");

    this.ls.register(formData).subscribe(response => {
      //debugger;
      if (response.status === 1) {
        this.success = true;
        this.response = response.message;
        this.barForm.reset();
      } else {
        this.error = true;
        this.response = response.message;
      }
    });
  }

  //Getor - To access the userName directly in the form in HTML
  get barName() {
    return this.barForm.get("barName");
  }
  get email() {
    return this.barForm.get("email");
  }
  get phone() {
    return this.barForm.get("phone");
  }
  get password() {
    return this.barForm.get("password");
  }
  get confPass() {
    return this.barForm.get("confPass");
  }
  get terms() {
    return this.barForm.get("terms");
  }
}
