import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-newsfeed-reporters",
  templateUrl: "./newsfeed-reporters.component.html",
  styleUrls: ["./newsfeed-reporters.component.css"]
})
export class NewsfeedReportersComponent implements OnInit {
  //Varbiable Declation
  userPicPath = this.cs.userProfilePic();
  mediaPath = this.cs.userNewsFeedPath();
  newsId: any = 0;
  reporters: any = [];
  newsfeed: any = [];
  newsMedia: any = [];
  showModal = true;
  hideimagesvideo = true;
  modalImage: any = "";
  modalVideo: any = "";
  constructor(
    private fb: FormBuilder,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get("id");
      if (id.length > 0) {
        this.newsId = id;
        this.loadReporters(id);
      }
    });
  }

  // Load News feed reporters
  loadReporters(newsId) {
    const postData = new FormData();
    postData.append("newsId", newsId);
    postData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(postData, "getNewsFeedReporters").subscribe(
      (response: any) => {
        console.log(response.data);
        if (response.status == 1) {
          this.newsfeed = response.data.newsfeed;
          this.newsMedia = response.data.newsMedia;
          this.reporters = response.data.reporters;
          console.log("Reporters" + this.newsfeed);
        } else {
          this.reporters = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Image Priview Function
  imageviewer(img) {
    this.modalImage = img;
    this.showModal = true;
  }

  //Video Priview Function
  videoviewer(video) {
    this.modalVideo = video;
    this.hideimagesvideo = false;
  }

  showimagevideo() {
    this.hideimagesvideo = true;
  }

  // Media Delete Function
  deleteMedia(id, mediaName, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.myapp.hideAlerts();
        const mediaData = new FormData();
        mediaData.append("id", id);
        mediaData.append("mediaName", mediaName);
        mediaData.append("token", localStorage.getItem("token"));
        this.bs
          .callAPI(mediaData, "deleteNewsFeedMedia")
          .subscribe(response => {
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              //Delete the key from array
              this.newsMedia.splice(index, 1);
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
            }
          });
      }
    });
    return false;
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }
}
