import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-tip-history",
  templateUrl: "./tip-history.component.html",
  styleUrls: ["./tip-history.component.css"]
})
export class TipHistoryComponent implements OnInit {
  //Variable Declration
  orderData = [];
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  tips: any = [];
  fromDate: any = "";
  toDate: any = "";

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the Orders Function
    this.loadOrders("", "", ""); //three empty arguments, bcz this function is using in filters aslo

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      from: [""],
      to: [""]
    });
  }

  //Load Orders defination
  loadOrders(keyword, from, to) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("from", from);
    barData.append("to", to);
    barData.append("keyword", keyword);
    barData.append("tip", "1");
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getOrdersList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.orderData = response.data;
          this.tips = response.tips;
          //console.log(this.orderData);
        } else {
          this.orderData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("from", this.fromDate);
    barData.append("to", this.toDate);
    barData.append("tip", "1");
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getOrdersList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.orderData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let from = this.fromDate;
    let to = this.toDate;
    this.loadOrders(keyword, from, to);
  }

  //On Keyword filter
  filterOnDates() {
    let keyword = this.filterForm.get("keyword").value;
    let from = this.filterForm.get("from").value;
    let to = this.filterForm.get("to").value;
    if (from == undefined || from == null || from == "") {
      this.fromDate = "";
    } else {
      if (typeof from === "object") {
        this.fromDate = this.cs.stringToDateFun(from);
      }
    }

    if (to == undefined || to == null || to == "") {
      this.toDate = "";
    } else {
      if (typeof to === "object") {
        this.toDate = this.cs.stringToDateFun(to);
      }
    }

    this.loadOrders(keyword, this.fromDate, this.toDate);
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }
}
