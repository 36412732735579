import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  Input
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import  'jspdf-autotable';

@Component({
  selector: "app-discount-sales-report",
  templateUrl: "./discount-sales-report.component.html",
  styleUrls: ["./discount-sales-report.component.css"]
})
export class DiscountSalesReportComponent implements OnInit {
    

  discountDisable = false;
  happyHourDisable = false;
  

  public timeType: string = "today";
  public reportData: any = [];
  public userType: string = localStorage.getItem("userType");
  public barList: any = [];

  public toDisabled = false;
  public cards = {
    totalSales: 0,
    totalAdminCommission: 0,
    totalPaymentsGatewayAmt: 0,
    totalBarAmount: 0
  };
  public discounts: any = [];
  public happyHours: any = [];
  filterForm: FormGroup;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;

  myControl = new FormControl();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    /*this.filterForm = this.fb.group({
      discountId: [""],
      happyHourId: [""],
      from: [""],
      to: [""]
    });*/

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      from: [""],
      to: [""],
      keyword: [""],
      barName: [""],
      barList: [],
      city: [localStorage.getItem("activeCity")],
      offerType: ["both"]
    });

    this.cities = this.myapp.allCities;
    this.loadBarLIst();

    //this.loadDiscounts();
    this.loadReport(this.timeType);
  }

  loadBarLIst(){
    // BarListIn
    this.bs.callAPI("", "BarListIn").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barList = response.data;
         
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load the discounts and happy hours
  /*loadDiscounts() {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("offerType", "discount");

    this.bs.callAPI(barData, "getDiscountsHappyHours").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.discounts = response.data.discounts;
          this.happyHours = response.data.happyhours;
        }
      },
      error => {
        console.log(error);
      }
    );
  }*/

  /*setDiscountFilter() {
    this.happyHourDisable = true;
    this.discountDisable = false;
    this.filterForm.get("happyHourId").setValue("");
  }

  setHappyHourFilter() {
    this.discountDisable = true;
    this.happyHourDisable = false;
    this.filterForm.get("discountId").setValue("");
  }*/

  // Function to load reports
  loadReport(reportType) {
    const param = new FormData();
    param.append("barId", localStorage.getItem("barId"));
    param.append("userType", localStorage.getItem("userType"));
    param.append("from", "");
    param.append("to", "");
    param.append("offerType", this.filterForm.get("offerType").value);
    param.append("time", reportType);
    param.append("userType", localStorage.getItem("userType"));
    param.append("city", this.filterForm.get("city").value);
    param.append("barName", this.filterForm.get("barName").value);
    param.append("token", localStorage.getItem("token"));
    //param.append("discountId", this.filterForm.get("discountId").value);
    //param.append("happyHourId", this.filterForm.get("happyHourId").value);

    this.bs.callAPI(param, "getDiscountsReport").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.reportData = response.data.report;
          this.cards = response.data.cards;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to change report time
  changeReportType(time) {
    if (time == "today") {
      this.filterForm.get("to").setValue("");
      this.filterForm.get("from").setValue("");
      this.timeType = time;
      this.loadReport(time);
    } else {
      this.timeType = time;
      this.reportData = [];
      this.cards = {
        totalSales: 0,
        totalAdminCommission: 0,
        totalPaymentsGatewayAmt: 0,
        totalBarAmount: 0
      };
      this.calculateEndDate();
    }
  }

  //Function to calculate end date
  calculateEndDate() {
    this.toDisabled = true;
    let from = new Date(this.filterForm.get("from").value);
    var year = from.getFullYear();
    var month = from.getMonth();
    var date = from.getDate();
    var hour = from.getHours();
    var newDate;

    if (this.timeType == "yearly")
      newDate = new Date(year + 1, month, date - 1, hour);
    else if (this.timeType == "monthly") {
      newDate = new Date(year, month + 1, date - 1, hour);
    } else if (this.timeType == "weekly") {
      newDate = new Date(year, month, date + 6, hour);
    } else {
      this.toDisabled = false;
    }
    this.filterForm.get("to").setValue(new Date(newDate));
  }

  // Function to filter reports
  filterReport(email) {
    let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
    let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

    const param = new FormData();
    param.append("barId", localStorage.getItem("barId"));
    param.append("from", from);
    param.append("to", to);
    param.append("offerType", this.filterForm.get("offerType").value);
    param.append("userType", localStorage.getItem("userType"));
    param.append("city", this.filterForm.get("city").value);
    param.append("barName", this.filterForm.get("barName").value);
    param.append("token", localStorage.getItem("token"));
    //param.append("discountId", this.filterForm.get("discountId").value);
    //param.append("happyHourId", this.filterForm.get("happyHourId").value);
    param.append("time", this.timeType);
    if (email == "1") param.append("email", email);

    this.bs.callAPI(param, "getDiscountsReport").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.reportData = response.data.report;
          this.cards = response.data.cards;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  sendEmailReport() {
    this.filterReport("1");

    this.myapp.showSuccessAlert = true;
    this.myapp.successMsg = "Report has been sent on email";
  }
  sendPDFReport() {
    // var dataToExport = [
    //   { id: 1, name: 'John', age: 30 },
    //   { id: 2, name: 'Jane', age: 25 },
    //   { id: 3, name: 'Doe', age: 40 }
    // ];
    var dataToExport =this.reportData;

    var headers = ['key', 'title', 'admin_total_amt','commission_rate','admin_gst_rate','tip_amount','bar_amount','total','bar_amount'];

    // Create new jsPDF instance
    const doc = new jsPDF();

    // Add a title
    doc.text('Discounts Report', 10, 10);

    // Convert the data to an array of arrays
    const tableData = dataToExport.map(row => headers.map(header => row[header]));

    // Use jspdf-autotable to generate the table
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
    });

    // Save the PDF
    doc.save('Discounts_Report.pdf');
  }
  sendExcelReport(){
   
  //   var data = [
  //     { id: 1, name: 'John', age: 30 },
  //     { id: 2, name: 'Jane', age: 25 },
  //     { id: 3, name: 'Doe', age: 40 }
  // ];
  var data =this.reportData;
  var filename ="Discounts_Report";
  var sheetName ='ll';
  var headers = ['date', 'orderId', 'barName','total','bar_amount'];
  
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {header: headers});
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  
  // Convert the workbook to an array buffer
  const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  
  // Create a blob object from the buffer
  const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
  
  // Create a download link
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename + '.xlsx';
  
  // Trigger the download
  link.click();
  
  // Cleanup
  URL.revokeObjectURL(url);

  }
  //On Keyword filter
  onKeyWordChange(keyword) {
    // this.hideLoadMore = true;
    // this.page = 1;
    // let status = this.filterForm.get("status").value;
    
    let city = this.filterForm.get("city").value;
   // console.log(city);
    
    this.loadReport("");
  }

  // //On Applied/Status filter
  onDropDownChange() {
   // this.hideLoadMore = true;
    //this.page = 1;
   let reportType = this.timeType;
    //let status = this.filterForm.get("status").value;
    //let city = this.filterForm.get("city").value;
    this.filterReport("0");
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
   // console.log('lll');
    
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange('');
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange('');
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange('');
  }

}
