import { Component, OnInit, Renderer2 } from "@angular/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl
} from "@angular/platform-browser";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { ViewChild, ElementRef, NgZone } from "@angular/core";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-bar-profile",
  templateUrl: "./bar-profile.component.html",
  styleUrls: ["./bar-profile.component.css"]
})
export class BarProfileComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  //Varialbe Declration
  mediaForm: FormGroup;
  passwordForm: FormGroup;
  reviewReplyForm: FormGroup;

  roleId: any = 0;
  profile = [];
  media = [];
  review = [];
  other = [];
  hours = [];
  items = [];
  logoPath = this.cs.barLogoPath();
  mediaPath = this.cs.barMediaPath();
  userPicPath = this.cs.userProfilePic();
  videoIconPath = this.cs.siteUrl + "assets/img/video-icon.png";
  barMedia = null;
  timing = null;
  mimeType = null;
  FileErrorMessage = false;
  page = 1; //Used in Pagination
  itemPage = 1; //Used in items Pagination
  hideMediaLoadMore = true;
  hideReviewLoadMore = true;
  hide = null;
  showModal = true;
  barStatus: number;
  barUserId: number;
  barId: any = null;
  activeParam: any = false;
  discountOfferStatus:"";
  is_texes_status:"";
  imgpath = "";
  videopath = "";
  hideimagesvideo = true;
  pendingItems: any = [];
  chooseFileLabel = "Choose a file..";
  croppedImage: any = "";
  imageChangedEvent: any = "";
  base64Img: any = "";
  bannerImageSave: any = null;
  imageSave = false;
  reviewImage = null;
  reviewReplyIndex = null;
  itemsCount="";
  public messages = [];
  public emailMessages = [];
  public bmType = "push";
  // videoUrl;
  // videoSizeError;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.roleId = localStorage.getItem("roleId");
    this.barId = localStorage.getItem("barId");
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.barId = id;
        this.activeParam = true;
      }
    });

    this.loadBarProfile(); //Call the Bar Profile

    //mediaForm Form Model Declration with form validation for the fields
    this.mediaForm = this.fb.group({
      barId: [this.barId],
      mediaName: ["", Validators.required],
      mediaType: [""]
    });

    this.reviewReplyForm = this.fb.group({
      id: [0],
      barReply: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ]
    });

    // custom validator to check that two fields match
    function MustMatch(controlName: string, matchingControlName: string) {
      return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
        } else {
          matchingControl.setErrors(null);
        }
      };
    }

    //passwordForm Form Model Declration with form validation for the fields
    this.passwordForm = this.fb.group(
      {
        barId: [this.barId],
        token: [localStorage.getItem("token")],
        oldPass: ["", [Validators.required]],
        newPass: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
            )
          ]
        ],
        confPass: ["", [Validators.required]]
      },
      {
        validator: MustMatch("newPass", "confPass")
      }
    );
  }

  //Load Bar Profile Defination
  loadBarProfile() {
    //this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("id", this.barId);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getBarProfileWeb").subscribe(
      (response: any) => {
        if (response.status == 1) {
          console.log(response);
          
          this.profile = response.data.profile;
          this.hours = response.data.hours;
          this.media = response.data.media;
          this.review = response.data.review;
          this.items = response.data.items;
          this.itemsCount =response.data.items_count;
          this.other = response.data.other;
          this.messages = response.data.messages;
          this.emailMessages = response.data.emailMessages;
          this.barStatus = response.data.profile.barStatus;
          this.barUserId = response.data.profile.userId;
          this.discountOfferStatus = response.data.profile.discountOfferStatus;
          this.is_texes_status = response.data.profile.is_texes_status;
          this.timing =
            this.cs.convertTime(this.hours["openingTime"]) +
            " - " +
            this.cs.convertTime(this.hours["closingTime"]);

          /*this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;*/
        } else {
          //this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load More Data Function
  loadMoreData(loadmore, pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("id", this.barId);
    barData.append("loadmore", loadmore);
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getBarProfileWeb").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          if (loadmore == "media") {
            for (let i = 0; i < response.data.length; i++) {
              this.media.push(response.data[i]);
            }

            //Hide the load more button if reaches ot limit
            if (response.limit != response.data.length)
              this.hideMediaLoadMore = false;
          } else if (loadmore == "review") {
            for (let i = 0; i < response.data.length; i++) {
              this.review.push(response.data[i]);
            }

            //Hide the load more button if reaches ot limit
            if (response.limit != response.data.length)
              this.hideReviewLoadMore = false;
          }
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Items Function
  loadMoreItems(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", this.barId);
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getMenu").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.itemPage++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.items.push(response.data[i]);
          }
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // Media image crop
  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.barMedia = event.base64;
    this.mediaForm.get("mediaName").setValue(event.base64);
    this.base64Img = "ok";
  }
  hideCropImg() {
    this.imageSave = false;
  }
  /*skipCropImg() {
    this.imageSave = false;
    this.mediaForm.get("mediaName").setValue(this.bannerImageSave);
  }*/

  //on File select, put the selected file in Profile Form model variable
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let docName = file.name;
      let docSize = file.size; //50000000 Byes = 50 MB
      // var mimeType = file[0].type;
      //Get the mime type to validate file uploading
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      console.log("docSize" + docSize);
      if (
        (fileType === "image" || fileType === "video") &&
        docSize <= 50000000
      ) {
        this.chooseFileLabel = "Choose a file..";
        if (fileType == "video") {
          this.chooseFileLabel = docName;
          this.imageSave = false;
          this.base64Img = "";
          this.barMedia = this.videoIconPath;
          this.mediaForm.get("mediaType").setValue("2");
        }

        // console.log("File Name " + file.type);
        this.FileErrorMessage = false;
        this.mediaForm.get("mediaName").setValue(file);

        if (fileType === "image") {
          this.imageSave = true;
          this.imageChangedEvent = event;
          this.bannerImageSave = this.mediaForm.get("mediaName").setValue(file);
          this.mediaForm.get("mediaName").setValue(file);
          this.mediaForm.get("mediaType").setValue("1");
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.barMedia = reader.result;
          };
        }

        // if (fileType === "video") {
        //   //const files = event.target.files;
        //   const duration = event.target.video.duration;
        //   console.log("duration"+ duration);
        //   this.videoSizeError = duration > 30;
        //   if (file && file[0]) {
        //     this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file[0]));
        //     console.log("url"+ this.videoUrl);
        //   }
        // }

        return;
      } else {
        this.FileErrorMessage = true;
      }
    }
  }

  //Media Form Submission
  onMediaSubmit() {
    this.myapp.hideAlerts();
    this.imageSave = false;
    this.myapp.isLoadingResults = true;
    const bannerData = new FormData();
    bannerData.append("barId", this.mediaForm.get("barId").value);
    bannerData.append("mediaName", this.mediaForm.get("mediaName").value);
    bannerData.append("mediaType", this.mediaForm.get("mediaType").value); // 1 = Images, 2 = Video
    bannerData.append("token", localStorage.getItem("token"));
    if (this.base64Img == "ok") bannerData.append("base64Img", this.base64Img);

    this.bs
      .callAPI(bannerData, "addBarMedia") //this.profileForm.value
      .subscribe(response => {
        if (response.status === 1) {
          this.chooseFileLabel = "Choose a file..";
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.barMedia = false;
          //Add the latest added image in Array
          this.media.unshift(response.data);
          //Unset the Media name field
          this.mediaForm.patchValue({
            mediaName: ""
          });
          this.ngOnInit();

          //Close modal
          /*this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);*/
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  //Media Delete Function
  deleteMedia(type, id, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.myapp.hideAlerts();
        const mediaData = new FormData();
        mediaData.append("id", id);
        mediaData.append("type", type);
        mediaData.append("token", localStorage.getItem("token"));
        this.bs.callAPI(mediaData, "deleteBarMedia").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            //Delete the key from array
            this.media.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
      }
    });
    return false;
  }

  //Image Priview Function
  imageviewer(name) {
    this.imgpath = name;
  }

  loadReviewImage(img) {
    this.reviewImage = img;
  }

  showimagevideo() {
    this.hideimagesvideo = true;
  }

  //Video Priview Function
  videoviewer(name) {
    this.videopath = name;
    console.log(name);
    this.hideimagesvideo = false;
  }

  //Change Password Form Submission
  onPasswordSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("barId", this.passwordForm.get("barId").value);
    formData.append("oldPass", this.oldPass.value);
    formData.append("newPass", this.newPass.value);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "barChangePassword").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.passwordForm.reset();
        //Set the BAR ID again so form will work in a loop
        this.passwordForm.patchValue({
          barId: [this.barId]
        });

        //Update password in remember me
        if (localStorage.getItem("loginPassword")) {
          window.localStorage.setItem("loginPassword", this.newPass.value);
        }
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Review Reply Form Submission
  onReplySubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("barId", this.barId);
    formData.append("id", this.reviewReplyForm.get("id").value);
    formData.append("barReply", this.barReply.value);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "submitReviewReply").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.review[this.reviewReplyIndex].barReply = this.barReply.value;
        this.reviewReplyIndex = null;
        this.reviewReplyForm.reset();
        this.reviewReplyForm.patchValue({
          id: 0,
          barReply: ""
        });

        // Hide the Modal
        let btn: HTMLElement = document.getElementById(
          "review-close-btn"
        ) as HTMLElement;
        btn.click();
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  loadModal() {
    this.showModal = true;
  }

  closeMediaModal() {
    this.barMedia = null;
    this.chooseFileLabel = "Choose a file..";
    this.mediaForm.get("mediaName").setValue("");
    this.base64Img = "";
    this.imageSave = false;
  }

  setReplyId(id, i) {
    this.reviewReplyForm.get("id").setValue(id);
    this.reviewReplyIndex = i;
  }

  //Getor - To access the fields directly in the form in HTML
  get oldPass() {
    return this.passwordForm.get("oldPass");
  }
  get newPass() {
    return this.passwordForm.get("newPass");
  }
  get confPass() {
    return this.passwordForm.get("confPass");
  }

  //Getor - To access the fields directly in the form in HTML
  get mediaName() {
    return this.mediaForm.get("mediaName");
  }

  get barReply() {
    return this.reviewReplyForm.get("barReply");
  }

  //Function to Active/Inactive records
  /*changeStatus(id, value) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you have completed the profile?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.changeStatus("bnUsers", id, value);
        this.barStatus = value;
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = "Activation request sent successfully.";
      }
    });
  }*/
 

  //Function to Activate Restaurant
  activateRestaurant(barUserId, barId) {
    this.myapp.changeStatus("bnUsers", barUserId, 1);
    this.myapp.showSuccessAlert = true;
    this.myapp.successMsg = "Restaurant has been successfully activated.";
    this.barStatus = 1;

    // Hide the Modal
    this.showModal = false;
    let closeModal: HTMLElement = document.getElementsByClassName(
      "g-sidenav-pinned"
    )[0] as HTMLElement;
    closeModal.classList.remove("modal-open");
    let closeModalpadding: HTMLElement = document.getElementsByClassName(
      "g-sidenav-pinned"
    )[0] as HTMLElement;
    closeModalpadding.style.padding = "0px";
    let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
      "modal-backdrop"
    )[0] as HTMLElement;
    ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
  }

  changeStatus(id, barId) {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    const postData = new FormData();
    postData.append("userId", id);
    postData.append("barId", barId);
    postData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(postData, "acceptBarRequest").subscribe(response => {
      console.log(response);
      
      if (response.status === 1) {
        this.barStatus = 4;
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = "Activation request sent successfully.";
        this.myapp.isLoadingResults = false;
        //this.ngOnInit();

        // Hide the Modal
        this.showModal = false;
        let closeModal: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModal.classList.remove("modal-open");
        let closeModalpadding: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModalpadding.style.padding = "0px";
        let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
          "modal-backdrop"
        )[0] as HTMLElement;
        ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
      } else {
        //this.myapp.showErrorAlert = true;
        //this.myapp.errorMsg = response.message;
        this.pendingItems = response.data;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  changeBmType(type) {
    this.bmType = type;
  }
  changeDayStatus(x,y) {
    //console.log(x);
    let value: any = 0;
    let alertMsg = "Are you sure? you want to enable discount.";
    
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: alertMsg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;

        const barData = new FormData();
        barData.append("barId", x);
        barData.append("discount_type", y);
        barData.append("token", localStorage.getItem("token"));
        // barData.append("dayOnOffStatus", value);

        this.bs.callAPI(barData, "updateBarGlobalDiscounteWeb").subscribe(
          (response: any) => {
            console.log(response);
            
            if (response.status == 1) {
              //console.log(response);
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              this.ngOnInit();
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
              // this.dayOnOffStatus = true;
              //this.openingStatus = true;

              //this.dayOnOffStatus = false;
              //this.openingStatus = false;
            }
          },
          error => {
            console.log(error);
          }
        );
      } else {
        
      }
    });
    
  }
// Disabel Enable Tax At admin
  changeTaxStatus(x,y) {
    //console.log(x);
    let value: any = 0;
    let alertMsg = "Are you sure? you want to enable Tax.";
    
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: alertMsg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;

        const barData = new FormData();
        barData.append("barId", x);
        barData.append("tax_type", y);
        barData.append("token", localStorage.getItem("token"));
        // barData.append("dayOnOffStatus", value);

        this.bs.callAPI(barData, "updateBarTaxWeb").subscribe(
          (response: any) => {
            console.log(response);
            
            if (response.status == 1) {
              //console.log(response);
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              this.ngOnInit();
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
              // this.dayOnOffStatus = true;
              //this.openingStatus = true;

              //this.dayOnOffStatus = false;
              //this.openingStatus = false;
            }
          },
          error => {
            console.log(error);
          }
        );
      } else {
        
      }
    });
    
  }


  //Dispatch Enable Disable
  changeDispatchStatus(x,y) {
    //console.log(x);
    let value: any = 0;
    let alertMsg = "Are you sure? you want to enable Dispatch.";
    
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: alertMsg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;

        const barData = new FormData();
        barData.append("barId", x);
        barData.append("dispatch_status", y);
        barData.append("token", localStorage.getItem("token"));
        // barData.append("dayOnOffStatus", value);

        this.bs.callAPI(barData, "updateBarDispatchWeb").subscribe(
          (response: any) => {
            console.log(response);
            
            if (response.status == 1) {
              //console.log(response);
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              this.ngOnInit();
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
              // this.dayOnOffStatus = true;
              //this.openingStatus = true;

              //this.dayOnOffStatus = false;
              //this.openingStatus = false;
            }
          },
          error => {
            console.log(error);
          }
        );
      } else {
        
      }
    });
    
  }
}
