import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-broadcast",
  templateUrl: "./broadcast.component.html",
  styleUrls: ["./broadcast.component.css"]
})
export class BroadcastComponent implements OnInit {
  broadForm: FormGroup;
  custMessages: any = [];
  barMessages: any = [];
  custEmailMessages: any = [];
  barEmailMessages: any = [];

  bmType = "push";
  recordType = "customers";
  page = 1; //used for load more
  barPage = 1;
  hideCustLoadMore = true;
  hideBarLoadMore = true;

  public searchElementRef: ElementRef;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    if (window.localStorage.getItem("roleId") != "5") this.cs.isAdminRoute();

    //Form Model
    this.broadForm = this.fb.group({
      messageType: ["", [Validators.required]],
      userType: ["", [Validators.required]],
      subject: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      message: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ],
      emailMsg: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ]
    });

    this.getAllMessages();
  }

  // Function to get all messages
  getAllMessages() {
    const formData = new FormData();
    this.bs.callAPI(formData, "getBroadcastMessages").subscribe(
      (res: any) => {
        if (res.status == 1) {
          this.custMessages = res.data.custMessages;
          this.barMessages = res.data.barMessages;
          this.custEmailMessages = res.data.custEmailMessages;
          this.barEmailMessages = res.data.barEmailMessages;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function - Customers
  loadMoreCustData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("page", pageNo);
    barData.append("bmType", this.bmType);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getCustomerBroadcastMessages").subscribe(
      (res: any) => {
        if (res.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < res.data.length; i++) {
            this.bmType == "push"
              ? this.custMessages.push(res.data[i])
              : this.custEmailMessages.push(res.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (res.limit != res.data.length) this.hideCustLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = res.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function - Bars
  loadMoreBarData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("page", pageNo);
    barData.append("bmType", this.bmType);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getRestaurantBroadcastMessages").subscribe(
      (res: any) => {
        if (res.status == 1) {
          this.barPage++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < res.data.length; i++) {
            this.bmType == "push"
              ? this.barMessages.push(res.data[i])
              : this.barEmailMessages.push(res.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (res.limit != res.data.length) this.hideBarLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = res.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  changeRecordType(type) {
    this.recordType = type;
    this.hideCustLoadMore = true;
    this.hideBarLoadMore = true;
  }

  changeBmType(type) {
    this.bmType = type;
    this.hideCustLoadMore = true;
    this.hideBarLoadMore = true;
  }

  changeMessageType() {
    if (this.messageType.value == "1") {
      this.message.setValue("");
      this.emailMsg.setValue("Test");
    } else if (this.messageType.value == "2") {
      this.message.setValue("Test");
      this.emailMsg.setValue("");
    } else {
      this.message.setValue("");
      this.emailMsg.setValue("");
    }
  }

  //Function to submit data
  onFormSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    const formData = new FormData();
    formData.append("messageType", this.messageType.value);
    formData.append("userType", this.userType.value);
    formData.append("subject", this.subject.value);
    formData.append("message", this.message.value);
    formData.append("emailMsg", this.emailMsg.value);
    formData.append("token", localStorage.getItem("token"));

    //Form submit by appi call
    this.bs.callAPI(formData, "sendBroadcastMessage").subscribe(res => {
      if (res.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = res.message;
        this.myapp.isLoadingResults = false;
        this.broadForm.reset();
        this.userType.setValue("");
        this.messageType.setValue("");
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = res.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get messageType() {
    return this.broadForm.get("messageType");
  }
  get userType() {
    return this.broadForm.get("userType");
  }
  get subject() {
    return this.broadForm.get("subject");
  }
  get message() {
    return this.broadForm.get("message");
  }
  get emailMsg() {
    return this.broadForm.get("emailMsg");
  }
}
