import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
@Component({
  selector: 'app-upload-menu',
  templateUrl: './upload-menu.component.html',
  styleUrls: ['./upload-menu.component.css']
})
export class UploadMenuComponent implements OnInit {

  uploadMenu: FormGroup;
  itemImagePath = null;
  FileErrorMessageLog = false;
  submitted = false;
  public Available = true;
  public NotAvailable = false;
  showCancelBtn = false;
  PriceErrorMessage = false;
  showStockField = false;
  showFoodType = false;
  foodTypeError = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) { }

  ngOnInit() {
    //menu Form Model Declaration with form validation for the fields
    this.uploadMenu = this.fb.group({
      barId: [localStorage.getItem("barId")], 
      uploadMenuexcel: ["", [Validators.required]],
    });
  }

  //on File select, put the selected file in Profile Form model variable
   onFileSelect(event) {
     if (event.target.files.length > 0) {
       const file = event.target.files[0];
     // console.log(file);
      
       var fileMimes = file.type.split("/");
       var fileType = fileMimes[0];
       //console.log(fileType);

       if (fileType === "application") {
         this.FileErrorMessageLog = false;
         this.uploadMenu.get("uploadMenuexcel").setValue(file);
         //Preveiw of the image only on images
        //  var reader = new FileReader();
        //  reader.readAsDataURL(file);
        //  reader.onload = _event => {
        //    this.itemImagePath = reader.result;
        //  };
         return;
       } else {
         this.FileErrorMessageLog = true;
       }
     }
   }

  onMenuSubmit() { 
    this.myapp.hideAlerts();
    // this.myapp.isLoadingResults = true;

    const uploadMenuData = new FormData();
    uploadMenuData.append("barId", this.uploadMenu.get("barId").value);
    uploadMenuData.append("uploadMenuexcel", this.uploadMenu.get("uploadMenuexcel").value);
    uploadMenuData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(uploadMenuData, "categoryImport").subscribe(response => {
      //console.log(response.message);
      
      if (response.status === 1) {
         this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            this.FileErrorMessageLog = true;
        if (this.uploadMenu.get("barId").value) {
          this.router.navigate(["/upload-menu"]);
        } else {  
              this.uploadMenu.reset();
              this.uploadMenu.patchValue({
                barId: localStorage.getItem("barId"), 
                uploadMenuexcel: "", 
              }); 
        }
      } else {
        // this.myapp.showErrorAlert = true;
        this.FileErrorMessageLog = true;
       this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });

  }

  

   // Reset the Form via Cancel Button
   resetForm() {
    this.uploadMenu.reset();
    this.FileErrorMessageLog = false;
    //Patch the required data in Form Modal
    this.uploadMenu.patchValue({
      barId: [localStorage.getItem("barId")],
      uploadMenuexcel: null
    }); 
    
  }

  get uploadMenuexcel() {
    return this.uploadMenu.get("uploadMenuexcel");
  }
}


