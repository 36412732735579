import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-edit-kot",
  templateUrl: "./edit-kot.component.html",
  styleUrls: ["./edit-kot.component.css"]
})
export class EditKotComponent implements OnInit {
  //Varbiable Declation
  currencySymbol = localStorage.getItem("currency");
  kotData: any = [];
  kotNo = "";
  itemImgPath = this.cs.itemsPath();
  cartItems = new Array();

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get("id");
      if (id.length > 0) {
        this.loadKotItems(id);
        this.kotNo = id;
      }
    });
  }

  //loadKotItems defination
  loadKotItems(kotNo) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("kotNo", kotNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getKotDetail").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.kotData = response.data;
          //console.log(this.kotData);

          //Add the KOT items in Cart Array,
          for (let i = 0; i < this.kotData["items"].length; i++) {
            let cartData = {
              kotItemId: this.kotData["items"][i].id,
              itemId: this.kotData["items"][i].itemId,
              itemName: this.kotData["items"][i].itemName,
              itemImage: this.kotData["items"][i].itemImage,
              qty: this.kotData["items"][i].quantity,
              price: this.kotData["items"][i].amount
              //this.kotData["items"][i].quantity
            };
            this.cartItems.push(cartData);
          }
          console.log(this.cartItems);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to Increase qty of Item in Cart
  increaseQty(i: any) {
    let kotItemId = this.kotData["items"][i].id;
    let qty = this.kotData["items"][i].quantity;
    let price = this.kotData["items"][i].amount / qty;
    let cartData = {
      kotItemId: kotItemId,
      qty: qty,
      price: price
    };

    let index = -1;
    //First search itemId is exit or not in the CART
    for (var j = 0; j < this.cartItems.length; j++) {
      if (this.cartItems[j].kotItemId === kotItemId) {
        index = j;
        break;
      }
    }

    if (index < 0) {
      //Insert the Items in Cart
      //this.cartItems.push(cartData); //Push data in cart
    } else {
      //Update the Items in Cart
      this.cartItems[index].qty++;
      this.cartItems[index].price = price * this.cartItems[index].qty;
    }
    console.log(this.cartItems);
    console.log("total items: " + this.cartItems.length);
  }

  //Function to Decrease qty of Item in Cart
  decreaseQty(i: any) {
    let kotItemId = this.kotData["items"][i].id;
    let qty = this.kotData["items"][i].quantity;
    let price = this.kotData["items"][i].amount / qty;
    let index = -1;
    //First search itemId is exit or not in the CART
    for (var j = 0; j < this.cartItems.length; j++) {
      if (this.cartItems[j].kotItemId === kotItemId) {
        index = j;
        break;
      }
    }

    if (index >= 0) {
      //Update the Items in Cart
      if (this.cartItems[index].qty > 0) {
        this.cartItems[index].qty--;
        this.cartItems[index].price = price * this.cartItems[index].qty;
      }
    }
    //console.log(this.cartItems);
    //console.log("total items: " + this.cartItems.length);
  }

  //Function to go back to previous route
  goBack() {
    history.back();
    return false;
  }

  //Submit Funciton
  onKotSubmit() {
    //Validation checking
    if (this.cartItems.length > 0) {
      const itemData = new FormData();
      itemData.append("barId", localStorage.getItem("barId"));
      itemData.append("orderId", this.kotData["orderId"]);
      itemData.append("kotItems", JSON.stringify(this.cartItems));
      itemData.append("token", localStorage.getItem("token"));
      //will continue from here, after API fixes
      this.myapp.hideAlerts();
      this.myapp.isLoadingResults = true;
      this.bs.callAPI(itemData, "editKotItems").subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.cartItems = new Array();
          this.router.navigate(["/kot-list/" + this.kotData["uniqueId"]]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    } else {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please select an item";
      this.myapp.isLoadingResults = false;
    }
  }
}
