import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-offer-items",
  templateUrl: "./offer-items.component.html",
  styleUrls: ["./offer-items.component.css"]
})
export class OfferItemsComponent implements OnInit {
  //Varbiable Declation
  itemData = [];
  discountRate = 0;
  itemImgPath = this.cs.itemsPath();
  filterForm: FormGroup;
  itemForm: FormGroup;
  itemIdsExist = []; //used in Edit Offer
  redirectTo = "";

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call the Bar Items
    this.loadMenuItems("");

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      barId: [localStorage.getItem("barId")],
      keyword: [""]
    });

    //Define the Filter Form Model
    this.itemForm = this.fb.group({
      offerType: [""],
      offerId: [""],
      itemIds: new FormArray([])
    });

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let str = params.get("str");
      let id = params.get("id");
      if (str.length > 0 && id.length > 0) {
        if (str == "discount") this.redirectTo = "/view-discount";
        else this.redirectTo = "/view-happy-hour";

        this.loadOfferItems(str, id);
        //Patch the Item data in form modal
        this.itemForm.patchValue({
          offerType: str,
          offerId: id
        });
      }
    });
  }

  //Load Menu Items defination
  loadMenuItems(keyword) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getItemsList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.itemData = response.data;
          //console.log(this.itemData);
        } else {
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load Item Data in case of edit
  loadOfferItems(offerType, offerId) {
    const formData = new FormData();
    formData.append("offerType", offerType);
    formData.append("offerId", offerId);
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getOffersItems").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //Set the discount rate
          this.discountRate = response.discountRate;
          //Add the items
          for (let i = 0; i < response.data.length; i++) {
            const formArray: FormArray = this.itemForm.get(
              "itemIds"
            ) as FormArray;

            // Add a new control in the arrayForm
            formArray.push(new FormControl(response.data[i].itemId));
            this.itemIdsExist.push(response.data[i].itemId);
          }
        } else {
          //Set the discount rate
          this.discountRate = response.discountRate;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to find itemIds in existing Ids to show the checkbox checked while EDIT
  checkItemExist(itemId) {
    let data = this.itemIdsExist.indexOf(itemId);
    if (data >= 0) return true;
    else return false;
  }
  //Funciton to search items by keyword
  searchItems(keyword) {
    this.loadMenuItems(keyword);
  }

  //Funciton to select/deselect menu items on checkbox event
  onCheckChange(event) {
    const formArray: FormArray = this.itemForm.get("itemIds") as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  //Submit Funciton
  onOfferSubmit() {
    //console.log(this.menuForm.value);
    this.myapp.hideAlerts();
    const ids: FormArray = this.itemForm.get("itemIds") as FormArray;
    //Validation checking
    if (ids.length > 0) {
      this.myapp.isLoadingResults = true;
      this.bs
        .callAPI(this.itemForm.value, "addEditItems")
        .subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            this.router.navigate([this.redirectTo]);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
    } else {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please select an item";
      this.myapp.isLoadingResults = false;
    }
  }

  //Function to go back to previous route
  goBack() {
    history.back();
    return false;
  }

  // Function to calculate disounted price on HTML
  calculateDisountedPrice(price, discount) {
    return (price - (price / 100) * discount).toFixed(2);
  }
}
