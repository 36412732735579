import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, tap, map, retry } from "rxjs/operators";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root"
})
export class BarService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private cs: CommonService
  ) {}

  //Update Basic Profile Function
  callAPI(data, apiFunction) {
    let url: string = this.cs.baseUrl + apiFunction; //"editBarProfile";
    return this.http
      .post<any>(url, data)
      .pipe(catchError(this.cs.errorHandler));
  }

  /*loadCities(apiFunction): Observable<User[]> {
    let url: string = this.cs.baseUrl + "api/getEnrollment";
    return this.http.get<User[]>(url).pipe(
      retry(1),
      catchError(error => {
        return throwError(error.message);
      })
    );
  }*/
}
