import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.css"]
})
export class InvoiceComponent implements OnInit {
  //Varbiable Declation
  currencySymbol = '';
  paymentForm: FormGroup;
  bar: any = [];
  orderData: any = [];
  paymentData: any = [];
  giftItems: any = [];
  visit: any = [];
  orderId: string;
  visitId: any = 0;
  orderType = false; //Used to show/hide KOT button on HTML
  paymentModes: any = [];
  barId: any = 0;
  showUpdateBtn = true;
  showPaymentForm = false;
  tipAmount = 0;
  grandTotal = 0;
  userType:any =0;

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.barId = localStorage.getItem("barId");
    this.userType = localStorage.getItem("userType");
    if (this.userType == 'A') {
      // this.barId = localStorage.getItem("barId");

      this.route.paramMap.subscribe((params: ParamMap) => {
        this.orderId = params.get('id');
        this.barId = params.get('visitId');
        
        if (this.orderId && this.barId) {
          // Both orderId and barId are present in the URL
          this.loadOrderDetails();
          
        }
      });
    }else{
    //Access the Paramter from URL
        this.route.paramMap.subscribe((params: ParamMap) => {
          this.orderId = params.get("id");
          // let vId = params.get("visitId");
          // if (vId) this.visitId = vId;

          let bId = params.get("barId");
          if (bId) {
            this.barId = bId;
            this.showUpdateBtn = false;
          }

          if (this.orderId.length > 0) {
            this.loadOrderDetails();
          }
        });
    }
   
    

    this.paymentModes = this.myapp.paymentModes;

    //Payment form modal declaration
    this.paymentForm = this.fb.group({
      orderId: [this.orderId],
      paymentMode: ["", [Validators.required]],
      remark: [""],
      tip: [""]
    });
  }

  //Function to call Order Details
  loadOrderDetails() {
    const formData = new FormData();
    formData.append("barId", this.barId);
    formData.append("orderId", this.orderId);
    formData.append("token", localStorage.getItem("token"));

    if (this.visitId.length > 0) formData.append("visitId", this.visitId);

    this.bs.callAPI(formData, "getOrderInvoice").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.bar = response.data.bar;
          /*this.orderData = response.data;
          this.paymentData = response.paymentData;
          this.grandTotal = this.orderData.grandTotal;
          this.tipAmount = this.orderData.tipAmount;*/
          this.giftItems = response.data.giftItems;
          if (response.data.order) {
            this.orderData = response.data.order;
            this.currencySymbol = response.currenct;            
            this.paymentData = response.data.paymentData;
            this.grandTotal = this.orderData.grandTotal;
            this.tipAmount = this.orderData.tipAmount;

            if (this.orderData.orderType == "order") this.orderType = true;
            if (this.orderData.paymentStatus == 0) this.showPaymentForm = true;
          }

          if (response.data.visit) {
            this.visit = response.data.visit;
          }
          //will continue from here
        } else {
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to go back to previous route
  goBack() {
    history.back();
    return false;
  }

  addTip(event) {
    let val = parseFloat(event.target.value);
    if (val > 0) {
      this.tipAmount = val;
      this.orderData.grandTotal = (this.grandTotal + val).toFixed(2);
    } else {
      this.orderData.grandTotal = this.grandTotal.toFixed(2);
      this.tipAmount = 0;
    }
  }

  //Function to update the payment status
  onPaymentSubmit() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Do you want to perform this action?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        //this.myapp.changeStatus("bnOrdersPayment", this.orderData["id"], 1);
        //if (this.myapp.actionStatus)
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;

        const formData = new FormData();
        formData.append("orderId", this.orderId);
        formData.append("paymentMode", this.paymentMode.value);
        formData.append("remark", this.remark.value);
        formData.append("tip", this.tip.value);
        formData.append("token", localStorage.getItem("token"));

        this.bs
          .callAPI(formData, "updatePaymentStatus") //this.profileForm.value
          .subscribe(response => {
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              //this.paymentForm.reset();
              //this.ngOnInit();
              this.router.navigate(["/dashboard/"]);
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
            }
          });

        this.orderData["paymentStatus"] = 1;
        this.showPaymentForm = false;
      }
    });
  }

  // Function to update the order status - In case of Preorder
  onUpdateOrderSubmit() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure this order is completed?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        const postData = new FormData();
        postData.append("orderId", this.orderData["id"]);
        postData.append("token", localStorage.getItem("token"));

        this.bs.callAPI(postData, "changeOrderStatus").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg =
              "Order status has been updated successfully.";
            this.myapp.isLoadingResults = false;
            this.showUpdateBtn = false;
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });

        /*this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.myapp.changeStatus("bnOrders", this.orderData["id"], 3);
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = "Order status has been updated successfully.";
        this.myapp.isLoadingResults = false;
        this.showUpdateBtn = false;*/
      }
    });
  }

  calculateNetAmt(grandTotal, advance) {
    let amount: any = (grandTotal - advance).toFixed(2);
    return amount > 0 ? amount : 0;
  }

  // Gator Method
  get paymentMode() {
    return this.paymentForm.get("paymentMode");
  }
  get remark() {
    return this.paymentForm.get("remark");
  }
  get tip() {
    return this.paymentForm.get("tip");
  }

  printInvoice() {
    document.getElementById("printable").style.display = "block";
    window.print();
    document.getElementById("printable").style.display = "none";
  }
}
