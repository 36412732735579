import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { BarService } from "../service/bar.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "app-accept-booking-dialog",
  templateUrl: "./accept-booking-dialog.component.html",
  styleUrls: ["./accept-booking-dialog.component.css"]
})
export class AcceptBookingDialogComponent implements OnInit {
  //Variable Declration
  bookingForm: FormGroup;
  showSuccess = false;
  showError = false;
  sucMsg = "";
  errMsg = "";

  //Injecting Services
  constructor(
    public dialogRef: MatDialogRef<AcceptBookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private bs: BarService,
    private router: Router
  ) {}

  ngOnInit() {
    //Visit Form modal, in the popup
    this.bookingForm = this.fb.group({
      tableNo: [""]
    });
  }

  acceptBooking() {
    this.showError = false;
    this.errMsg = "";
    let tableNo = this.bookingForm.get("tableNo").value;
    //Validate, to check table number
    if (tableNo.length > 0 && tableNo > 0) {
      const itemData = new FormData();
      itemData.append("id", this.data.id);
      itemData.append("status", "1");
      itemData.append("tableNo", tableNo);
      itemData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(itemData, "changeBookTableStatus").subscribe(
        (response: any) => {
          if (response.status == 1) {
            this.dialogRef.close();
            //this.myapp.showSuccessAlert = true;
            //this.myapp.successMsg = response.message;
          } else {
            this.showError = true;
            this.errMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.showError = true;
      this.errMsg = "Sorry, Please enter a valid table number.";
    }
  }
}
