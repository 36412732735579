import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import  'jspdf-autotable';

@Component({
  selector: "app-commission-report",
  templateUrl: "./commission-report.component.html",
  styleUrls: ["./commission-report.component.css"]
})
export class CommissionReportComponent implements OnInit {
  public timeType: string = "today";
  public reportData: any = [];
  public userType: string = localStorage.getItem("userType");
  public barList: any = [];

  toDisabled = false;
  public cards = {
    totalAdminCommission: 0,
    totalAdminGst: 0,
    totalPaymentsGatewayAmt: 0,
    totalPaymentsGatewayGst: 0,
    adminCommissionRate: 0,
    adminGstRate: 0,
    paymentGatewayRate: 0,
    paymentGatewayGstRate: 0
  };
  filterForm: FormGroup;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      from: [""],
      to: [""],
      keyword: [""],
      barName: [""],
      barList: [],
      city: [localStorage.getItem("activeCity")],
    });

    this.cities = this.myapp.allCities;
   this.loadBarLIst();

    this.loadReport("",this.timeType);
  }
  loadBarLIst(){
    // BarListIn
    this.bs.callAPI("", "BarListIn").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barList = response.data;
         
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load Menu items defination
  loadReport(keyword,reportType) {
    this.filterForm.get("from").setValue("");
    this.filterForm.get("to").setValue("");

    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("time", reportType);
    barData.append("userType", localStorage.getItem("userType"));
    barData.append("city", this.filterForm.get("city").value);
    barData.append("keyword", keyword);
    barData.append("barName", this.filterForm.get("barName").value);
    barData.append("token", localStorage.getItem("token"));
   

    this.bs.callAPI(barData, "getCommissionReport").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.reportData = response.data.report;
          this.cards = response.data.cards;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to change report time
  changeReportType(time) {
    if (time == "today") {
      this.timeType = time;
      this.loadReport("",time);
    } else {
      this.timeType = time;
      this.reportData = [];
      this.cards = {
        totalAdminGst: 0,
        totalAdminCommission: 0,
        totalPaymentsGatewayAmt: 0,
        totalPaymentsGatewayGst: 0,
        adminCommissionRate: 0,
        adminGstRate: 0,
        paymentGatewayRate: 0,
        paymentGatewayGstRate: 0
      };
      this.calculateEndDate();
    }
  }

  //Function to calculate end date
  calculateEndDate() {
    this.toDisabled = true;
    let from = new Date(this.filterForm.get("from").value);
    var year = from.getFullYear();
    var month = from.getMonth();
    var date = from.getDate();
    var hour = from.getHours();
    var newDate;

    if (this.timeType == "yearly")
      newDate = new Date(year + 1, month, date - 1, hour);
    else if (this.timeType == "monthly") {
      newDate = new Date(year, month + 1, date - 1, hour);
    } else if (this.timeType == "weekly") {
      newDate = new Date(year, month, date + 6, hour);
    } else {
      this.toDisabled = false;
    }
    this.filterForm.get("to").setValue(new Date(newDate));
  }

  // Function to filter reports
  filterReport(keyword,email) {
    let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
    let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

    const param = new FormData();
    param.append("barId", localStorage.getItem("barId"));
    param.append("userType", localStorage.getItem("userType"));
    param.append("city", this.filterForm.get("city").value);
    param.append("barName", this.filterForm.get("barName").value);
    param.append("from", from);
    param.append("to", to);
    param.append("time", this.timeType);
    param.append("token", localStorage.getItem("token"));
    if (email == "1") param.append("email", email);

    this.bs.callAPI(param, "getCommissionReport").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          this.reportData = response.data.report;
          this.cards = response.data.cards;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  sendEmailReport() {
    this.filterReport("","1");

    this.myapp.showSuccessAlert = true;
    this.myapp.successMsg = "Report has been sent on email";
  }
  sendPDFReport() {
    // var dataToExport = [
    //   { id: 1, name: 'John', age: 30 },
    //   { id: 2, name: 'Jane', age: 25 },
    //   { id: 3, name: 'Doe', age: 40 }
    // ];
    var dataToExport =this.reportData;

    var headers = ['date', 'orderId','admin_total_amt','commission_rate','tip_amount', 'barName','total','bar_amount'];

    // Create new jsPDF instance
    const doc = new jsPDF();

    // Add a title
    doc.text('Commission Report', 10, 10);

    // Convert the data to an array of arrays
    const tableData = dataToExport.map(row => headers.map(header => row[header]));

    // Use jspdf-autotable to generate the table
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
    });

    // Save the PDF
    doc.save('Commission_Report.pdf');
  }
  sendExcelReport(){
   
  //   var data = [
  //     { id: 1, name: 'John', age: 30 },
  //     { id: 2, name: 'Jane', age: 25 },
  //     { id: 3, name: 'Doe', age: 40 }
  // ];
  var data =this.reportData;
  var filename ="Commission_Report";
  var sheetName ='Commission_Report';
  var headers = ['date', 'orderId', 'barName','total','bar_amount'];
  
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {header: headers});
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  
  // Convert the workbook to an array buffer
  const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  
  // Create a blob object from the buffer
  const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
  
  // Create a download link
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename + '.xlsx';
  
  // Trigger the download
  link.click();
  
  // Cleanup
  URL.revokeObjectURL(url);

  }
  //On Keyword filter
  onKeyWordChange(keyword) {
    // this.hideLoadMore = true;
    // this.page = 1;
    // let status = this.filterForm.get("status").value;
    
    let city = this.filterForm.get("city").value;
   // console.log(city);
    
    this.loadReport("","");
  }

  // //On Applied/Status filter
  onDropDownChange() {
   // this.hideLoadMore = true;
    //this.page = 1;
   let reportType = this.timeType;
    //let status = this.filterForm.get("status").value;
    //let city = this.filterForm.get("city").value;
    this.filterReport("0","");
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
   // console.log('lll');
    
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange('');
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange('');
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange('');
  }
}
