import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.css"]
})
export class CategoryComponent implements OnInit {
  specialMenu = false;
  dessertMenu = false;

  categoryForm: FormGroup;
  formHeading = "Create Sub Category (optional)";
  btnText = "Save";
  showCancelBtn = false;
  catData = [];
  mainCategories = []; //this.myapp.itemMainCategories;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Load the categories from app component
    this.mainCategories = this.myapp.itemMainCategories;
    //console.log(this.mainCategories);

    ///Call the Bar Profile
    this.loadCategories();

    this.categoryForm = this.fb.group({
      barId: [localStorage.getItem("barId")],
      subCatId: [""],
      parentId: ["", [Validators.required]],
      subCatName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(25),
          Validators.pattern("^[a-zA-Z ()]+$")
        ]
      ]
    });
  }

  //Load Categories defination
  loadCategories() {
    //this.myapp.isLoadingResults = true;
    //this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(barData, "getCategories").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.catData = response.data;

          if (response.specialMenu == "1") this.specialMenu = true;
          else this.specialMenu = false;

          if (response.dessertMenu == "1") this.dessertMenu = true;
          else this.dessertMenu = false;
        } else {
          //this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          if (response.specialMenu == "1") this.specialMenu = true;
          else this.specialMenu = false;

          if (response.dessertMenu == "1") this.dessertMenu = true;
          else this.dessertMenu = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Category Form Submission
  onCategorySubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));
    formData.append("subCatId", this.subCatId.value);
    formData.append("parentId", this.parentId.value);
    formData.append("subCatName", this.subCatName.value);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "addEditSubCategory").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.loadCategories();
        //this.subCatName.setValue("");

        let sci = this.subCatId.value;
        let pi = this.parentId.value;
        this.categoryForm.reset();
        this.categoryForm.patchValue({
          barId: localStorage.getItem("barId"),
          parentId: pi,
          subCatId: sci,
          subCatName: ""
        });
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Function to Active/Inactive records
  changeStatus(id, value, catIndex, scIndex) {
    //console.log(this.catData);
    this.myapp.changeStatus("bnItemCategories", id, value);
    //console.log(this.catData[catIndex].subCat[scIndex].subCatStatus);
    //this.ngOnInit();
    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.catData[catIndex].subCat[scIndex].subCatStatus = 1;
    else if (value == 0)
      this.catData[catIndex].subCat[scIndex].subCatStatus = 0;
  }

  //Function to edit record, it will show the data in form
  editCategory(subCatId, subCatName, parentId) {
    this.myapp.hideAlerts();
    this.showCancelBtn = true;
    this.formHeading = "Edit Category";
    this.btnText = "Update";

    this.categoryForm.patchValue({
      barId: localStorage.getItem("barId"),
      subCatId: subCatId,
      parentId: parentId,
      subCatName: subCatName
    });
  }

  //Function to cancel edit record
  cancelEdit() {
    this.myapp.hideAlerts();
    this.showCancelBtn = false;
    this.formHeading = "Create Sub Category (optional)";
    this.btnText = "Save";

    this.categoryForm.patchValue({
      barId: localStorage.getItem("barId"),
      subCatId: "",
      parentId: "",
      subCatName: ""
    });
  }

  // Function to enable/disable special menu
  enableDisableMenu() {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));

    let sm: any = 1;
    if (!this.specialMenu) sm = 0;
    let metaData: any = [{ specialMenu: sm }];
    formData.append("metaData", JSON.stringify(metaData));
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "updateBarSettings").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.myapp.isLoadingResults = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

   // Function to enable/disable special menu
   enableDisableDessertMenu() {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));

    let sm: any = 1;
    if (!this.dessertMenu) sm = 0;
    let metaData: any = [{ dessertMenu: sm }];
    formData.append("metaData", JSON.stringify(metaData));
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "updateBarSettings").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.myapp.isLoadingResults = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Getor - To access the fields directly in the form in HTML
  get subCatId() {
    return this.categoryForm.get("subCatId");
  }

  get parentId() {
    return this.categoryForm.get("parentId");
  }

  get subCatName() {
    return this.categoryForm.get("subCatName");
  }
}
