import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
@Component({
  selector: "app-event-ticket",
  templateUrl: "./event-ticket.component.html",
  styleUrls: ["./event-ticket.component.css"]
})
export class EventTicketComponent implements OnInit {
  bookingList = [];
  page = 1; //used for load more
  hideLoadMore = true;
  eventId: any;
  adminLogin = false;
  showModal = true;
  msgForm: FormGroup;
  filterForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    if (window.localStorage.getItem("userType") == "A") {
      this.adminLogin = true;
    }

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      eventId: [""]
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.eventId = id;
        this.loadTicketBooking(id, "");
      } else {
      }
    });

    //Message Form
    this.msgForm = this.fb.group({
      messageType: ["1"],
      userId: ["", [Validators.required]],
      subject: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      message: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ],
      allUsers: [""]
    });
  }
  //Define loadTicketBooking function
  loadTicketBooking(id, keyword) {
    const bookingData = new FormData();
    bookingData.append("keyword", keyword);
    bookingData.append("eventId", id);
    bookingData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(bookingData, "getTicketBookingList").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          //console.log("event Data: " + response.data);
          this.bookingList = response.data;
        } else {
          this.bookingList = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("keyword", this.filterForm.get("keyword").value);
    barData.append("page", pageNo);
    barData.append("eventId", this.eventId);
    barData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(barData, "getTicketBookingList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.bookingList.push(response.data[i]);
          }
          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    this.loadTicketBooking(this.eventId, keyword);
  }
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to grab user Id
  patchUserId(id) {
    this.showModal = true;
    this.msgForm.patchValue({
      userId: id
    });
  }

  //Message submit function
  onMessageSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    const formData = new FormData();
    formData.append("messageType", this.messageType.value);
    formData.append("userId", this.msgForm.get("userId").value);
    formData.append("subject", this.subject.value);
    formData.append("message", this.message.value);
    formData.append("allUsers", this.allUsers.value);
    formData.append("eventId", this.eventId);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "sendMessageToEventUsers").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.msgForm.reset();
        this.msgForm.get("userId").setValue("");
        this.messageType.setValue("1");
        this.allUsers.setValue(false);

        // Hide the Modal
        this.showModal = false;
        let closeModal: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModal.classList.remove("modal-open");
        let closeModalpadding: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModalpadding.style.padding = "0px";
        let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
          "modal-backdrop"
        )[0] as HTMLElement;
        ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  // Getor - To access the fields directly in the form in HTML
  get messageType() {
    return this.msgForm.get("messageType");
  }
  get subject() {
    return this.msgForm.get("subject");
  }
  get message() {
    return this.msgForm.get("message");
  }
  get allUsers() {
    return this.msgForm.get("allUsers");
  }
}
