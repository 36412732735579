import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"]
})
export class NotificationComponent implements OnInit {
  notificationData = [];
  hideLoadMore = true;
  page = 1; //used for load more
  filterForm: FormGroup;
  //will continue from here
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) { }
  ngOnInit() {
    this.filterForm = this.fb.group({
      keyword: [""]
    });
    this.loadNotificationData();
  }
  //Load item defination
  loadNotificationData() {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(barData, "getBarNotification").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.notificationData = response.notifications;
        } else {
          this.notificationData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("page", pageNo);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getBarNotification").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.notifications.length; i++) {
            this.notificationData.push(response.notifications[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.notifications.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // Delete individual Notification
  deleteNotification(id, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const barData = new FormData();
        barData.append("id", id);
        barData.append("token", localStorage.getItem("token"));
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs
          .callAPI(barData, "deleteBarNotification")
          .subscribe(response => {
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              //Delete the item from array
              this.notificationData.splice(index, 1);
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
            }
          });
      }
    });
  }

  // Delete All Notification
  deleteAllNotifications() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete all notifications?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const barData = new FormData();
        barData.append("barId", localStorage.getItem("barId"));
        barData.append("token", localStorage.getItem("token"));

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs
          .callAPI(barData, "deleteBarAllNotification")
          .subscribe(response => {
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              this.ngOnInit();
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
            }
          });
      }
    });
  }

  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  goToPage(orderId, orderType) {
    if (orderType == "order") {
      this.router.navigate(["kot-list", orderId]);
    } else if (orderType == "preorder") {
      this.router.navigate(["invoice", orderId]);
    } else if (orderType == "visit") {
      this.router.navigate(["code-generate"]);
    } else if (orderType == "bookTable") {
      this.router.navigate(["booking-table"]);
    } else if (orderType == "orderPayment") {
      this.router.navigate(["invoice", orderId]);
    } else {
      this.router.navigate(["bar-profile"]);
    }
  }
}
