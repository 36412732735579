import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { AcceptBookingDialogComponent } from "../accept-booking-dialog/accept-booking-dialog.component";
@Component({
  selector: "app-booking-table",
  templateUrl: "./booking-table.component.html",
  styleUrls: ["./booking-table.component.css"]
})
export class BookingTableComponent implements OnInit {
  public timeType: string = "today";
  toDisabled = false;

  //Variable Declration
  currencySymbol="";
  isTableBooking = true;
  cancelForm: FormGroup;
  bookingData = [];
  filterForm: FormGroup;
  page = 1; //used for load more
  hideLoadMore = true;
  showModal = true;
  cancelByName: any = null;
  cancelReason: any = null;
  cancelDateTime: any = null;
  gracePeriod: any = "";
  timing: any = [15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

  //Injecting the services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    //Call the Orders Function
   
    //Define filter form
    this.filterForm = this.fb.group({
      from: [""],
      to: [""],
      keyword: [""],
      paymentStatus: [""],
      status: [""]
    });
    this.loadBookingData("", "", "",this.timeType);
    //visitCancelForm Form Model Declration with form validation for the fields
    this.cancelForm = this.fb.group({
      id: ["", [Validators.required]],
      status: ["", [Validators.required]],
      reason: [
        "",
        [
          //Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ]
    });
  }
  //Load bookingData defination
  loadBookingData(keyword, status, paymentStatus,timeType) {
    if (this.timeType == "today") {
      this.filterForm.get("from").setValue("");
    this.filterForm.get("to").setValue("");

    } else {

    }
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("status", status);
    barData.append("paymentStatus", paymentStatus);
    barData.append("token", localStorage.getItem("token"));
    barData.append("time", timeType);
    if (this.timeType == "today") {
     
    } else {
      let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
      let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

      barData.append("from", from);
      barData.append("to", to);
    }

    this.bs.callAPI(barData, "getAllTableBookingRestaurant").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //console.log("table data: " + response.data);
          this.bookingData = response.data;
          this.gracePeriod = response.gracePeriod;

          if (response.isTableBooking == "1") this.isTableBooking = true;
          else this.isTableBooking = false;
        } else {
          this.bookingData = [];
          this.gracePeriod = response.gracePeriod;
          if (response.isTableBooking == "1") this.isTableBooking = true;
          else this.isTableBooking = false;
        }

        if (!this.gracePeriod) {
          this.gracePeriod = 30;
          this.updateBarSettings();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to change report time
  changeReportType(time) {
    if (time == "today") {
      this.timeType = time;
      this.loadBookingData("","","",time);
    } else {
      this.timeType = time;
      this.bookingData = [];
      
      this.calculateEndDate();
    }
  }

  //Function to calculate end date
  calculateEndDate() {
    this.toDisabled = true;
    let from = new Date(this.filterForm.get("from").value);
    var year = from.getFullYear();
    var month = from.getMonth();
    var date = from.getDate();
    var hour = from.getHours();
    var newDate;

    if (this.timeType == "yearly")
      newDate = new Date(year + 1, month, date - 1, hour);
    else if (this.timeType == "monthly") {
      newDate = new Date(year, month + 1, date - 1, hour);
    } else if (this.timeType == "weekly") {
      newDate = new Date(year, month, date + 6, hour);
    } else {
      this.toDisabled = false;
    }
    this.filterForm.get("to").setValue(new Date(newDate));
  }

  // Function to filter reports
  filterReport(email ,status, keyword) {
   
    let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
    let to = this.cs.stringToDateFun(this.filterForm.get("to").value);

    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("keyword", keyword);
    barData.append("status", status);
    barData.append("token", localStorage.getItem("token"));
    barData.append("from", from);
    barData.append("to", to);

    this.bs.callAPI(barData, "getAllTableBooking").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.bookingData = response.data;
          //console.log(this.visitData);
        } else {
          this.bookingData = [];
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("barId", localStorage.getItem("barId"));
    userData.append("page", pageNo);
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("status", this.filterForm.get("status").value);
    userData.append(
      "paymentStatus",
      this.filterForm.get("paymentStatus").value
    );
    userData.append("token", localStorage.getItem("token"));

    if (this.timeType == "today") {
      
    } else {
      let from = this.cs.stringToDateFun(this.filterForm.get("from").value);
      let to = this.cs.stringToDateFun(this.filterForm.get("to").value);
      userData.append("from", from);
      userData.append("to", to);

    }
    this.bs.callAPI(userData, "getAllTableBooking").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.bookingData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //reject booking request
  rejectRequest(id) {
    this.showModal = true;
    this.cancelForm.patchValue({
      id: id,
      status: "2"
    });
    /*let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want reject this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const itemData = new FormData();
        itemData.append("status", "2");
        itemData.append("id", id);

        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
        this.bs
          .callAPI(itemData, "changeBookTableStatus")
          .subscribe(response => {
            if (response.status === 1) {
              this.bookingData[index].status = 2;
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
            }
          });
      }
    });*/
  }

  //Function to cancel the table booking
  onCancelSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("id", this.cancelForm.get("id").value);
    formData.append("status", this.cancelForm.get("status").value);
    formData.append("reason", this.cancelForm.get("reason").value);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "changeBookTableStatus").subscribe(response => {
      if (response.status === 1) {
        // Hide the Modal
        this.showModal = false;
        let closeModal: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModal.classList.remove("modal-open");
        let closeModalpadding: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModalpadding.style.padding = "0px";
        let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
          "modal-backdrop"
        )[0] as HTMLElement;
        ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);

        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.ngOnInit();
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //accept booking request
  acceptRequest(id, status, index) {
    let tableId = id;
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want confirm this?" }
    });
    //alert(" outer table id:"+ tableId);
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      //alert(" inner table id:"+ tableId);
      if (result == true) {
        //this.bookingData[index].status = 1;
        //this.myapp.errorMsg = "Booking request accepted!";
        let dialogBookingRef = this.dialog.open(AcceptBookingDialogComponent, {
          data: { id: tableId }
        });
        //On Cancel Button
        dialogBookingRef.afterClosed().subscribe(result => {
          this.myapp.hideAlerts();
          //console.log(`dialog result:  ${result}`);
          if (!result) {
            this.bookingData[index].status = 1;
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = "Booking has been confirmed successfully.";
            this.myapp.isLoadingResults = false;
            this.ngOnInit();
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = "No action performed";
            this.myapp.isLoadingResults = false;
          }
        });
      }
    });
  }
  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let paymentStatus = this.filterForm.get("paymentStatus").value;
    this.loadBookingData(keyword, status, paymentStatus,this.timeType);
  }

  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let paymentStatus = this.filterForm.get("paymentStatus").value;
    this.loadBookingData(keyword, status, paymentStatus,this.timeType);
  }
  //Function to convert API Date in Date Formate
  convertToDateTime(data, option) {
    return this.cs.convertToDateTime(data, option);
  }

  //Function to return time in am/pm format on HTML
  convertTime(bookingTime) {
    return this.cs.convertTime(bookingTime);
  }

  //Function to show the reason in modal
  loadCancelReason(cancelBy, reason, datetime) {
    this.cancelByName = cancelBy;
    this.cancelReason = reason;
    this.cancelDateTime = this.convertToDateTime(datetime, "");
  }

  //Function to update bar settings
  updateBarSettings() {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));

    /*formData.append("gracePeriod", this.gracePeriod);
    let bookingEnable: any = 1;
    if (!this.isTableBooking) bookingEnable = 0;
    formData.append("isTableBooking", bookingEnable);*/

    let bookingEnable: any = 1;
    if (!this.isTableBooking) bookingEnable = 0;
    let metaData: any = [
      { tableGracePeriod: this.gracePeriod },
      { isTableBooking: bookingEnable }
    ];
    formData.append("metaData", JSON.stringify(metaData));
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "updateBarSettings").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.myapp.isLoadingResults = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // Getor - To access the fields directly in the form in HTML
  get reason() {
    return this.cancelForm.get("reason");
  }
}
