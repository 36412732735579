import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-restaurants-list",
  templateUrl: "./restaurants-list.component.html",
  styleUrls: ["./restaurants-list.component.css"]
})
export class RestaurantsListComponent implements OnInit {
  //Variable Declration
  filterForm: FormGroup;
  barData = [];
  fileurl: any = null;
  hideLoadMore = true;
  page = 1; //used for load more
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;
  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      city: [localStorage.getItem("activeCity")],
      country:[localStorage.getItem("activeCountryId")],
    });
    this.cities = this.myapp.allCities;    
    this.countries = this.myapp.allCountries;
    this.loadBarList();
  }

  //Load item defination
  loadBarList() {
    const userData = new FormData();
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("roleId", window.localStorage.getItem("roleId"));
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("city", this.filterForm.get("city").value);
    userData.append("countryId", this.filterForm.get("country").value);
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "barList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barData = response.data;
          this.fileurl = response.url;
        } else {
          this.barData = [];
          this.fileurl = null;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  /*loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("page", pageNo);
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("roleId", window.localStorage.getItem("roleId"));
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("city", this.filterForm.get("city").value);
    userData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(userData, "getBarList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.barData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }*/

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let city = this.filterForm.get("city").value;
    let countryId = this.filterForm.get("country").value;
    // this.loadBarList();
    this.loadBarList();
  }
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;
    let countryId = this.filterForm.get("country").value;
    this.loadBarList();
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
          // window.localStorage.setItem("activeCity", "");
          
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        // window.localStorage.setItem("activeCity", "");
        this.onKeyWordChange();
      }
    }
  }


  // Function to filer Cities - for Bar
  filterByCountry(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.countriesSorted = false;
    } else {
      let keyword = this.filterForm.get("country").value;
      if (keyword.length > 0) {
        let output = [];
        this.countries.forEach(function(countries) {
          if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(countries);
          }
        });
        if (output.length > 0) {
          this.sortedCountries = output;
          this.countriesSorted = true;
        } else {
          this.sortedCountries = [];
          this.countriesSorted = false;
          // window.localStorage.setItem("activeCountryId", "");
        }
      } else {
        this.sortedCountries = [];
        this.countriesSorted = false;
        // window.localStorage.setItem("activeCountryId", "");
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    // window.localStorage.setItem("activeCity", str);
    this.onKeyWordChange();
  }
  fillTextBoxCountry(str) {
    this.filterForm.patchValue({
      country: str
    });
    this.sortedCountries = [];
    this.countriesSorted = false;
    // window.localStorage.setItem("activeCountryId", str);
    this.onKeyWordChange();
  }

}
