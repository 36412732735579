import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";

import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-activation-request",
  templateUrl: "./activation-request.component.html",
  styleUrls: ["./activation-request.component.css"]
})
export class ActivationRequestComponent implements OnInit {
  //Variable Declration
  cancelForm: FormGroup;
  filterForm: FormGroup;
  barData = [];
  hideLoadMore = true;
  page = 1; //used for load more
  showModal = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) {}
  ngOnInit() {
    this.loadBarList(4);
    //Define the Filter Form Model
  }
  //Load item defination
  loadBarList(status) {
    const userData = new FormData();
    userData.append("status", status);
    userData.append("roleId", window.localStorage.getItem("roleId"));
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("countryId", localStorage.getItem("activeCountryId"));
    userData.append("city", localStorage.getItem("activeCity"));
    userData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(userData, "barList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barData = response.data;
        } else {
          this.barData = [];
        }
      },
      error => {
        console.log(error);
      }
    );

    //Cancel Form Model Declration with form validation for the fields
    this.cancelForm = this.fb.group({
      userId: ["", [Validators.required]],
      //status: ["", [Validators.required]],
      reason: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ]
    });
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("page", pageNo);
    userData.append("status", "4");
    userData.append("roleId", window.localStorage.getItem("roleId"));
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(userData, "barList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.barData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Function to Active/Inactive records
  changeStatus(id, value, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to activate this bar profile?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.changeStatus("bnUsers", id, value);
        this.barData.splice(index, 1);
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = "Status has been updated successfully.";
      }
    });
  }

  // Function to reject request
  rejectRequest(id) {
    this.showModal = true;
    this.cancelForm.patchValue({
      userId: id
      //status: "3"
    });
  }

  // Function to Cancel the bar approval request
  onCancelSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    this.bs
      .callAPI(this.cancelForm.value, "rejectBarRequest")
      .subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.ngOnInit();

          // Hide the Modal
          this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  // Getor - To access the fields directly in the form in HTML
  get reason() {
    return this.cancelForm.get("reason");
  }
}
