import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "app-bars-dialog",
  templateUrl: "./bars-dialog.component.html",
  styleUrls: ["./bars-dialog.component.css"]
})
export class BarsDialogComponent implements OnInit {
  //Variable Declration
  hideLoadMore = true;
  page = 1; //used for load more
  filterForm: FormGroup;
  logoPath = this.cs.barLogoPath();
  barData = [];
  activeBarId: any = 0;

  //Injecting Services
  constructor(
    public dialogRef: MatDialogRef<BarsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private bs: BarService,
    private cs: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    //Assign the data in varialble
    this.barData = this.data;
    //if (localStorage.getItem("activeBarId"))
    this.activeBarId = localStorage.getItem("activeBarId");

    //Visit Form modal, in the popup
    this.filterForm = this.fb.group({
      keyword: [""]
    });

    // console.log(this.data);
  }

  activateBar(index) {
    // alert(this.barData[index].barId);
    let barId = this.barData[index].barId;
    let barName = this.barData[index].barName;
    window.localStorage.setItem("barId", barId);
    window.localStorage.setItem("activeBarId", barId);
    window.localStorage.setItem("activeBarName", barName);
    this.activeBarId = barId;

    //Reload the active route
    let activeRoute = this.router.url;
    if (activeRoute != "/admin-dashboard")
      window.location.href = this.cs.siteUrl + activeRoute;
  }

  /*deactiveBar() {
    localStorage.removeItem("barId");
    localStorage.removeItem("activeBarId");
    localStorage.removeItem("activeBarName");
    this.activeBarId = 0;
  }*/

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = "1";
    this.loadBarList(keyword, status);
  }

  //Load item defination
  loadBarList(keyword, status) {
    const dialogFormData = new FormData();
    dialogFormData.append("keyword", keyword);
    dialogFormData.append("status", status);
    dialogFormData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(dialogFormData, "barList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barData = response.data;
        } else {
          this.barData = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    const dialogFormData = new FormData();
    dialogFormData.append("keyword", this.filterForm.get("keyword").value);
    dialogFormData.append("page", pageNo);
    dialogFormData.append("status", "1");
    dialogFormData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(dialogFormData, "barList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page

          for (let i = 0; i < response.data.length; i++) {
            this.barData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.hideLoadMore = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
}
