import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";

@Component({
  selector: "app-add-staff",
  templateUrl: "./add-staff.component.html",
  styleUrls: ["./add-staff.component.css"]
})
export class AddStaffComponent implements OnInit {
  staffForm: FormGroup;
  actionType = "add";
  pageTitle: string;
  dynamicRoute: any = "";
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    //Profile Form Model Declration with form validation for the fields
    this.staffForm = this.fb.group({
      id: [""],
      staffName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(25),
          Validators.pattern("^[a-zA-Z () &]+$")
        ]
      ],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      roleId: ["", [Validators.required]]
    });
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadStaffData(id);
        this.actionType = "edit";
        this.pageTitle = "Edit Staff";
        this.dynamicRoute = "editStaff";
      } else {
        this.actionType = "add";
        this.pageTitle = "Add Staff";
        this.dynamicRoute = "addStaff";
      }
    });
  }
  //Function to submit data
  onStaffSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;
    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("id", this.staffForm.get("id").value);
      formData.append("name", this.staffForm.get("staffName").value);
      formData.append("email", this.staffForm.get("email").value);
      formData.append("roleId", this.staffForm.get("roleId").value);
      formData.append("phone", this.staffForm.get("phone").value);
      formData.append("loginLink", this.cs.siteUrl + "login");
      formData.append("userId", window.localStorage.getItem("userId"));
      formData.append("token", localStorage.getItem("token"));

      //Form submit by appi call
      this.bs.callAPI(formData, this.dynamicRoute).subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.router.navigate(["/view-staff"]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    }
  }
  loadStaffData(id) {
    const staffData = new FormData();
    staffData.append("id", id);
    staffData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(staffData, "getStaffDetail").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.staffForm.patchValue({
            id: response.data.id,
            staffName: response.data.name,
            email: response.data.email,
            phone: response.data.phone,
            roleId: response.data.roleId
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  resetForm() {
    this.staffForm.reset();
    //Patch the required data in Form Modal
    this.staffForm.patchValue({
      id: "",
      staffName: "",
      phone: "",
      email: ""
    });
  }
  get staffName() {
    return this.staffForm.get("staffName");
  }
  get email() {
    return this.staffForm.get("email");
  }
  get phone() {
    return this.staffForm.get("phone");
  }
  get roleId() {
    return this.staffForm.get("roleId");
  }
}
