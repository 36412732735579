import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../../service/bar.service";
import { CommonService } from "../../../service/common.service";
import { AppComponent } from "../../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";
@Component({
  selector: "app-add-price",
  templateUrl: "./add-price.component.html",
  styleUrls: ["./add-price.component.css"]
})
export class AddPriceComponent implements OnInit {
  isEventPriceEnable: any = false;
  eventPricefForm: FormGroup;
  actionType = "add";
  pageTitle: string;
  dynamicRoute: any = "";
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();

    //Profile Form Model Declration with form validation for the fields
    this.eventPricefForm = this.fb.group({
      id: [""],
      price: ["", [Validators.required, Validators.pattern("^[0-9() . ]+$")]],
      duration: ["", [Validators.required, Validators.pattern("^[[0-9()]+$")]]
    });
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadEventPriceData(id);
        this.actionType = "edit";
        this.pageTitle = "Edit Special Event Price";
        this.dynamicRoute = "editSpecialEventFee";
      } else {
        this.actionType = "add";
        this.pageTitle = "Add Special Event Price";
        this.dynamicRoute = "addSpecialEventFee";
      }
    });

    this.getCmsSetting();
  }

  //Function to get settings
  getCmsSetting() {
    this.bs.callAPI("", "getCmsSetting").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.isEventPriceEnable = response.data.isEventPriceEnable;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Function to submit data
  onFormSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;
    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("id", this.eventPricefForm.get("id").value);
      formData.append("duration", this.eventPricefForm.get("duration").value);
      formData.append("price", this.eventPricefForm.get("price").value);
      formData.append("token", localStorage.getItem("token"));
      //Form submit by appi call
      this.bs.callAPI(formData, this.dynamicRoute).subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.router.navigate(["/view-special-event-price"]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    }
  }
  loadEventPriceData(id) {
    const formParam = new FormData();
    formParam.append("id", id);
    formParam.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formParam, "getSpecialEventFeeDetails").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.eventPricefForm.patchValue({
            id: response.data.id,
            duration: response.data.duration,
            price: response.data.price
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  resetForm() {
    this.eventPricefForm.reset();
    //Patch the required data in Form Modal
    this.eventPricefForm.patchValue({
      id: "",
      duration: "",
      price: ""
    });
  }

  // Function to update settings
  updateSettings() {
    let enable: any = this.isEventPriceEnable ? 1 : 0;

    //this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("isEventPriceEnable", enable);
    formData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(formData, "editCmsSetting").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.myapp.isLoadingResults = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  get duration() {
    return this.eventPricefForm.get("duration");
  }
  get price() {
    return this.eventPricefForm.get("price");
  }
}
