import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { ImageCroppedEvent } from "ngx-image-cropper";
import * as moment from "moment";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-add-happy-hour",
  templateUrl: "./add-happy-hour.component.html",
  styleUrls: ["./add-happy-hour.component.css"]
})
export class AddHappyHourComponent implements OnInit {
  //VAriable declaration
  pageHeading = "Add Happy Hour";
  barName = "";
  happyhourForm: FormGroup;
  public AllDaysAvailable = false;
  public SpecificDaysAvailable = false;
  public Discount = false;
  public Offer = false;
  DiscountErrorMessage = false;
  DiscountMaxErrorMessage = false;
  templates = [];
  happyHourSave = "Publish";
  canSubmit = true;
  weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  tempPath = this.cs.tempPath();
  offerImgPath = this.cs.offersPath();
  imgPreviewPath = null;
  imgCropPath = null;
  croppedImage: any = "";
  imageChangedEvent: any = "";
  FileErrorMessageLog = false;
  showCancelBtn = false;
  editing = false;
  daysExist = []; //used in Edit Offer
  temp = "";
  TimeErrorMessage = false;
  MinuteErrorMessage = false;
  minTime = new Date(new Date().setHours(10, 30));

  offerId: any;
  starttimee: any;
  endtimee: any;
  durationvalue: any;
  durationmin = 3600000;

  //offer item
  itemData = [];
  itemIdsExist = [];
  itemImgPath = this.cs.itemsPath();
  isShowOfferItem = false;
  dynamicDiscountRate = 0;
  imageSave = false;
  base64Img: any = "";

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Call Load templates function
    this.loadTemplates();

    //Happy Hour Form Model Declaration with form validation for the fields
    this.happyhourForm = this.fb.group({
      id: [""],
      barId: [localStorage.getItem("barId")],
      offerImage: [""],
      templateId: [0], //[this.templates[0]],
      title: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(50)]
      ],
      description: ["", [Validators.minLength(20), Validators.maxLength(200)]],
      startTime: ["", [Validators.required]],
      endTime: ["", [Validators.required]],
      appliedOnDay: ["", [Validators.required]],
      weekDays: new FormArray([]),
      appliedOn: ["", [Validators.required]],
      discountType: ["", [Validators.required]],
      discountRate: ["", [Validators.pattern("^[0-9()]+$")]],
      buy: ["", [Validators.pattern("^[0-9()]+$")]],
      free: ["", [Validators.pattern("^[0-9()]+$")]],
      itemIds: new FormArray([]),
      agree: ["", [Validators.required]],
      gracePeriod: [""]
    });

    //Access the Paramter from URL in EDIT
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadMenuItems();
        this.canSubmit = false;
        this.loadOfferData(id);
        //this.loadOfferItems("happyhour", id);
        this.showCancelBtn = true;
        this.editing = true;
        this.offerId = id;
        this.pageHeading = "Edit Happy Hour";
      }
    });
  }

  //Function to load templates
  loadTemplates() {
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getTemplates").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barName = response.bar.barName; //To show on Image preview

          this.templates = response.data;
          //Add the first Image for preview
          this.imgPreviewPath = this.tempPath + this.templates[0].templateFrame;
          //Patch the template ID in Form Modal
          this.happyhourForm.patchValue({
            templateId: this.templates[0].id,
            offerImage: ""
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Function to set the template with Discount Form Model
  selectTemplate(index) {
    let tempData = this.templates[index];
    this.imgPreviewPath = this.tempPath + tempData.templateFrame;
    //Patch the template ID in Form Modal
    this.happyhourForm.patchValue({
      templateId: tempData.id,
      offerImage: ""
    });
    this.base64Img = "";
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.imgPreviewPath = event.base64;
    this.happyhourForm.get("offerImage").setValue(event.base64);
    this.base64Img = "ok";
  }

  hideCropImg() {
    this.imageSave = false;
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];

      if (fileType === "image") {
        this.imageSave = true;
        this.FileErrorMessageLog = false;
        this.happyhourForm.get("offerImage").setValue(file);
        this.imageChangedEvent = event;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.imgPreviewPath = reader.result;
        };
        //unPatch the template ID in Form Modal
        this.happyhourForm.patchValue({
          templateId: 0
        });
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  //Load Offer Data in case of edit
  loadOfferData(id) {
    const formData = new FormData();
    formData.append("barId", localStorage.getItem("barId"));
    formData.append("id", id);
    formData.append("offerType", "happyhour");
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getOffersData").subscribe(
      (response: any) => {
        if (response.status == 1) {
          if (response.canEdit == true) this.canSubmit = true;

          // console.log(response.data);
          this.loadOfferItems("happyhour", response.data.happyHId);

          //Patch the Item data in menuForm
          this.happyhourForm.patchValue({
            barId: localStorage.getItem("barId"),
            id: response.data.id,
            offerImage: response.data.offerImage,
            title: response.data.title,
            description: response.data.description,
            startTime: this.cs.timeWithoutSeconds(response.data.startTime),
            endTime: this.cs.timeWithoutSeconds(response.data.endTime),
            discountRate: response.data.discountRate,
            appliedOnDay: response.data.appliedOnDay.toString(),
            appliedOn: response.data.appliedOn.toString(),
            templateId: response.data.templateId,
            discountType: response.data.discountType.toString(),
            buy: response.data.buy,
            free: response.data.free,
            gracePeriod: response.data.gracePeriod,
            agree: 1
          });

          //Show/Hide the Weekdays
          if (response.data.appliedOnDay == 1) {
            this.AllDays();
          } else if (response.data.appliedOnDay == 2) {
            this.SpecificDays(); //Visible the weekday

            //Add the WeekDays in the arary
            let existingDays = response.data.weekDay.split(",");
            for (let i = 0; i < existingDays.length; i++) {
              const formArray: FormArray = this.happyhourForm.get(
                "weekDays"
              ) as FormArray;

              // Add a new control in the arrayForm
              formArray.push(new FormControl(existingDays[i]));
              this.daysExist.push(existingDays[i]);
            }
          }
          if (response.data.appliedOn == 2) {
            this.isShowOfferItem = true;
          }
          //Show/Hide the Buy/Free OR Discount box
          if (response.data.discountType == 1) {
            this.DiscountShow();
          } else this.OfferShow();

          //Preview of Image
          if (response.data.templateId != 0)
            this.imgPreviewPath = this.tempPath + response.data.templateFrame;
          else
            this.imgPreviewPath = this.offerImgPath + response.data.offerImage;

          this.TitleShow(response.data.title);
          this.DescriptionShow(response.data.description);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Funciton to select/deselect weekDays om checkbox event
  onCheckChange(event) {
    const formArray: FormArray = this.happyhourForm.get(
      "weekDays"
    ) as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
      this.daysExist.push(event.target.value);
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  //form submit function
  onHappyHourSubmit() {
    this.myapp.hideAlerts();
    let dayOpt = this.appliedOnDay.value;
    let offerOpt = this.discountType.value;
    let canSubmit = true;
    let msg = "";

    /*if (this.templateId.value == 0) {
      canSubmit = false;
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please select a template";
      this.myapp.isLoadingResults = false;
    }*/

    //Function to validate End time is grater then start time
    let startValue = this.startTime.value;
    let endValue = this.endTime.value;
    console.log("startValue" + startValue);
    // example 1
    // let starttimee = moment(startValue,'HH:mm:ss');
    // let endtimee = moment(endValue,'HH:mm:ss');
    // let duration = moment.duration(endtimee.diff(starttimee));
    // console.log("duration" + duration);
    // this.durationvalue = duration;
    // console.log("durationvalue" + this.durationvalue);
    // let durationmin = 3600000;
    // if (this.durationvalue > this.durationmin ) {
    //   this.TimeErrorMessage = true;
    //   canSubmit = false;
    //   return;
    // } else {
    //   this.TimeErrorMessage = false;
    //   canSubmit = true;
    // }

    /*let starttime = this.cs.timevalidation(startValue);
    let endtime = this.cs.timevalidation(endValue);
    console.log("startValue" + startValue);
    if (starttime >= endtime) {
      this.TimeErrorMessage = true;
      canSubmit = false;
      return;
    } else {
      this.TimeErrorMessage = false;
      canSubmit = true;
    }*/

    // VAlidating minimum 1 hr interval
    /*let starttotal = moment(startValue, "hh:mm:ss").add(59, 'minutes').format('LTS');
    let endtotal = moment(endValue, "hh:mm:ss").subtract(0, 'minutes').format('LTS');
    console.log("starttotal" + starttotal);
    console.log("endtotal" + endtotal);
    if (starttotal > endtotal || starttotal == endtotal) {
      this.MinuteErrorMessage = true;
      canSubmit = false;
      return;
    } else {
      this.MinuteErrorMessage = false;
      canSubmit = true;
    }*/

    // Weekdays selection validation
    if (dayOpt == "2") {
      let selectedDays = this.happyhourForm.get("weekDays").value;
      if (selectedDays.length == 0) {
        canSubmit = false;
        msg = "Please select at least one day";
      }
    }

    // Discount value validation
    if (offerOpt == "1") {
      let discount = this.discountRate.value;
      if (discount == "") {
        canSubmit = false;
        msg = "Please enter the discount";
      } else if (discount == 0) {
        canSubmit = false;
        msg = "Please enter at least 1% discount";
      } else if (discount > 100) {
        canSubmit = false;
        msg = "Discount cannot exceed 100%";
      }
    } else if (offerOpt == "2") {
      let buy = this.buy.value;
      let free = this.free.value;
      if (buy == "" || free == "" || buy == 0 || free == 0) {
        canSubmit = false;
        msg = "Please enter the offer details";
      }
    }
    const ids: FormArray = this.happyhourForm.get("itemIds") as FormArray;
    //Validation checking of offer item
    if (this.isShowOfferItem) {
      if (ids.length > 0) {
      } else {
        canSubmit = false;
        msg = "Please select an item";
        /*this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = "Please select an item";
        this.myapp.isLoadingResults = false;*/
      }
    }

    //Validation agree is checked or not
    //console.log("Agree", this.agree.value);
    if (!this.agree.value) {
      canSubmit = false;
      msg = "Please agree before publishing this offer";
    }

    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("id", this.happyhourForm.get("id").value);
      formData.append("barId", this.happyhourForm.get("barId").value);
      formData.append("offerImage", this.happyhourForm.get("offerImage").value);
      formData.append("templateId", this.happyhourForm.get("templateId").value);
      formData.append("title", this.title.value);
      formData.append("description", this.description.value);
      formData.append("startTime", this.startTime.value);
      formData.append("endTime", this.endTime.value);
      formData.append("discountRate", this.discountRate.value);
      formData.append("appliedOnDay", this.appliedOnDay.value);
      formData.append("weekDay", this.happyhourForm.get("weekDays").value);
      formData.append("appliedOn", this.appliedOn.value);
      formData.append("discountType", this.discountType.value);
      formData.append("buy", this.buy.value);
      formData.append("free", this.free.value);
      formData.append("offerType", "happyhour");
      formData.append("itemIds", this.happyhourForm.get("itemIds").value);
      formData.append("base64Img", this.base64Img);
      formData.append("gracePeriod", this.gracePeriod.value);
      formData.append("token", localStorage.getItem("token"));

      this.bs.callAPI(formData, "addEditOffer").subscribe(response => {
        if (response.status === 1) {
          this.myapp.isLoadingResults = false;
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.router.navigate(["/view-happy-hour"]);
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      });

      /*let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message:
            "Deletion of offer won't be allowed after publishing. Do you want to publish the offer?"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });*/
    } else {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = msg;
    }
  }

  // View weekdays
  AllDays(): void {
    this.AllDaysAvailable = true;
    document.getElementById("weekdays").style.display = "none";

    // Clear the selected Specific days
    const formArray: FormArray = this.happyhourForm.get(
      "weekDays"
    ) as FormArray;

    formArray.clear();

    // Add weekdays for default
    for (let j = 0; j < this.weekDays.length; j++) {
      console.log(this.weekDays[j]);
      formArray.push(new FormControl(this.weekDays[j].toLowerCase()));
    }
    //console.log(formArray);

    this.daysExist = [];
  }

  // Change save button text on click specific item
  SpecificItem(): void {
    //this.happyHourSave = "Save & Select Item"
  }

  AllItem(): void {
    //this.happyHourSave = "Save"
  }

  SpecificDays(): void {
    this.SpecificDaysAvailable = true;

    document.getElementById("weekdays").style.display = "block";

    // Clear the all week days which was for default
    const formArray: FormArray = this.happyhourForm.get(
      "weekDays"
    ) as FormArray;
    formArray.clear();
  }

  // View Discount and offers
  DiscountShow(): void {
    this.buy.setValue("");
    this.free.setValue("");
    this.Discount = true;
    document.getElementById("DisPrice").style.display = "block";
    document.getElementById("happyoffer1").style.display = "none";
    document.getElementById("happyoffer2").style.display = "none";
    document.getElementById("offerBuyGet").style.display = "none";
    document.getElementById("offerget").style.display = "none";
    document.getElementById("disoffer").style.display = "block";
  }
  OfferShow(): void {
    this.discountRate.setValue("");
    this.Offer = true;
    document.getElementById("DisPrice").style.display = "none";
    document.getElementById("happyoffer1").style.display = "block";
    document.getElementById("happyoffer2").style.display = "block";
    document.getElementById("disoffer").style.display = "none";
    document.getElementById("offerBuyGet").style.display = "block";
    document.getElementById("offerget").style.display = "block";
  }

  // View offer on banner
  showDiscountValue(offerValue: any): void {
    let label = "";
    if (offerValue > 0) label = offerValue + "% off";
    document.getElementById("disoffer").innerHTML = label;

    let discountRateValue = this.discountRate.value;
    if (discountRateValue == 0) {
      this.DiscountErrorMessage = true;
    } else {
      this.DiscountErrorMessage = false;
    }
  }

  offerValueBuyGet() {
    let label = "";
    let buy = this.buy.value;
    let free = this.free.value;
    if (buy > 0 && free > 0) {
      label = "Buy " + buy + " Get " + free + " Free";
      document.getElementById("offerBuyGet").innerHTML = label;
    }
  }
  /*offerValueBye(offerrValue: string): void {
    document.getElementById("offerBuyGet").innerHTML = "Bye " + offerrValue;
  }
  offerValueGet(offerrValue: string): void {
    document.getElementById("offerget").innerHTML = "Get " + offerrValue;
  }*/

  // View Title on banner
  TitleShow(titleValue: string): void {
    document.getElementById("distitle").innerHTML = titleValue;
    //console.log(titleValue);
  }
  // View Description on banner
  DescriptionShow(descriptionValue: string): void {
    document.getElementById("disdiscription").innerHTML = descriptionValue;
    //console.log(descriptionValue);
  }

  //Function to find WeekDay in existing WeekDays Array to show the checkbox checked while EDIT
  checkDayExist(day) {
    //console.log(this.daysExist.length);
    let data = this.daysExist.indexOf(day);
    if (data >= 0) return true;
    else return false;
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.happyhourForm.reset();
    //Patch the required data in Form Modal
    this.happyhourForm.patchValue({
      id: "",
      barId: [localStorage.getItem("barId")],
      templateId: this.templates[0].id,
      offerImage: "",
      title: "",
      description: "",
      startTime: "",
      endTime: "",
      appliedOnDay: "",
      weekDay: "",
      appliedOn: "",
      discountType: "",
      discountRate: "",
      buy: "",
      free: ""
    });
    //Add the first Image for preview again
    this.imgPreviewPath = this.tempPath + this.templates[0].templateFrame;
    this.isShowOfferItem = false;
    this.imageSave = false;
    this.AllDays();
  }
  //Load Menu Items defination
  loadMenuItems() {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(barData, "getItemsList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.itemData = response.data;
        } else {
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load Item Data in case of edit
  loadOfferItems(offerType, offerId) {
    const formData = new FormData();
    formData.append("offerType", offerType);
    formData.append("offerId", offerId);
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getOffersItems").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //Set the discount rate
          this.dynamicDiscountRate = response.discountRate;
          //Add the items
          for (let i = 0; i < response.data.length; i++) {
            const formArray: FormArray = this.happyhourForm.get(
              "itemIds"
            ) as FormArray;

            // Add a new control in the arrayForm
            formArray.push(new FormControl(response.data[i].itemId));
            this.itemIdsExist.push(response.data[i].itemId);
          }
        } else {
          //Set the discount rate
          this.dynamicDiscountRate = response.discountRate;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Function to display offer item list and hide
  showHideOfferItem(num): void {
    //alert("show hide function is call"+num);
    if (num == 2) {
      this.isShowOfferItem = true;
      if (this.editing == false) {
        this.loadMenuItems();
      }
    } else {
      this.isShowOfferItem = false;
    }
  }
  //Funciton to select/deselect menu items on checkbox event
  onItemCheckChange(event, itemId: any) {
    let mainPrice = document.getElementById("mainPrice" + itemId);
    let disPrice = document.getElementById("discountPrice" + itemId);

    const formArray: FormArray = this.happyhourForm.get("itemIds") as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));

      mainPrice.className =
        "font-weight-bold mr-2 font-weight-bold line-through";
      disPrice.className = "font-weight-bold mr-2 text-success";
    } else {
      mainPrice.className = "font-weight-bold mr-2 font-weight-bold";
      disPrice.className = "font-weight-bold mr-2 text-success d-none";

      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  //Function to change discount rate
  changeDiscountRate() {
    this.dynamicDiscountRate = this.happyhourForm.get("discountRate").value;
    if (this.isShowOfferItem) {
      this.loadMenuItems();
    }
  }
  // Function to calculate disounted price on HTML
  calculateDisountedPrice(price, discount) {
    return (price - (price / 100) * discount).toFixed(2);
  }
  //Function to find itemIds in existing Ids to show the checkbox checked while EDIT
  checkItemExist(itemId) {
    let data = this.itemIdsExist.indexOf(itemId);
    if (data >= 0) return true;
    else return false;
  }

  //Function to find itemIds in existing Ids to show the price in green while EDIT
  onEditItemExist(itemId) {
    let mainPrice = document.getElementById("mainPrice" + itemId);
    let disPrice = document.getElementById("discountPrice" + itemId);
    let data = this.itemIdsExist.indexOf(itemId);
    if (data >= 0) {
      //console.log("ItemID exist");
      return true;
    } else return false;
  }

  //Function to select all items of category
  checkUncheckCategory(event, ci) {
    if (this.itemData[ci]) {
      let cat = this.itemData[ci];
      for (let i = 0; i < cat.subCat.length; i++) {
        for (let j = 0; j < cat.subCat[i].catItems.length; j++) {
          let itemId = cat.subCat[i].catItems[j];

          //id="customCheck_{{ ci }}{{subCatIndex}}{{ ii }}"
          let btn: HTMLElement = document.getElementById(
            "customCheck_" + ci + i + j
          ) as HTMLElement;
          btn.click();

          /*if (event.target.checked)
            this.itemIdsExist.push(itemId);
          else {
            let index = this.itemIdsExist.indexOf(itemId);
            this.itemIdsExist.splice(index, 1);
          }*/
        }
      }
    }
  }

  //Getor - To access the fields directly in the form in HTML
  get offerImage() {
    return this.happyhourForm.get("offerImage");
  }
  get title() {
    return this.happyhourForm.get("title");
  }
  get description() {
    return this.happyhourForm.get("description");
  }
  get startTime() {
    return this.happyhourForm.get("startTime");
  }
  get endTime() {
    return this.happyhourForm.get("endTime");
  }
  get appliedOnDay() {
    return this.happyhourForm.get("appliedOnDay");
  }
  get appliedOn() {
    return this.happyhourForm.get("appliedOn");
  }
  get discountType() {
    return this.happyhourForm.get("discountType");
  }
  get discountRate() {
    return this.happyhourForm.get("discountRate");
  }
  get buy() {
    return this.happyhourForm.get("buy");
  }
  get free() {
    return this.happyhourForm.get("free");
  }
  get itemIds() {
    return this.happyhourForm.get("itemIds");
  }
  get templateId() {
    return this.happyhourForm.get("templateId");
  }
  get agree() {
    return this.happyhourForm.get("agree");
  }
  get gracePeriod() {
    return this.happyhourForm.get("gracePeriod");
  }
}
