import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { exit, title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";
import { IDropdownSettings } from "ng-multiselect-dropdown";
@Component({
  selector: 'app-waiter-assign-table',
  templateUrl: './waiter-assign-table.component.html',
  styleUrls: ['./waiter-assign-table.component.css']
})
export class WaiterAssignTableComponent implements OnInit {

  


  staffForm: FormGroup;
  actionType = "add";
  pageTitle: string;
  dynamicRoute: any = "";
  imageSave = false;
  imageSaveLogo = false;
  FileErrorMessageLogo = false;
  FileErrorMessageCover = false;
  croppedImage: any = "";
  imageChangedEvent: any = "";
  imageChangedEventLogo: any = "";
  logoImageSave: any = null;
  logoPath = null;
  maintables = [];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedIds: number[] = [];
  // tablesIds =[];
  tablesIds: number[] = [];
  //  selectedItems =[];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    this.loadAlltables();
   //Profile Form Model Declration with form validation for the fields
   this.staffForm = this.fb.group({
    id: [""],
    waiterName: [
      "",
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(25),
      ]
    ],
    email: [""],
    phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
    password:["",[Validators.required]],
    status: [""],
    profilePic:[""],
    // password:[""],
  });
  //Access the Paramter from URL
  this.route.paramMap.subscribe((params: ParamMap) => {
    let id = parseInt(params.get("id"));
    if (id) {
      this.loadStaffData(id);
      this.actionType = "edit";
      this.pageTitle = "Edit waiter";
      this.dynamicRoute = "createWaiter";
      this.staffForm = this.fb.group({
        id: [""],
        waiterName: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(25)
          ]
        ],
        email: [""],
        phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
        password:[""],
        status: [""],
        profilePic:[""],
        // password:[""],
      });
     
      // this.loadAlltables();
    } else {
      this.actionType = "add";
      this.pageTitle = "Add waiter";
      this.dynamicRoute = "createWaiter";
      // this.loadAlltables();
    }
  });
  // this.dropdownList = [
  // ];
  // this.selectedItems = [
   
  // ];
  this.dropdownSettings= {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
}
onItemSelect(item: any) {
  console.log(item.id);
  this.logSelectedItems(item.id);

}

onSelectAll(items: any) {
  console.log(items);
  items.forEach(item => this.logSelectedItems(item.id));
}
logSelectedItems(id: number) {
  this.selectedIds.push(id);
  console.log(this.selectedIds.join(','));
}

onItemDeSelect(item: any) {
  console.log(item.id);
  this.logUnselectedItems(item.id);
}

onItemDeSelectAll(items: any[]) {
  console.log(items.map(item => item.id).join(','));
  this.logUnselectedItems(items.map(item => item.id));
}

logUnselectedItems(ids: number | number[]) {
  const unselectedIds = Array.isArray(ids) ? ids : [ids];
   
  // Perform your action with the unselected IDs
  console.log('Unselected IDs:', unselectedIds);

  // Remove unselected IDs from the selectedItems array
  this.selectedItems = this.selectedItems.filter(item => !unselectedIds.includes(item.id));

  // Reset or perform other actions as needed
  // For example, you can log the updated selectedItems array
  console.log('Updated selectedItems:', this.selectedItems);
}

getAllSelectedItems() {
  const selectedItems = this.staffForm.get('status').value;
  console.log('Selected Items:', selectedItems);
  this.tablesIds = selectedItems.map(item => item.id);
  console.log('Selected IDs:', this.tablesIds);
  
}
//Function to submit data
onStaffSubmit() {
  this.myapp.hideAlerts();
  let canSubmit = true;
  if (canSubmit) {
    this.myapp.isLoadingResults = true;
    this.getAllSelectedItems();
    const tablesIdsString = this.tablesIds.join(',');
   
    const formData = new FormData();
    formData.append("id", this.staffForm.get("id").value);
    formData.append("name", this.staffForm.get("waiterName").value);
    formData.append("email", this.staffForm.get("email").value);
    formData.append("roleId", '10');
    formData.append("status", this.staffForm.get("status").value);
    formData.append("phone", this.staffForm.get("phone").value);
    formData.append("password", this.staffForm.get("password").value);
    formData.append('tables', tablesIdsString);
    formData.append("loginLink", this.cs.siteUrl + "login");
    formData.append("userId", window.localStorage.getItem("userId"));
    formData.append("barId", window.localStorage.getItem("barId"));
    formData.append("profilePic", this.staffForm.get("profilePic").value);

    formData.append("token", localStorage.getItem("token"));
    // console.log(formData);
    

    //Form submit by appi call
    this.bs.callAPI(formData, this.dynamicRoute).subscribe(response => {
      console.log(response);
      
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.router.navigate(["/view-waiters"]);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }
}
loadStaffData(id) {
  const staffData = new FormData();
  staffData.append("id", id);
  staffData.append("token", localStorage.getItem("token"));
  this.bs.callAPI(staffData, "editWaiter").subscribe(
    (response: any) => {
      console.log(response);
      
      if (response.status == 1) {
        this.staffForm.patchValue({
          id: response.data.id,
          waiterName: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          status: response.data.status
        });
        // this.selectedItems =response.data.waiterTables;
        const selectedTableIds = response.data.waiterTables.split(',').map(id => +id);
        
        //  this.loadAlltables();
        //  console.log(selectedTableIds);
         
        //  console.log(this.dropdownList);
         
        // Set the selectedItems array based on the selected tables
        this.selectedItems = this.dropdownList.filter(id => selectedTableIds.includes(id.id));
        console.log(this.dropdownList);
        
        console.log(this.selectedItems);
        
      
      }
    },
    error => {
      console.log(error);
    }
  );
}


 loadAlltables(){
    const staffData = new FormData();
    staffData.append("barId", localStorage.getItem("barId"));
    staffData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(staffData, "getBarTables").subscribe(
      (response: any) => {
        if (response.status == 1) {
          console.log(response);
          
              //  this. maintables = response.data;
              this.maintables = response.data.map(table => {
                return {
                  id: table.id,
                  name: `${table.tableName} (${table.zoneName})`, // Assuming the property name is 'tableName'
                  zoneName: table.zoneName // Assuming the property name is 'zoneName'
                };
              });
              this.dropdownList = this.maintables;
               console.log('oo');
               console.log(this.dropdownList);
               
               
              // Configure dropdown settings
              this.dropdownSettings = {
                singleSelection: false,
                idField: 'id',
                textField: 'name',
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 3,
                allowSearchFilter: true
              };

              // console.log(this.maintables);
              
          // this.staffForm.patchValue({
          //   id: response.data.id,
          //   staffName: response.data.name,
          //   email: response.data.email,
          //   phone: response.data.phone,
            
          //   roleId: response.data.roleId
          // });
        }
      },
      error => {
        console.log(error);
      }
    );

  }

   //on File select, put the selected file in Profile Form model variable
   onFileSelect(event, form) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let docName = file.name;
      //console.log("File Size: " + file.size);
      if (form == "profileForm") {
        console.log('pkkkk');
        
        var fileMimes = file.type.split("/");
        var fileType = fileMimes[0];
  
        if (fileType === "image") {
          this.imageSaveLogo = true;
          this.imageChangedEventLogo = event;
          this.FileErrorMessageLogo = false;
          console.log('ookjkjj111111');
          this.logoImageSave = this.staffForm.get("profilePic").setValue(file);
          this.staffForm.get("profilePic").setValue(file);
          console.log('ookjkjj');
          // console.log();
          
          
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.logoPath = reader.result;
          };
          return;
        } else {
          this.FileErrorMessageLogo = true;
        }
      } 
      // else if (form == "kycForm") {
      //   var fileMimes = file.type.split("/");
      //   var fileType = fileMimes[0];
      //   var fileType2 = fileMimes[1];
  
      //   if (fileType === "image" || fileType2 == "pdf") {
      //     this.KycprePath = null;
      //     this.FileErrorMessage = false;
      //     this.kycForm.get("fileName").setValue(file);
      //     //Preveiw of the image only on images
      //     if (fileType === "image") {
      //       var reader = new FileReader();
      //       reader.readAsDataURL(file);
      //       reader.onload = _event => {
      //         this.KycprePath = reader.result;
      //       };
      //       this.chooseFileLabel = "Choose a file..";
      //     } else if (fileType2 === "pdf") {
      //       this.chooseFileLabel = docName;
      //     }
      //     return;
      //   } else {
      //     this.FileErrorMessage = true;
      //   }
      // } 
      
     
      
    }
  }
resetForm() {
  this.staffForm.reset();
  //Patch the required data in Form Modal
  this.staffForm.patchValue({
    id: "",
    waiterName: "",
    phone: "",
    email: "",
    password:""
  });
}
get waiterName() {
  return this.staffForm.get("waiterName");
}
get email() {
  return this.staffForm.get("email");
}
get phone() {
  return this.staffForm.get("phone");
}
get status() {
  return this.staffForm.get("status");
}
get password() {
  return this.staffForm.get("password");
}
  }
