import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-add-menu",
  templateUrl: "./add-menu.component.html",
  styleUrls: ["./add-menu.component.css"]
})
export class AddMenuComponent implements OnInit {
  //Variable Declration
  pageHeading = "Add Menu";
  public itemId = null; //used in Edit
  subCategories = [];
  mainCategories = [];
  foodUnits = [];
  drinkUnits = [];
  itemTypes: any = [
    { id: 1, type: "Veg" },
    { id: 2, type: "Non-Veg" }
    /*{ id: 3, type: "Eggiterian" }*/
  ];

  menuForm: FormGroup;
  public Available = true;
  public NotAvailable = false;
  submitted = false;
  FileErrorMessageLog = false;
  itemImagePath = null;
  showCancelBtn = false;
  PriceErrorMessage = false;
  showStockField = false;
  showFoodType = false;
  foodTypeError = false;
  generatedImages: string[] = [];
  selectedImageUrls: string[] = [];
  appliedOn:[]
  //Injecting SErvices
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    //Load the categories from app component
    this.mainCategories = this.myapp.itemMainCategories;
    //Load the unit types
    this.foodUnits = this.myapp.foodUnitTypes;
    this.drinkUnits = this.myapp.drinkUnitTypes;

    //menu Form Model Declaration with form validation for the fields
    this.menuForm = this.fb.group({
      barId: [localStorage.getItem("barId")],
      id: [""],
      catId: ["", [Validators.required]],
      subCatId: ["0"],
      itemImage: [""],
      itemType: ["0"],
      availableStock: ["", [Validators.pattern("^[0-9() . ]+$")]],
      itemName: [
        "",
        [
          Validators.required,
          //Validators.pattern("^[a-zA-Z ()]+$"),
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      ],
      appliedOn:[''],
      description: ["", [Validators.minLength(20), Validators.maxLength(200)]],
      isCustomized: ["0"], //Validators.required
      price: ["", [Validators.required, Validators.pattern("^[0-9() . ]+$")]],
      selectedImages: this.fb.array([])
      //UnitPlate: this.fb.array([this.AddUnitPlate()])
    });

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadItemData(id);
        this.pageHeading = "Edit Menu";
        this.showCancelBtn = true;
      }
    });
  }

  onImageCheckboxChange(event: any, imageUrl: string, index: number) {
    if (event.target.checked) {
      this.addSelectedImage(imageUrl);
    } else {
      this.removeSelectedImage(index);
    }
  }

  //on File select, put the selected file in Profile Form model variable
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      console.log(fileType);

      if (fileType === "image") {
        this.FileErrorMessageLog = false;
        this.menuForm.get("itemImage").setValue(file);
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.itemImagePath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  // Function to validate discount
  validatePrice() {
    let priceValue = this.price.value;
    if (priceValue <= 0) {
      this.PriceErrorMessage = true;
    } else {
      this.PriceErrorMessage = false;
    }
  }
  // Function to add selected image URLs to the FormArray
  addSelectedImage(imageUrl: string) {
    const selectedImagesArray = this.menuForm.get('selectedImages') as FormArray;
    selectedImagesArray.push(new FormControl(imageUrl));
  }

  // Function to remove selected image URLs from the FormArray
  removeSelectedImage(index: number) {
    const selectedImagesArray = this.menuForm.get('selectedImages') as FormArray;
    selectedImagesArray.removeAt(index);
  }
  
  //menu Form Submission
  onMenuSubmit() {
    //console.log(this.menuForm.value);
    if (!this.PriceErrorMessage) {
      let canSubmit = true;
      let catId = this.menuForm.get("catId").value;
      if (catId == 5 || catId == 6) {
        let itemType = this.menuForm.get("itemType").value;
        if (itemType == 0) {
          this.foodTypeError = true;
          canSubmit = false;
        }
      }

      if (canSubmit) {
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;
          console.log( this.applitdOn);
          // console.log(this.menuForm.get("applitdOn").value);
          console.log('ppp');
          
          
        const menuData = new FormData();
        const selectedImagesArray = this.menuForm.get('selectedImages') as FormArray;
        const selectedImageUrls = selectedImagesArray.value;
         console.log(selectedImageUrls);
         
        menuData.append("barId", this.menuForm.get("barId").value);
        menuData.append("id", this.menuForm.get("id").value);
        menuData.append("catId", this.menuForm.get("catId").value);
        menuData.append("subCatId", this.menuForm.get("subCatId").value);
        menuData.append("itemImage", this.menuForm.get("itemImage").value);
        menuData.append("itemName", this.menuForm.get("itemName").value);
        menuData.append("itemType", this.menuForm.get("itemType").value);
        menuData.append(
          "availableStock",
          this.menuForm.get("availableStock").value
        );
        menuData.append("description", this.menuForm.get("description").value);
        menuData.append(
          "isCustomized",
          this.menuForm.get("isCustomized").value
        );
        menuData.append("selectedImageUrls", selectedImageUrls);

        menuData.append("price", this.menuForm.get("price").value);
        menuData.append("token", localStorage.getItem("token"));
        console.log(menuData);
        
        this.bs.callAPI(menuData, "addEditMenuItem").subscribe(response => {
          console.log(response);
          
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;
            if (this.menuForm.get("id").value) {
              this.router.navigate(["/view-menu"]);
            } else {
              this.itemImagePath = null;
              let cid = this.catId.value;
              let sci = this.subCatId.value;
              this.menuForm.reset();
              this.menuForm.patchValue({
                barId: localStorage.getItem("barId"),
                id: "",
                itemName: "",
                catId: cid,
                subCatId: sci,
                itemImage: "",
                itemType: "0",
                availableStock: "",
                description: "",
                appliedOn: [''],
                isCustomized: "0",
                price: ""
              });
            }
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
            this.myapp.isLoadingResults = false;
          }
        });
      }
    } else {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please enter correct amount in price field.";
      this.myapp.isLoadingResults = false;
    }
  }

  //Load Item Data in case of edit
  loadItemData(id) {
    const itemData = new FormData();
    itemData.append("id", id);
    itemData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(itemData, "getMenuDetail").subscribe(
      (response: any) => {
        if (response.status == 1) {
          //Patch the Item data in menuForm
          this.menuForm.patchValue({
            barId: localStorage.getItem("barId"),
            id: response.data.id,
            catId: response.data.catId,
            subCatId: response.data.subCatId,
            itemName: response.data.itemName,
            itemType: response.data.itemType,
            availableStock: response.data.availableStock,
            description: response.data.description,
            applitdOn:[''],
            isCustomized: response.data.isCustomized,
            price: response.data.price
          });

          //Show image preview if available
          if (response.data.itemImage)
            this.itemImagePath = this.cs.itemsPath() + response.data.itemImage;
          this.loadSubCategory(null);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Search Items

  //Form Group For Dynamic Field
  /*AddUnitPlate(): FormGroup {
    return this.fb.group({
      menuPlate: ["", [Validators.required]],
      menuPlateprice: [
        "",
        [Validators.required, Validators.pattern("^[0-9()]+$")]
      ]
    });
  }

  //Generate Dynamic Field
  AddUnitBtn(): void {
    (<FormArray>this.menuForm.get("UnitPlate")).push(this.AddUnitPlate());
  }

  ShowAvailable(): void {
    this.Available = false;
    this.NotAvailable = true;
  }

  ShowNotAvailable(): void {
    this.NotAvailable = false;
    this.Available = true;
  }*/

  //Load Subcategory, on Category change event
  loadSubCategory(event) {
    this.myapp.hideAlerts();
    const catId = this.menuForm.get("catId").value;

    this.showFoodType = catId == 5 || catId == 6 ? true : false;
    this.showStockField = catId == 3 ? true : false;

    if (catId != "") {
      const formData = new FormData();
      formData.append("catId", catId);
      formData.append("barId", localStorage.getItem("barId"));
      formData.append("token", localStorage.getItem("token"));

      this.bs.callAPI(formData, "getCategoryData").subscribe(
        (response: any) => {
          if (response.status == 1) {
            this.subCategories = response.data;
            //console.log(this.subCategories);
          } else {
            //this.myapp.showErrorAlert = true;
            //this.myapp.errorMsg = response.message;
            var noSubCat = [
              { subCatId: 0, subCatName: "No Sub Category found" }
            ];
            this.subCategories = noSubCat;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.subCategories = [];
  }

  // Reset the Form via Cancel Button
  resetForm() {
    this.menuForm.reset();
    this.PriceErrorMessage = false;
    //Patch the required data in Form Modal
    this.menuForm.patchValue({
      barId: [localStorage.getItem("barId")],
      id: "",
      catId: "",
      subCatId: "",
      itemImage: "",
      itemName: "",
      itemType: "0",
      availableStock: "",
      description: "",
      appliedOn:[''],
      isCustomized: "0",
      price: ""
    });
    this.itemImagePath = null;
    this.foodTypeError = false;
  }

  //Getor - To access the fields directly in the form in HTML
  get catId() {
    return this.menuForm.get("catId");
  }
  get subCatId() {
    return this.menuForm.get("subCatId");
  }
  get itemImage() {
    return this.menuForm.get("itemImage");
  }
  get itemName() {
    return this.menuForm.get("itemName");
  }
  get itemType() {
    return this.menuForm.get("itemType");
  }
  get description() {
    return this.menuForm.get("description");
  }
  get applitdOn(){
    return this.menuForm.get("appliedOn");
  }
  /*get isCustomized() {
    return this.menuForm.get("isCustomized");
  }*/
  get price() {
    return this.menuForm.get("price");
  }
  get availableStock() {
    return this.menuForm.get("availableStock");
  }
  /*get menuPlateprice() {
    return this.menuForm.get("menuPlateprice");
  }
  get UnitPlate() {
    return this.menuForm.get("UnitPlate");
  }*/

  generateText() {
    if (this.itemName) {
      const prompt = `Generate text for ${this.itemName.value}`;
      console.log(prompt);
      const formData = new FormData();
      formData.append("itemName", this.menuForm.get("itemName").value);
      formData.append("token", localStorage.getItem("token"));

      this.bs.callAPI(formData, "getBarProfile12").subscribe(
        (response: any) => {
          console.log(response.content);
          this.menuForm.patchValue({
          
            description: response.content,
            ai_images:response.images,
          });
          this.generatedImages=response.images;

        },
        error => {
          console.log(error);
         
        }
      );
     
    }
  }
}
