import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HeaderComponent } from "./layout/header/header.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { RestaurantOnboardingComponent } from "./restaurant-onboarding/restaurant-onboarding.component";
import { RestaurantOnboardingNewComponent } from "./restaurant-onboarding-new/restaurant-onboarding-new.component";
import { EditBarProfileComponent } from "./bar/edit-bar-profile/edit-bar-profile.component";
import { BarProfileComponent } from "./bar/bar-profile/bar-profile.component";
import { AddMenuComponent } from "./menu/add-menu/add-menu.component";
import { UploadMenuComponent } from "./menu/upload-menu/upload-menu.component"; 
import { CategoryComponent } from "./menu/category/category.component";
import { ViewMenuComponent } from "./menu/view-menu/view-menu.component";
import { AddDiscountComponent } from "./discount/add-discount/add-discount.component";
import { ViewDiscountComponent } from "./discount/view-discount/view-discount.component";
import { AddHappyHourComponent } from "./happy-hour/add-happy-hour/add-happy-hour.component";
import { ViewHappyHourComponent } from "./happy-hour/view-happy-hour/view-happy-hour.component";
import { AddSpecialEventComponent } from "./special-event/add-special-event/add-special-event.component";
import { ViewSpecialEventComponent } from "./special-event/view-special-event/view-special-event.component";

import { BarZoneComponent } from "./bar/bar-zone/bar-zone.component";
import { BarTableComponent } from "./bar/bar-table/bar-table.component";
import { AddBarTableComponent } from "./bar/add-bar-table/add-bar-table.component";
import { AddBarZoneComponent } from "./bar/add-bar-zone/add-bar-zone.component";
import { WaiterAssignTableComponent } from "./bar/waiter-assign-table/waiter-assign-table.component";
import { WaiterAssignTableListComponent } from "./bar/waiter-assign-table-list/waiter-assign-table-list.component";

import { ReportComponent } from "./report/report.component";
import { OrderHistoryComponent } from "./order/order-history/order-history.component";
import { CreateKotComponent } from "./order/create-kot/create-kot.component";
import { KotListComponent } from "./order/kot-list/kot-list.component";
import { EditKotComponent } from "./order/edit-kot/edit-kot.component";
import { InvoiceComponent } from "./order/invoice/invoice.component";
import { CodeGenerateComponent } from "./code-generate/code-generate.component";
import { TopBarComponent } from "./top-bar/top-bar.component";
import { EventTicketComponent } from "./special-event/event-ticket/event-ticket.component";
import { OfferItemsComponent } from "./offer-items/offer-items.component";
import { AddPatronComponent } from "./patron/add-patron/add-patron.component";
import { ViewPatronComponent } from "./patron/view-patron/view-patron.component";
import { AddBarComponent } from "./bar/add-bar/add-bar.component";
import { ViewBarComponent } from "./bar/view-bar/view-bar.component";
import { BarUserComponent } from "./bar/bar-user/bar-user.component";
import { BarNonSolUserListComponent } from "./bar/bar-non-sol-user-list/bar-non-sol-user-list.component";
import { BarWalkingSameNumberCustomerComponent } from "./bar/bar-walking-same-number-customer/bar-walking-same-number-customer.component";
import { BarUserListComponent } from "./bar/bar-user-list/bar-user-list.component";
import { ViewGeoTaggedComponent } from "./geo-tagged/view-geo-tagged/view-geo-tagged.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { ViewSettlementComponent } from "./settlement/view-settlement/view-settlement.component";
import { PatronHistoryComponent } from "./patron/patron-history/patron-history.component";
import { SettingComponent } from "./setting/setting.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { PaymentComponent } from "./settlement/payment/payment.component";
import { PaymentHistoryComponent } from "./settlement/payment-history/payment-history.component";
import { NotificationComponent } from "./notification/notification.component";
import { NewsfeedComponent } from "./newsfeed/newsfeed.component";
import { NewsfeedReportersComponent } from "./newsfeed-reporters/newsfeed-reporters.component";
import { BookingTableComponent } from "./booking-table/booking-table.component";
import { RevenueListComponent } from "./revenue-list/revenue-list.component";
import { ActivationRequestComponent } from "./admin/activation-request/activation-request.component";
import { AllCodeGenerateComponent } from "./admin/all-code-generate/all-code-generate.component";
import { NonSolUserListComponent } from "./admin/non-sol-user-list/non-sol-user-list.component";
import { AdminWalkingCustomerComponent } from "./bar/admin-walking-customer/admin-walking-customer.component";
import { AdminWalkingSameNumberCustomerComponent } from "./admin/admin-walking-same-number-customer/admin-walking-same-number-customer.component";
import { BarWalkingCustomerComponent } from "./bar/bar-walking-customer/bar-walking-customer.component";
import { AllDiscountOfferComponent } from "./admin/all-discount-offer/all-discount-offer.component";
import { AllHappyHoursComponent } from "./admin/all-happy-hours/all-happy-hours.component";
import { AllSpecialEventComponent } from "./admin/all-special-event/all-special-event.component";
import { AllOrdersComponent } from "./admin/all-orders/all-orders.component";
import { AllTableBookingsComponent } from "./admin/all-table-bookings/all-table-bookings.component";
import { AddStaffComponent } from "./staff/add-staff/add-staff.component";
import { ViewStaffComponent } from "./staff/view-staff/view-staff.component";
import { AddPriceComponent } from "./admin/event-price-setup/add-price/add-price.component";
import { ViewPriceComponent } from "./admin/event-price-setup/view-price/view-price.component";
import { AddTemplateComponent } from "./admin/templates/add-template/add-template.component";
import { BannertempletComponent } from "./admin/bannertemplet/bannertemplet.component";
import { AdminAppVersionComponent } from "./admin/admin-app-version/admin-app-version.component";
import { TransactionComponent } from "./admin/transaction/transaction.component";
import { AdminPaymentBarComponent } from "./admin/admin-payment-bar/admin-payment-bar.component";
import { PaymentBarTransactionComponent } from "./admin/payment-bar-transaction/payment-bar-transaction.component";
import { BarTransactionsComponent } from "./bar-transactions/bar-transactions.component";
import { AreasComponent } from "./admin/areas/areas.component";
import { TipHistoryComponent } from "./order/tip-history/tip-history.component";
import { PosComponent } from "./pos/pos.component";
import { GiftInvoiceComponent } from "./order/gift-invoice/gift-invoice.component";
import { EntireSalesReportComponent } from "./reports/entire-sales-report/entire-sales-report.component";
import { ItemSalesReportComponent } from "./reports/item-sales-report/item-sales-report.component";
import { DiscountSalesReportComponent } from "./reports/discount-sales-report/discount-sales-report.component";
import { CancelCodesReportComponent } from "./reports/cancel-codes-report/cancel-codes-report.component";
import { PreOrdersReportComponent } from "./reports/pre-orders-report/pre-orders-report.component";
import { MaxSellingItemsReportComponent } from "./reports/max-selling-items-report/max-selling-items-report.component";
import { CommissionReportComponent } from "./reports/commission-report/commission-report.component";
import { BroadcastComponent } from "./admin/broadcast/broadcast.component";
import { RestaurantsListComponent } from "./admin/restaurants-list/restaurants-list.component";

import { EventManagerComponent} from "./admin/event-manager/event-manager.component"
import { EventManagerEventsComponent } from "./admin/event-manager-events/event-manager-events.component";
import { EventManagerCreateComponent } from "./admin/event-manager-create/event-manager-create.component";
import { EventManagerEventsTicketComponent } from "./admin/event-manager-events-ticket/event-manager-events-ticket.component";
import { MesterRestaurantComponent } from "./admin/mester-restaurant/mester-restaurant.component";
import { AllCodeGenerateRestaurantListComponent } from "./admin/all-code-generate-restaurant-list/all-code-generate-restaurant-list.component";
import { AllDiscountOfferRestaurantListComponent } from "./admin/all-discount-offer-restaurant-list/all-discount-offer-restaurant-list.component";
import { AllHappyHoursRestaurantListComponent } from "./admin/all-happy-hours-restaurant-list/all-happy-hours-restaurant-list.component";
import { AllOrdersRestaurantListComponent } from "./admin/all-orders-restaurant-list/all-orders-restaurant-list.component";
import { AllSpecialEventRestaurantListComponent } from "./admin/all-special-event-restaurant-list/all-special-event-restaurant-list.component";
import { AllTableBookingRestaurantListComponent } from "./admin/all-table-booking-restaurant-list/all-table-booking-restaurant-list.component";

const routes: Routes = [ 
  {
    path: "login",
    component: LoginComponent,
    data: { title: "Login" }
  },
  {
    path: "restaurant-onboarding-new",
    component: RestaurantOnboardingComponent,
    data: { title: "Onboarding-new" }
  },
  {
    path: "restaurant-data",
    component: MesterRestaurantComponent,
    data: { title: "Restaurant Data" }
  },
  {
    path: "restaurant-onboarding",
    component: RestaurantOnboardingNewComponent,
    data: { title: "Onboarding" }
  },
  {
    path: "dashboard/:str",
    component: DashboardComponent,
    data: { title: "Dashboard" }
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    data: { title: "Dashboard" }
  },
  {
    path: "pos",
    component: PosComponent,
    data: { title: "POS" }
  },
  {
    path: "edit-bar-profile",
    component: EditBarProfileComponent,
    data: { title: "Edit Profile" }
  },
  {
    path: "edit-bar-profile/:id",
    component: EditBarProfileComponent,
    data: { title: "Edit Profile" }
  },
  {
    path: "bar-profile",
    component: BarProfileComponent,
    data: { title: "Bar Profile" }
  },
  {
    path: "bar-profile/:id",
    component: BarProfileComponent,
    data: { title: "Bar Profile" }
  },
  {
    path: "event-manger",
    component: EventManagerComponent,
    data: { title: "Event Manager" }
  },
  {
    path: "event-manager-create",
    component: EventManagerCreateComponent,
    data: { title: "Create Event Manager" }
  },
  {
    path: "category",
    component: CategoryComponent,
    data: { title: "Category" }
  },
  {
    path: "add-menu-item",
    component: AddMenuComponent,
    data: { title: "Edit Profile" }
  },
  {
    path: "add-menu-item/:id",
    component: AddMenuComponent,
    data: { title: "Edit Profile" }
  },
  {
    path: "upload-menu",
    component: UploadMenuComponent,
    data: { title: "Upload Menu" }
  },
  {
    path: "view-menu",
    component: ViewMenuComponent,
    data: { title: "View Menu" }
  },
  {
    path: "view-menu/:keyword",
    component: ViewMenuComponent,
    data: { title: "View Menu" }
  },
  {
    path: "code-generate",
    component: CodeGenerateComponent,
    data: { title: "Code Generate" }
  },
  {
    path: "non-sol-user",
    component: BarNonSolUserListComponent,
    data: { title: "Non Sol User" }
  },
  //AdminWalkingCustomerComponent  AdminWalkingSameNumberCustomerComponent
  {
    path: "report/admin-walking-customers",
    component: AdminWalkingCustomerComponent,
    data: { title: "Admin Walking Customer" }
  },
  
  {
    path: "report/admin-walking-customers/:id",
    component: AdminWalkingSameNumberCustomerComponent,
    data: { title: "Admin Walk In Details" }
  },
  {
    path: "report/walking-customers",
    component: BarWalkingCustomerComponent,
    data: { title: "Walking Customer" }
  },
  {
    path: "report/walking-customers/:id",
    component: BarWalkingSameNumberCustomerComponent,
    data: { title: "Walk In Details" }
  },
  {
    path: "order-history",
    component: OrderHistoryComponent,
    data: { title: "Order History" }
  },
  {
    path: "order-history/:orderId",
    component: OrderHistoryComponent,
    data: { title: "Order History" }
  },
  {
    path: "create-kot/:id",
    component: CreateKotComponent,
    data: { title: "Create KOT" }
  },
  {
    path: "kot-list/:id",
    component: KotListComponent,
    data: { title: "KOT List" }
  },
  {
    path: "edit-kot/:id",
    component: EditKotComponent,
    data: { title: "Edit KOT" }
  },
  {
    path: "invoice/:id",
    component: InvoiceComponent,
    data: { title: "Order Invoice" }
  },
  {
    path: "invoice/:id/:visitId",
    component: InvoiceComponent,
    data: { title: "Order Invoice" }
  },
  {
    path: "invoice/:id/:visitId/:barId",
    component: InvoiceComponent,
    data: { title: "Order Invoice" }
  },
  {
    path: "gift-invoice/:id",
    component: GiftInvoiceComponent,
    data: { title: "Order Invoice" }
  },
  {
    path: "order-tips",
    component: TipHistoryComponent,
    data: { title: "Tip History" }
  },
  {
    path: "add-discount",
    component: AddDiscountComponent,
    data: { title: "Add Discount" }
  },
  {
    path: "add-discount/:id",
    component: AddDiscountComponent,
    data: { title: "Edit Discount" }
  },
  {
    path: "view-discount",
    component: ViewDiscountComponent,
    data: { title: "View Discount" }
  },
  {
    path: "add-happy-hour",
    component: AddHappyHourComponent,
    data: { title: "Add Happy Hour" }
  },
  {
    path: "add-happy-hour/:id",
    component: AddHappyHourComponent,
    data: { title: "Edit Happy Hour" }
  },
  {
    path: "view-happy-hour",
    component: ViewHappyHourComponent,
    data: { title: "View Happy Hour" }
  },
  {
    path: "offer-items/:str/:id",
    component: OfferItemsComponent,
    data: { title: "Offer Items" }
  },
  {
    path: "add-special-event",
    component: AddSpecialEventComponent,
    data: { title: "Add Special Event" }
  },
  {
    path: "add-special-event/:id",
    component: AddSpecialEventComponent,
    data: { title: "Edi Special Event" }
  },
  {
    path: "view-special-event",
    component: ViewSpecialEventComponent,
    data: { title: "View Special Event" }
  },

  {
    path: "add-zone",
    component: AddBarZoneComponent,
    data: { title: "Add Zone " }
  },
  {
    path: "add-zone/:id",
    component: AddBarZoneComponent,
    data: { title: "Edit Zone" }
  },
  {
    path: "view-zones",
    component: BarZoneComponent,
    data: { title: "View Zones" }
  },


  {
    path: "add-table",
    component: AddBarTableComponent,
    data: { title: "Add Special Event" }
  },
  {
    path: "add-table/:id",
    component: AddBarTableComponent,
    data: { title: "Edit Table" }
  },
  {
    path: "view-tables",
    component: BarTableComponent,
    data: { title: "View Tables" }
  },

  {
    path: "add-waiter",
    component: WaiterAssignTableComponent,
    data: { title: "Add Waiter " }
  },
  {
    path: "add-waiter/:id",
    component: WaiterAssignTableComponent,
    data: { title: "Edi Waiter" }
  },
  {
    path: "view-waiters",
    component: WaiterAssignTableListComponent,
    data: { title: "View Waiters " }
  },


  {
    path: "report",
    component: ReportComponent,
    data: { title: "Report" }
  },
  {
    path: "reports/entire-sales",
    component: EntireSalesReportComponent,
    data: { title: "Entire Sales" }
  },
  {
    path: "reports/items",
    component: ItemSalesReportComponent,
    data: { title: "Item Sales Report" }
  },
  {
    path: "reports/max-selling-items",
    component: MaxSellingItemsReportComponent,
    data: { title: "Max Selling Items Report" }
  },
  {
    path: "reports/discounts",
    component: DiscountSalesReportComponent,
    data: { title: "Discount Report" }
  },
  {
    path: "reports/cancel-codes",
    component: CancelCodesReportComponent,
    data: { title: "Cancel Codes Report" }
  },
  {
    path: "reports/pre-orders",
    component: PreOrdersReportComponent,
    data: { title: "Pre Orders Report" }
  },
  {
    path: "reports/commission",
    component: CommissionReportComponent,
    data: { title: "Commission Report" }
  },

  {
    path: "top-bar",
    component: TopBarComponent,
    data: { title: "Top Bar" }
  },
  {
    path: "event-ticket/:id",
    component: EventTicketComponent,
    data: { title: "Event Ticket" }
  },
  {
    path: "event-manager-ticket/:id",
    component: EventManagerEventsTicketComponent,
    data: { title: "Event Manager Ticket" }
  },
  {
    path: "event-manager-events/:id",
    component: EventManagerEventsComponent,
    data: { title: "Event Manager Events" }
  },
  
  {
    path: "add-patron",
    component: AddPatronComponent,
    data: { title: "Add Patron" }
  },
  {
    path: "add-patron/:id",
    component: AddPatronComponent,
    data: { title: "Add Patron" }
  },
  {
    path: "view-patron",
    component: ViewPatronComponent,
    data: { title: "Event Ticket" }
  },
  {
    path: "patron-history/:id",
    component: PatronHistoryComponent,
    data: { title: "Patron History" }
  },
  {
    path: "add-bar",
    component: AddBarComponent,
    data: { title: "Add Bar" }
  },
  {
    path: "view-bar",
    component: ViewBarComponent,
    data: { title: "View Bar" }
  },
  
  {
    path: "restaurants-list",
    component: RestaurantsListComponent,
    data: { title: "Restaurants" }
  },
  {
    path: "view-geo-tagged",
    component: ViewGeoTaggedComponent,
    data: { title: "View Geo Tagged" }
  },
  {
    path: "admin-dashboard",
    component: AdminDashboardComponent,
    data: { title: "Dashboard" }
  },
  {
    path: "view-settlement",
    component: ViewSettlementComponent,
    data: { title: "View Settlement" }
  },
  {
    path: "setting",
    component: SettingComponent,
    data: { title: "Setting" }
  },

  {
    path: "change-password",
    component: ChangePasswordComponent,
    data: { title: "Change Password" }
  },
  {
    path: "pay-to-bar/:id",
    component: PaymentComponent,
    data: { title: "Pay To bar" }
  },
  {
    path: "payment-history",
    component: PaymentHistoryComponent,
    data: { title: "Payment History" }
  },
  {
    path: "notification",
    component: NotificationComponent,
    data: { title: "notification" }
  },
  {
    path: "newsfeed",
    component: NewsfeedComponent,
    data: { title: "newsfeed" }
  },
  {
    path: "newsfeed-reporters/:id",
    component: NewsfeedReportersComponent,
    data: { title: "News Feed Reporters" }
  },
  {
    path: "booking-table",
    component: BookingTableComponent,
    data: { title: "Booking Table" }
  },
  {
    path: "revenue-list",
    component: RevenueListComponent,
    data: { title: "Revenue List" }
  },
  {
    path: "activation-request",
    component: ActivationRequestComponent,
    data: { title: "Activation Request" }
  },
  // {
  //   path: "all-code-generate",
  //   component: AllCodeGenerateComponent,
  //   data: { title: "All Code Generate" }
  // },
  {
    path: "all-code-generate/:id",
    component: AllCodeGenerateComponent,
    data: { title: "All Code Generate" }
  },
  {
    path: "all-restaurant-code-generate",
    component: AllCodeGenerateRestaurantListComponent,
    data: { title: "All Restaurant Code Generate" }
  },
  {
    path: "all-non-sol-user",
    component: NonSolUserListComponent,
    data: { title: "All Non Sol Users" }
  },
  // {
  //   path: "all-discount-offers",
  //   component: AllDiscountOfferComponent,
  //   data: { title: "All Discount Offers" }
  // },
  // {
  //   path: "all-discount-offers",
  //   component: AllDiscountOfferComponent,
  //   data: { title: "All Discount Offers" }
  // },
  {
    path: "all-discount-offers/:id",
    component: AllDiscountOfferComponent,
    data: { title: "All Discount Offers" }
  },
  {
    path: "all-restaurant-discount-offers",
    component: AllDiscountOfferRestaurantListComponent,
    data: { title: "All Restaurant Discount offers" }
  },

  // {
  //   path: "all-happy-hours-offers",
  //   component: AllHappyHoursComponent,
  //   data: { title: "All Happy Hours Offers" }
  // },
  {
    path: "all-happy-hours-offers/:id",
    component: AllHappyHoursComponent,
    data: { title: "All Happy Hours Offers" }
  },
  {
    path: "all-restaurant-happy-hour-offers",
    component: AllHappyHoursRestaurantListComponent,
    data: { title: "All Restaurant Happy Hours offers" }
  },
  // {
  //   path: "all-special-events",
  //   component: AllSpecialEventComponent,
  //   data: { title: "All Special Events" }
  // },
  {
    path: "all-special-events/:id",
    component: AllSpecialEventComponent,
    data: { title: "All Special Events" }
  },
  {
    path: "all-restaurant-special-events",
    component: AllSpecialEventRestaurantListComponent,
    data: { title: "All Restaurant Special Events" }
  },
  // {
  //   path: "all-orders",
  //   component: AllOrdersComponent,
  //   data: { title: "All Orders" }
  // },
  {
    path: "all-orders/:id",
    component: AllOrdersComponent,
    data: { title: "All Orders" }
  },
  {
    path: "all-restaurant-orders",
    component: AllOrdersRestaurantListComponent,
    data: { title: "All Restaurant Orders" }
  },
  // {
  //   path: "all-table-bookings",
  //   component: AllTableBookingsComponent,
  //   data: { title: "All Table Bookings" }
  // },
  {
    path: "all-table-bookings/:id",
    component: AllTableBookingsComponent,
    data: { title: "All Table Bookings" }
  },
  {
    path: "all-restaurant-table-bookings",
    component: AllTableBookingRestaurantListComponent,
    data: { title: "All Restaurant Table Bookings" }
  },
  {
    path: "add-staff",
    component: AddStaffComponent,
    data: { title: "Add Staff" }
  },
  {
    path: "add-staff/:id",
    component: AddStaffComponent,
    data: { title: "Edit Staff" }
  },
  {
    path: "bar-user",
    component: BarUserComponent,
    data: { title: "Add Bar Users" }
  },
  {
    path: "bar-user/:id",
    component: BarUserComponent,
    data: { title: "Edit Bar Users" }
  },
  {
    path: "bar-user-list",
    component: BarUserListComponent,
    data: { title: "Bar User List" }
  },
  {
    path: "view-staff",
    component: ViewStaffComponent,
    data: { title: "View Staff" }
  },
  {
    path: "add-special-event-price",
    component: AddPriceComponent,
    data: { title: "Add Special Event Price" }
  },
  {
    path: "add-special-event-price/:id",
    component: AddPriceComponent,
    data: { title: "Edit Special Event Price" }
  },
  {
    path: "view-special-event-price",
    component: ViewPriceComponent,
    data: { title: "View Special Event Price" }
  },
  {
    path: "add-template",
    component: AddTemplateComponent,
    data: { title: "Add Template" }
  },
  {
    path: "add-banner-template",
    component: BannertempletComponent,
    data: { title: "Add Banner Template" }
  },
  {
    path:"admin-app-versions",
    component:AdminAppVersionComponent,
    data: { title: "Admin App Versions"}
  },
  {
    path: "add-banner-template/:id",
    component: BannertempletComponent,
    data: { title: "Edit Banner Template" }
  },
  
  {
    path: "add-template/:id",
    component: AddTemplateComponent,
    data: { title: "Edit Template" }
  },
  {
    path: "areas-management",
    component: AreasComponent,
    data: { title: "Areas Management" }
  },
  {
    path: "transactions",
    component: TransactionComponent,
    data: { title: "Transaction List" }
  },
  {
    path: "admin-bar-transactions",
    component: AdminPaymentBarComponent,
    data: { title: "Admin Bar Transaction List" }
  },
  {
    path: "bar-payment/:id",
    component: PaymentBarTransactionComponent,
    data: { title: "Payment Bar" }
  },
  {
    path: "bar-transactions",
    component: BarTransactionsComponent,
    data: { title: "Transaction List" }
  },
  {
    path: "broadcast",
    component: BroadcastComponent,
    data: { title: "Broadcast" }
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
export const routingComponents = [
  LoginComponent,
  RestaurantOnboardingComponent,
  RestaurantOnboardingNewComponent,
  UploadMenuComponent,
  DashboardComponent,
  EditBarProfileComponent,
  BarProfileComponent,
  CategoryComponent,
  AddMenuComponent,
  ViewMenuComponent,
  CodeGenerateComponent,
  BarNonSolUserListComponent,
  AddDiscountComponent,
  ViewDiscountComponent,
  OfferItemsComponent,
  OrderHistoryComponent,
  KotListComponent,
  EditKotComponent,
  InvoiceComponent,
  AddHappyHourComponent,
  ViewHappyHourComponent,
  AddDiscountComponent,
  ViewDiscountComponent,
  ViewGeoTaggedComponent,
  AdminDashboardComponent,
  ViewSettlementComponent,
  PatronHistoryComponent,
  PaymentComponent,
  PaymentHistoryComponent,
  BookingTableComponent,
  ActivationRequestComponent,
  AllCodeGenerateComponent,
  NonSolUserListComponent,
  AllDiscountOfferComponent,
  AllHappyHoursComponent,
  AllSpecialEventComponent,
  AddStaffComponent,
  ViewStaffComponent,
  AddTemplateComponent,
  BannertempletComponent,
  TransactionComponent,
  TipHistoryComponent,
  BarZoneComponent,
  AddBarTableComponent,
  AddBarZoneComponent,
  BarTableComponent,
  WaiterAssignTableListComponent,
  WaiterAssignTableComponent
];
