import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";

@Component({
  selector: 'app-add-bar-zone',
  templateUrl: './add-bar-zone.component.html',
  styleUrls: ['./add-bar-zone.component.css']
})
export class AddBarZoneComponent implements OnInit {

  zoneForm: FormGroup;
  actionType = "add";
  pageTitle: string;
  dynamicRoute: any = "";
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    // this.cs.isAdminRoute();

    //Profile Form Model Declration with form validation for the fields
    this.zoneForm = this.fb.group({
      id: [""],
      zoneName: [
        "",
        [
          Validators.required,
        ]
      ],
      // kotPrinter: [""],
      // botPrinter: [""],
      // invoicePrinter: [""],
      status:[""],
      // password:[""],
    });
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadStaffData(id);
        this.actionType = "edit";
        this.pageTitle = "Edit Restaurent Zone";
        this.dynamicRoute = "createBarZone";
        this.zoneForm = this.fb.group({
          id: [""],
          zoneName: [
            "",
            [
              Validators.required
            ]
          ],
          // kotPrinter: [""],
          // botPrinter: [""],
          // invoicePrinter: [""],
          status:["",],
        });
      } else {
        this.actionType = "add";
        this.pageTitle = "Add Restaurant Zone";
        this.dynamicRoute = "createBarZone";
      }
    });
  }
  //Function to submit data
  onStaffSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;
    if (canSubmit) {
      this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("id", this.zoneForm.get("id").value);
      formData.append("zoneName", this.zoneForm.get("zoneName").value);
      formData.append("barId", localStorage.getItem("barId"));
      // formData.append("kotPrinter", this.zoneForm.get("kotPrinter").value);
      // formData.append("invoicePrinter", this.zoneForm.get("invoicePrinter").value);
      // formData.append("botPrinter", this.zoneForm.get("botPrinter").value);
      formData.append("status", this.zoneForm.get("status").value);
      formData.append("loginLink", this.cs.siteUrl + "login");
      formData.append("userId", window.localStorage.getItem("userId"));
      formData.append("token", localStorage.getItem("token"));

      //Form submit by appi call
      this.bs.callAPI(formData, this.dynamicRoute).subscribe(response => {
        console.log(response);
        
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.router.navigate(["/view-zones"]);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
    }
  }
  loadStaffData(id) {
    const staffData = new FormData();
    staffData.append("id", id);
    staffData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(staffData, "editBarZone").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.zoneForm.patchValue({
            id: response.data.id,
            zoneName: response.data.zoneName,
            // kotPrinter: response.data.kotPrinter,
            // botPrinter: response.data.botPrinter,
            // invoicePrinter: response.data.invoicePrinter,
            status:response.data.status,
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  resetForm() {
    this.zoneForm.reset();
    //Patch the required data in Form Modal
    this.zoneForm.patchValue({
      id: "",
      zoneName: "",
      // botPrinter: "",
      // kotPrinter: "",
      status:""
    });
  }
  get zoneName() {
    return this.zoneForm.get("zoneName");
  }
  // get kotPrinter() {
  //   return this.zoneForm.get("kotPrinter");
  // }
  // get botPrinter() {
  //   return this.zoneForm.get("botPrinter");
  // }
  // get invoicePrinter() {
  //   return this.zoneForm.get("invoicePrinter");
  // }
  get status() {
    return this.zoneForm.get("status");
  }


}
